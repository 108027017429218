import React, { useContext } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import { RenFunction, RenderContext } from 'Context/context';
import { config } from 'config/configs';
import { TRow, TableTh } from 'Component/Theme/CSSstyles';
import { numberFormat } from 'tools/numberFormat';

const GetInstaller = ({ modalGotov, getData }) => {
  const UserId = localStorage.getItem('DataID');
  const RenderFunction = useContext(RenFunction);
  const GetToken = useContext(RenderContext);

  if (!getData) {
    return 'Загрузка...';
  }
  let totalKv = 0;
  let totalOst = 0;
  getData.forEach((d) => {
    if (modalGotov.key === d.order_id && d.ish_turi_id === '4') {
      totalKv += Number(d.yuza);
      totalOst = modalGotov.yuza - totalKv.toFixed(2);
    }
  });

  const HandleDelete = (id, order_id, worker_id, yuza) => {
    swal({
      title: 'Точно удалить!',
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/delete-yuza.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify({
            token: config.TOKEN,
            id: id,
            order_id: order_id,
            worker_id: worker_id,
            yuza: yuza,
          }),
        })
          .then(function (res) {
            RenderFunction();
            GetToken();
            swal({
              title: `${res.data.xabar}`,
              icon: 'success',
              buttons: 'Ok',
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  // eslint-disable-next-line
  const newData = getData.map((i) => {
    if (modalGotov.key === i.order_id && i.ish_turi_id === '4') {
      return (
        <TRow key={i.id}>
          <td style={{ padding: 10, fontSize: 18, color: '#000' }}>{i.vaqt}</td>
          <td style={{ padding: 10, fontSize: 18, color: '#000' }}>
            {i.worker_name}
          </td>
          <td style={{ padding: 10, fontSize: 18, color: '#000' }}>{i.izoh}</td>
          <td style={{ padding: 10, fontSize: 18, color: '#000' }}>{i.yuza}</td>
          <td style={{ padding: 10, fontSize: 18, color: '#000', width: 150 }}>
            {numberFormat(Math.ceil(i.summa))}
          </td>
          <td style={{ padding: 10, fontSize: 18, color: '#000' }}>
            {numberFormat(i.fee)}
          </td>
          {UserId === '1' ? (
            <td
              style={{ padding: 10, color: '#000', cursor: 'pointer' }}
              onClick={() =>
                HandleDelete(i.id, i.order_id, i.worker_id, i.yuza)
              }
            >
              удалить
            </td>
          ) : null}
        </TRow>
      );
    }
  });
  return (
    <div>
      <span>
        Установлен {totalKv.toFixed(2)}{' '}
        <>
          М
          <sup>
            <small>2</small>
          </sup>
        </>
      </span>{' '}
      <span>
        Осталось установить {totalOst.toFixed(2)}{' '}
        <>
          М
          <sup>
            <small>2</small>
          </sup>
        </>
      </span>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <TableTh>День и время установки</TableTh>
            <TableTh>Установщики</TableTh>
            <TableTh>Подробные данные заказа</TableTh>
            <TableTh>
              {' '}
              <>
                М
                <sup>
                  <small>2</small>
                </sup>
              </>
            </TableTh>
            <TableTh>Сумма</TableTh>
            <TableTh>Цена за один кв</TableTh>
            <TableTh>удалить</TableTh>
          </tr>
        </thead>
        <tbody>{newData}</tbody>
      </table>
    </div>
  );
};

export default GetInstaller;
