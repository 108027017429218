import React, { useState, useEffect } from "react";
import axios from "axios";
import Loding from "./Loding";
import { Table, Modal } from "antd";
import ClientPrint from "./Client/ClientMiniComponent/ClientPrint";
import CSS from "./PageComponent.module.css";
import { Span, Main, MiniTitle } from "../Component/Theme/CSSstyles";

const { Column } = Table;

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

function TableNewClientRusset() {
  const [savedFile, setSavedFile] = useState([]);
  const token = localStorage.getItem("DataToken");
  const FetchArxiv = () => {
    const data = JSON.stringify({ token: token });

    const config = {
      method: "POST",
      url: `${url}/get-orders-arxiv.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        const Reset = response.data;
        Reset.reverse();
        setSavedFile(Reset);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchArxiv();
    // eslint-disable-next-line
  }, []);
  // ! Данные модального окна
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const closeModal = () => {
    setIsModalVisible(false);
  };
  // ! Данные модального окна

  if (savedFile.length === 0) return <Loding />;
  return (
    <>
      <Table
        scroll={{
          x: 1250,
        }}
        pagination={false}
        dataSource={savedFile}
        title={(data) => {
          let totalRaschot = 0;
          data.forEach(({ raschot }) => {
            totalRaschot += Number(raschot);
          });
          return (
            <Main style={{ padding: 20 }}>
              <MiniTitle className={CSS.CashInfo}>
                Сумма заказов:
                <Span className={CSS.CashInfoSpan}>
                  {totalRaschot.toLocaleString()}
                </Span>
              </MiniTitle>
            </Main>
          );
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setActiveRecord(record);
              setIsModalVisible(true);
            },
          };
        }}
      >
        <Column
          width={25}
          title="№"
          dataIndex="key"
          key="key"
          className="ClientTableDate"
        />
        <Column
          width={190}
          title="Дата заказа"
          dataIndex="date"
          key="date"
          className="ClientTableDate"
        />
        <Column
          width={170}
          title="Дата установки"
          dataIndex="installationDate"
          key="installationDate"
          className="ClientTableDate"
        />{" "}
        <Column
          width={300}
          align="center"
          title="Заказчик"
          dataIndex="name"
          key="name"
          className="ClientTableDate"
        />
        <Column
          align="center"
          title="Номер"
          dataIndex="phone"
          key="phone"
          width={200}
          className="ClientTableDate"
        />
        <Column
          width={250}
          align="center"
          title="Адрес"
          dataIndex="adres"
          key="adres"
          className="ClientTableDate"
        />
        <Column
          align="center"
          title="Сумма заказа"
          dataIndex="cash"
          key="cash"
          className="ClientTableDate"
          width={250}
          render={(_, record) => {
            const Cash = record.cash;
            return priceTo(Cash);
          }}
        />
        <Column
          width={50}
          align="center"
          title="Остаток"
          dataIndex="ostatok"
          key="ostatok"
          render={(_, record) => {
            const Ostatok = (record.ostatok = record.cash - record.raschot);
            return priceTo(Ostatok);
          }}
          className="ClientTableDate"
        />
        <Column
          width={250}
          align="center"
          title="Расчет"
          dataIndex="raschot"
          key="raschot"
          render={(_, record) => {
            const Raschot = record.raschot;
            return priceTo(Raschot);
          }}
          className="ClientTableDate"
        />
      </Table>
      <Modal
        title="Данные Клиента"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        width={"80vw"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClientPrint activeRecord={activeRecord} />
      </Modal>
    </>
  );
}

export default TableNewClientRusset;
