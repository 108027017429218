import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import RequiredAuthProvider from 'Component/RecuiredAuth/RequiredAuthProvider';
import { MonthList } from './stor/Store';
import { OrdersState } from './Context/OrderContext/OrdersState';
import OperationCashState from './Context/OrderContext/OperationCash/OperationCashState';
import StateDataPersonal from './Context/PersonalContext/StateDataPersonal';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './Component/Theme/GlobalStyles';
import { Layout } from 'antd';
import ColopsMenu from './Component/SidebarMenu/ColopsMenu';
import { Headers, Mains } from './Component/Theme/CSSstyles';
import MayHeader from './PageComponent/Header/Header';
import ContragentHooks from './Context/Contragent/ContragetHooks';
import { useDarkMode } from './Component/Theme/UseDarkMode';
import {
  darkTheme,
  lightTheme,
  LimeTheme,
  mayTheme,
  redTheme,
} from './Component/Theme/Theme';
import Routing from './Router/Routing';
import './App.css';
import { MonthContext } from 'Context/context';

const { Sider } = Layout;

export default function App() {
  const { getContragent } = ContragentHooks();

  useEffect(() => {
    getContragent();
    // eslint-disable-next-line
  }, []);

  const [collapsed, setCollapsed] = useState(true);
  const [theme, themeToggler, mountedComponent] = useDarkMode();
  const themeMode =
    theme === 'light'
      ? lightTheme
      : theme === 'dark'
      ? darkTheme
      : theme === 'may'
      ? mayTheme
      : theme === 'red'
      ? redTheme
      : theme === 'lime'
      ? LimeTheme
      : lightTheme;

  if (!mountedComponent) return <div />;
  return (
    <RequiredAuthProvider>
      <MonthContext.Provider value={MonthList}>
        {/* стате для таблицы рабочих */}
        <OrdersState>
          <OperationCashState>
            <StateDataPersonal>
              <ThemeProvider theme={themeMode}>
                <GlobalStyles />
                <Layout
                  style={{
                    minHeight: '100vh',
                  }}
                >
                  <Sider
                    breakpoint='lg'
                    collapsedWidth='0'
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                  >
                    <div
                      style={{
                        margin: 16,
                        background: '#3f5060',
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      {!collapsed ? '  Блокнот' : null}
                    </div>
                    <ColopsMenu />
                  </Sider>

                  <Layout className='site-layout'>
                    <Headers>
                      <MayHeader theme={theme} toggleTheme={themeToggler} />
                    </Headers>

                    <Mains>
                      <Routing />
                      <Outlet />
                    </Mains>

                    {/* <Footer
                    style={{
                      textAlign: "center",
                      margin: "5px 16px 0 16px",
                    }}
                  >
                    Ant Design ©2023 Created by Ant UED
                  </Footer> */}
                  </Layout>
                </Layout>
              </ThemeProvider>
            </StateDataPersonal>
          </OperationCashState>
        </OrdersState>
      </MonthContext.Provider>
    </RequiredAuthProvider>
  );
}
