import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Form, DatePicker, Input, TimePicker, Select } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import { useState } from "react";
import { PersonalContext } from "Context/context";
import ModalCenter from "Component/ModalComponent/ModalMiddle/ModalCenter";
import { CardButton } from "Component/Theme/CSSstyles";
const configs = {
	rules: [
		{
			type: "object",
			required: true,
			message: "введите данные!",
		},
	],
};

export default function AccauntingForm({ form, GetDataAccaunting, arrData }) {
	const { FetchWorkData, workData } = useContext(PersonalContext);
	const [isValue, setIsValue] = useState("");
	useEffect(() => {
		FetchWorkData();
		// eslint-disable-next-line
	}, []);

	function changeValue(val) {
		setIsValue(val);
	}

	let formData = workData.map((i) =>
		isValue === i.id ? (
			<div key={i.id}>
				<Form.Item name="employeeID" initialValue={i.fp_id}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="telegram_id" initialValue={i.telegram_id}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="personName" initialValue={i.username}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="cardNo" initialValue={""}>
					<Input type="hidden" />
				</Form.Item>
			</div>
		) : null
	);

	return (
		<ModalCenter name={"Учет рабочих"}>
			<Form name="global" form={form} onFinish={GetDataAccaunting}>
				<Form.Item name="worker_id">
					<Select
						placeholder="Выберите радочего"
						options={workData.map((i) => ({
							value: i.id,
							label: i.username,
						}))}
						onChange={changeValue}
					/>
				</Form.Item>

				<Form.Item name="authDate" placeholder="Дата" {...configs}>
					<DatePicker style={{ width: 300 }} locale={locale} />
				</Form.Item>

				<Form.Item name="authTime" {...configs}>
					<TimePicker style={{ width: 300 }} placeholder="Время" />
				</Form.Item>

				<Form.Item>
					<CardButton htmlType="submit">Выполнить</CardButton>
				</Form.Item>

				{formData}
			</Form>
		</ModalCenter>
	);
}
