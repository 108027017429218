import React from "react";
import { TableTh, TRow, Hr } from "../../../../Component/Theme/CSSstyles";
import CSS from "../FinishedOrder.module.css";
// const priceTo = (price) => {
//   if (!price) {
//     return null;
//   }
//   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
// };

const FinishedClientOnaTable = ({ modalGotov }) => {
  return (
    <div>
      <Hr />
      <table className={CSS.TableFinished}>
        <thead>
          <tr>
            <TableTh className={CSS.ModalClientTH}>№</TableTh>
            <TableTh className={CSS.ModalClientTH}>Дата заказа</TableTh>
            <TableTh className={CSS.ModalClientTH}>Дата установки</TableTh>
            <TableTh className={CSS.ModalClientTH}>Клиент</TableTh>
            <TableTh className={CSS.ModalClientTH}>Объём</TableTh>
          </tr>
        </thead>
        <tbody>
          <TRow>
            <td className={CSS.ModalClientInfo}>{modalGotov.key}</td>
            <td className={CSS.ModalClientInfo}>{modalGotov.date}</td>
            <td className={CSS.ModalClientInfo}>
              {modalGotov.installationDate}
            </td>
            <td className={CSS.ModalClientInfo}>{modalGotov.name}</td>
            <td className={CSS.ModalClientInfo}>{modalGotov.yuza}</td>
          </TRow>
        </tbody>
      </table>
    </div>
  );
};

export default FinishedClientOnaTable;
