import React, { useRef } from "react";
import FinishedClientOnaTable from "./Installers/FinishedTableComponent/FinishedClientOnaTable";
import InstallersWorkers from "./Installers/Installers/InstallersWorkers";
import FrameMaste from "./Master/FrameMaste";
import ReactToPrint from "react-to-print";
const Master = ({ modalGotov }) => {
  const componentRef = useRef();
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <button className="exit__modal-card-btn">Печатать</button>
        )}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        <h1>Действия с заказом</h1>
        <FinishedClientOnaTable modalGotov={modalGotov} />
        <FrameMaste modalGotov={modalGotov} />
        <InstallersWorkers modalGotov={modalGotov} />
      </div>
    </div>
  );
};

export default Master;
