import React, { useState, useEffect } from "react";
import axios from "axios";
import CSS from "./ProductComm.module.css";
import "antd/dist/antd.min.css";

const url = process.env.REACT_APP_DB_URL;

const ProductNameComm = ({ id, getClientComm, comm }) => {
  const Comments = getClientComm;
  const [comData, setComData] = useState(Comments);
  const token = localStorage.getItem("DataToken");
  const UserId = localStorage.getItem("DataID");
  const GetData = () => {
    const data = JSON.stringify({
      order_id: id,
      token: token,
    });
    const config = {
      method: "POST",
      url: `${url}/get-comments-id.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setComData(response.data);
      })
      .catch((err) => {
        console.log("GetInfoErr > > > >", err);
      });
  };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line
  }, [getClientComm, id]);

  const handleDelete = (key) => {
    const data = JSON.stringify({
      comment_id: key,
      token: token,
    });
    const config = {
      method: "POST",
      url: `${url}/delete-comment.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        GetData();
      })
      .catch((err) => {
        console.log("GetInfoErr > > > >", err);
      });
  };

  const DataComm = comData.map((item) =>
    id === item.order_id ? (
      <tr key={item.tr}>
        <td className={CSS.ClientPropductText}> {item.comment}</td>
        {UserId === "1" ? (
          <td className={CSS.ClientProductDell}>
            <button
              className={CSS.ClientProductDellButton}
              onClick={() => handleDelete(item.tr)}
            >
              &times;
            </button>
          </td>
        ) : null}
      </tr>
    ) : null
  );

  return (
    <>
      <table className={CSS.ClientProductTable}>
        <thead>
          <tr>
            <th>Перечень</th>
            <th>&times;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={CSS.ClientPropductText}> {comm}</td>
          </tr>
          {DataComm}
        </tbody>
      </table>
    </>
  );
};

export default ProductNameComm;
