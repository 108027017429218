import React, { useState } from "react";
import TablePokupkaRaznoe from "../PageComponent/Rasxod/TablePokupkaRaznoe";
import PostTableRaznoe from "../PageComponent/Rasxod/PostTableRaznoe";
import "antd/dist/antd.min.css";
import CSS from "./Page.module.css";
import RasxodInterval from "../PageComponent/Rasxod/RasxodInterval/RasxodInterval";

import { Title } from "../Component/Theme/CSSstyles";

const Rasxod = () => {
  const [getOthers, setGetOthers] = useState([]);
  return (
    <div>
      <div className={CSS.BlockRasxodHeader}>
        <Title className={CSS.H1} type="secondary">
          Расходы
        </Title>
        <div style={{ paddingTop: 10 }}>
          <RasxodInterval />
        </div>
      </div>
      <div className={CSS.PokupkaBlock}>
        {" "}
        <PostTableRaznoe setGetOthers={setGetOthers} />
        <br />
        <TablePokupkaRaznoe getOthers={getOthers} />
      </div>
    </div>
  );
};

export default Rasxod;
