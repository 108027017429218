import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import FormMinusPlusCash from "../PageComponent/Client/ClientMiniComponent/ClientCashOperation/FormMinusPlusCash";
import OrderSingle from "../PageComponent/Client/OrderSingle/OrderSingle";
import swal from "sweetalert";
import OperationCashHook from "../Context/OrderContext/OperationCash/OperationCashHook";
import { MiniTitle } from "../Component/Theme/CSSstyles";
import css from "./Page.module.css";
import { Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
// import OrderHook from "Context/OrderContext/OrderHook";
const url = process.env.REACT_APP_DB_URL;

export default function ClientSingle() {
  //   const { getOrder2 } = OrderHook();
  const {
    onMinusCash,
    onPlusCash,
    onAddAvans,
    fetchDataAvans,
    onRemoveAvans,
    data,
  } = OperationCashHook();
  const navigate = useNavigate();
  //! возвращаемся назад
  const goBack = () => navigate(-1);

  const [loding, setLoding] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const { key } = useParams();

  //!fetch запрашиваем данные
  const getSingleOrder = async () => {
    setLoding(true);
    const data = JSON.stringify({
      token: localStorage.getItem("DataToken"),
      order_id: key,
    });
    const config = {
      method: "POST",
      url: `${url}/get-orders-singl.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    try {
      const _res = await axios(config);

      if (200 === _res.status) {
        setLoding(false);
        // console.log("1", _res.data);
        setFetchData(_res.data);
      } else {
        swal({
          title: "Ooops....",
          text: "что-то пошло не так",
          icon: "warning",
        });
      }
    } catch (error) {
      setLoding(false);
      swal({
        title: "Ошибка",
        text: `${error}`,
        icon: "warning",
      });
      if (error.code === "ERR_NETWORK") {
        swal({
          title: "O_o!",
          text: "Проверьте подключение к интернету",
          icon: "warning",
          timer: 2000,
        });
      }
    }
  };

  useEffect(() => {
    fetchDataAvans(key);
    //   eslint-disable-next-line
  }, [key]);
  useEffect(() => {
    getSingleOrder();
    //   eslint-disable-next-line
  }, []);

  if (fetchData.length === 0) {
    return <h1>Загрузка</h1>;
  }
  return (
    <>
      <div className={css.ClientSingleHeader}>
        <div>
          <Button
            type="primary"
            danger
            shape="circle"
            onClick={goBack}
            style={{ marginRight: "5px" }}
          >
            <RollbackOutlined />
          </Button>
          <MiniTitle>Данные заказа</MiniTitle>
        </div>
        <div>
          <FormMinusPlusCash
            id={key}
            //  cash={active.cash}
            //  raschot={active.raschot}
            onFinesh={onAddAvans}
            title={"Добавить оплату за заказ"}
            buttonText={"Принять оплату"}
          />
          <FormMinusPlusCash
            id={key}
            onFinesh={onPlusCash}
            title={"Добавить сумму к заказу"}
            buttonText={"Добавить сумму"}
          />
          <FormMinusPlusCash
            id={key}
            onFinesh={onMinusCash}
            title={"Минусовать сумму с заказа"}
            buttonText={"Минусовать"}
          />
        </div>
      </div>
      <OrderSingle
        loding={loding}
        fetchData={fetchData}
        onRemoveAvans={onRemoveAvans}
        data={data}
      />
    </>
  );
}
