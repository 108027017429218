import React from "react";
import { Span, TableTh, TRow } from "../../../Component/Theme/CSSstyles";
import css from "./WorkDataInterval.module.css";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const WorkAvansInterval = ({ dataInterval }) => {
  // выводим данные о работах
  const DataInterval = dataInterval.avans.map((i) => (
    <TRow key={i.id}>
      <td className={css.WorkIntervalData}>{i.date}</td>
      <td className={css.WorkIntervalData}>{i.comment}</td>
      <td className={css.WorkIntervalData}>{i.cash}</td>
    </TRow>
  ));

  let TotalCash = 0;
  dataInterval.avans.forEach(({ cash }) => {
    TotalCash += Number(cash);
  });
  return (
    <div>
      <div>
        <Span>сумма авансов:</Span>
        {""}
        <Span>{priceTo(TotalCash)}</Span>
      </div>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <TableTh>Дата</TableTh>
            <TableTh>Заметки</TableTh>
            <TableTh>Сумма</TableTh>
          </tr>
        </thead>
        <tbody>{DataInterval}</tbody>
      </table>
    </div>
  );
};

export default WorkAvansInterval;
