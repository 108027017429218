import React from "react";
import "antd/dist/antd.min.css";
import WorkersArchive from "../PageComponent/WorkersArchive/WorkersArchive";
import css from "./Page.module.css";

import { Title } from "../Component/Theme/CSSstyles";

const Arxiv = () => {
  return (
    <div className={""}>
      <Title className={css.UserArxivtList__h1}>
        Рассчетные данные рабочих
      </Title>
      <div className={css.UserArxivList}>
        <WorkersArchive />
      </div>
    </div>
  );
};

export default Arxiv;
