import styled from 'styled-components';

export const Title = styled.h1`
  color: ${({ theme }) => theme.text2};
  font-size: calc(10px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280)) !important;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 5px;
  margin-left: 3px !important;
  transition: 0.5s;
`;
export const MiniTitle = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280)) !important;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 5px;
  margin-left: 3px !important;
  transition: 0.5s;
`;
export const Span = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: calc(6px + (5 + 5 * 0.7) * ((100vw - 320px) / 1280));
  padding-left: 10px;
`;
export const Spans = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: calc(10px + (2 + 2 * 0.7) * ((100vw - 320px) / 1280));
`;
export const Text = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: calc(20px + (2 + 2 * 0.7) * ((100vw - 320px) / 1280));
`;
export const Tablehed = styled.div`
  color: ${({ theme }) => theme.th};
  font-size: calc(1px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  text-align: center;
`;
export const Main = styled.main`
  height: 70px;
  background: ${({ theme }) => theme.background1};
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
`;

export const MainTable = styled.main`
  background: ${({ theme }) => theme.background1};
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  padding: 10px 0 10px 15px;
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.text2};
  text-align: center;
`;
export const Paragraphth = styled.th`
  color: ${({ theme }) => theme.text};
  text-align: center;
`;
export const Headers = styled.main`
  color: ${({ theme }) => theme.text};
  border-bottom: ${({ theme }) => theme.borderBottom};
  background: ${({ theme }) => theme.background2};
  grid-area: Header;
  /* background: #008080; */
  /* background: #1f2936; */
  display: flex;
`;
export const Sections = styled.section`
  color: ${({ theme }) => theme.text2};
  background: ${({ theme }) => theme.body};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  flex-direction: column;
`;

// Table card
export const MainCard = styled.main`
  color: ${({ theme }) => theme.text2};
  background: ${({ theme }) => theme.body};
  box-shadow: ${({ theme }) => theme.CardShadow};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 35% 15% 15% 15% 20%;
  grid-template-rows: auto;
  padding: 10px 0;
  padding-left: 10px;
  margin: 20px 10px;
`;
//
//   flex-direction: column;
export const CardTitle = styled.span`
  color: ${({ theme }) => theme.text1};
  font-size: calc(8px + (3 + 3 * 0.7) * ((100vw - 320px) / 1280)) !important;
  text-transform: uppercase;
  font-weight: 900;
  margin-left: 3px !important;
`;
export const CardSpan = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: calc(6px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  padding-left: 10px;
`;
export const CardButton = styled.button`
  color: #fff;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.CardShadow};
  padding: 5px 10px;
  margin-left: 5px;
  background: ${({ theme }) => theme.background2};
  cursor: pointer;
`;
export const TableTd = styled.td`
  padding: 0;
  background: ${({ theme }) => theme.background3};
  border-bottom: ${({ theme }) => theme.tableTd};
  color: ${({ theme }) => theme.text3};
  font-size: calc(10px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
`;

export const TableTh = styled.th`
  background: ${({ theme }) => theme.background2};
  color: ${({ theme }) => theme.text};
  font-size: calc(8px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
`;

export const Hr = styled.hr`
  width: 100%;
  margin: 20px 0px;
  box-shadow: ${({ theme }) => theme.CardShadow};
`;
export const TRow = styled.tr`
  text-align: center;
  color: ${({ theme }) => theme.text2};
`;

export const Footers = styled.footer`
  height: 40px;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.background2};
  flex: 40px;
  padding: 15px 50px;
  margin-top: 5px;
`;
export const Mains = styled.main`
  min-height: calc(100vh - 40px);
  padding: 50px;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.background};
`;

//! single orders table style
export const SingleTRow = styled.tr`
  text-align: start;

  color: ${({ theme }) => theme.text2};
`;
export const SingleTableTh = styled.th`
  background: ${({ theme }) => theme.background2};
  color: ${({ theme }) => theme.text};
  font-size: calc(4px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
  border-radius: 10px;
`;
export const SingleTableTd = styled.td`
  padding: 10px 15px;
  background: ${({ theme }) => theme.background3};
  border-bottom: ${({ theme }) => theme.tableTd};
  color: ${({ theme }) => theme.text3};
  font-size: calc(6px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
`;
