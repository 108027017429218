import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, DatePicker } from "antd";
import { CardButton } from "../../Component/Theme/CSSstyles";
import WorkersTable from "./WorkersTable";
import css from "./WorkersData.module.css";
// import "moment/locale/ru-RU";
import locale from "antd/es/date-picker/locale/ru_RU";
import GetAccaunting from "./WorkerAccaunting/GetAccaunting";
import swal from "sweetalert";

const url = process.env.REACT_APP_DB_URL;

const WorkersFormMonth = () => {
  // отправляем данные в таблицу рабочих
  const [workerInfo, setWorkerInfo] = useState([]);
  const token = localStorage.getItem("DataToken");
  const FetchWorkersData = (value) => {
    value = {
      oy_id: value["oy_id"].format("MM"),
      yil_id: value["yil_id"].format("YYYY"),
    };
    value.token = token;
    const data = JSON.stringify(value);
     console.log(">>>>>", data);
    const config = {
      method: "post",
      url: `${url}/get-davomat-check.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setWorkerInfo(response.data);
        }
      })
      .catch(function (error) {
        swal({
          title: "Что-то пошло не так",
          icon: "warning",
          text: `${error}`,
        });
      });
  };

  const FetchWorkersDataGEt = () => {
    const data = JSON.stringify({
      token: token,
      yil_id: new Date().getFullYear(),
      oy_id: new Date().getMonth() + 1,
    });
     // console.log("effect>>>", data);
    const config = {
      method: "post",
      url: `${url}/get-davomat-check.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setWorkerInfo(response.data);
        }
      })
      .catch(function (error) {
        swal({
          title: "Что-то пошло не так",
          icon: "warning",
          text: `${error}`,
        });
      });
  };
  useEffect(() => {
    FetchWorkersDataGEt();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={css.FormGetMonthBlock}>
        <Form onFinish={FetchWorkersData} className={css.FormGetMonth}>
          {" "}
          <Form.Item name="yil_id" placeholder="Дата">
            <DatePicker
              style={{ width: 200 }}
              placeholder="Год"
              picker="year"
              locale={locale}
            />
          </Form.Item>
          <Form.Item name="oy_id">
            <DatePicker
              style={{ width: 200 }}
              placeholder="Месяц"
              picker="month"
              locale={locale}
            />
          </Form.Item>
          <Form.Item>
            <CardButton htmlType="submit" className={css.WorkerTableBlockBtn}>
              Выполнить
            </CardButton>
          </Form.Item>
        </Form>
        <div className={css.FormGetDataUser}>
          <GetAccaunting FetchWorkersDataGEt={FetchWorkersDataGEt} />
        </div>
      </div>

      <div className={css.WorkerTableBlock}>
        <WorkersTable workerInfo={workerInfo} />
      </div>
    </div>
  );
};

export default WorkersFormMonth;
