import { TableTh, TRow } from "Component/Theme/CSSstyles";
import React from "react";
import css from "../OrdersTable/OrderTable.module.css";
const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export default function OrdersCahPlusMinus({ plusMinus }) {
  //   add_cash: "0";
  //   comment: "";
  //   id: "5";
  //   minus_cash: "10000000";
  //   operations: "минус";
  //   order_id: "180";
  //   sana: "04-04-2023";
  //   username: "Xasanov Javohir";
  const dataPlusMinus = plusMinus?.map((i, index) => (
    <TRow key={i.id}>
      <td>{index + 1}</td>
      <td>{i.sana}</td>
      <td>{i.operations}</td>
      <td>{priceTo(i.add_cash)}</td>
      <td>{priceTo(i.minus_cash)}</td>
      <td>{i.comment}</td>
      <td>{i.username}</td>
      <td>
        <button>удалить</button>
      </td>
    </TRow>
  ));
  return (
    <div>
      <table className={css.orderTable}>
        <caption style={{ captionSide: "top" }}>
          Плюсы и Минусы от суммы заказа
        </caption>
        <thead>
          <TRow>
            <TableTh>№</TableTh>
            <TableTh>Дата</TableTh>
            <TableTh>Действия</TableTh>
            <TableTh>Плус</TableTh>
            <TableTh>Минус</TableTh>
            <TableTh>Заметки</TableTh>
            <TableTh>Пользователь</TableTh>
            <TableTh>Удалить</TableTh>
          </TRow>
        </thead>
        <tbody>{dataPlusMinus}</tbody>
      </table>
    </div>
  );
}
