import React from "react";
import StatisticPage from "../PageComponent/IndexComponent/StatisticPage";
import { DemoColumn } from "../PageComponent/Test/DemoColumn";
import { Col, Row } from "antd";

function IndexPrivate() {
  return (
    <div>
      <Row gutter={12} justify="space-between">
        <Col span={10}>
          <StatisticPage />
        </Col>
        <Col span={14}>
          <DemoColumn />
        </Col>
      </Row>
    </div>
  );
}

export default IndexPrivate;
