import React from "react";
import CSS from "./ClientAvansComm.module.css";

const priceTo = (price) => {
  if (!price) {
    return "";
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const ClientAvansComm = ({ id, data, onRemoveAvans }) => {
  const UserId = localStorage.getItem("DataID");

  return (
    <>
      <table className={CSS.ClientBlockAvans}>
        <thead>
          <tr>
            <th>Пользователь</th>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Заметки</th>
            <th>&times;</th>
          </tr>
        </thead>
        {data.map((item) => {
          return id === item.order_id ? (
            <tbody key={item.tr}>
              <tr>
                <td className={CSS.ClientAvansText}> {item.username}</td>
                <td className={CSS.ClientAvansText}> {item.sana}</td>
                <td className={CSS.ClientAvansText}> {priceTo(item.avans)}</td>
                <td className={CSS.ClientAvansText}> {item.comment}</td>
                {UserId === "1" ? (
                  <td className={CSS.ClientAvansBtnBlock}>
                    <button
                      className={CSS.ClientAvansBtn}
                      type="danger"
                      shape="circle"
                      style={{ padding: 0 }}
                      onClick={() => onRemoveAvans(item.tr)}
                    >
                      &times;
                    </button>
                  </td>
                ) : null}
              </tr>
            </tbody>
          ) : null;
        })}
      </table>
    </>
  );
};

export default ClientAvansComm;
