import React, { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import css from './AddUser.module.css';
import { WorkingRender } from 'Context/context';
import { config } from 'config/configs';
import DrowerModal from 'Component/ModalComponent/DrowerModal/DrowerModal';
import { CardButton, Hr, Span, Title } from 'Component/Theme/CSSstyles';

const AddUser = () => {
  const FetchWorkData = useContext(WorkingRender);
  const [getName, setGetName] = useState([]);
  const [getStatus, setGetStatus] = useState([]);
  const [getVidOplati, setGetVidOplati] = useState([]);

  // получение имён из сканера для формы
  const GetUserData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-xodim-list.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        token: config.TOKEN,
      }),
    })
      .then(function (res) {
        //   console.log(res.data.new_workers);
        if (res.data.error === 0) {
          setGetName(res.data.new_workers);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // получение статуса для формы
  const GetStatus = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-lavozim.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (response) {
        if (response.status === 200) {
          setGetStatus(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // получаем вид оплаты для формы
  const VidOplati = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-ish-turi.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (response) {
        if (response.status === 200) {
          setGetVidOplati(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetUserData();
    GetStatus();
    VidOplati();
    // eslint-disable-next-line
  }, []);

  // добавление нового рабочего
  const RefName = useRef();
  const RefStatus = useRef();
  const RefVidOpl = useRef();
  const RefNumber = useRef();
  //   const RefNormativ = useRef();
  const RefBot = useRef();
  const RefMaosh = useRef();
  const RefNorma = useRef();
  const RefPersonName = useRef(null);

  const AddNewUser = () => {
    if (RefVidOpl.current.value === 'default') {
      swal({
        title: 'Забыли выбрать вид оплаты труда!!',
        text: 'Остальные данные можно ввести потом!',
        icon: 'warning',
        buttons: 'Ok',
      });
      return null;
    }
    if (RefStatus.current.value === 'default') {
      swal({
        title: 'Забыли выбрать статус!!',
        text: 'Остальные данные можно ввести потом!',
        icon: 'warning',
        buttons: 'Ok',
      });
      return null;
    }
    axios({
      method: 'post',
      url: `${config.DB_URL}/add-workers.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        token: config.TOKEN,
        fp_id: RefName?.current?.value || 'default',
        lavozim_id: RefStatus.current.value,
        ishturi_id: RefVidOpl.current.value,
        telefon: RefNumber.current.state.value,
        telegram_id: RefBot.current.state.value,
        maosh: RefMaosh.current.state.numAsString,
        norma: RefNorma.current.state.numAsString,
        personName: RefPersonName?.current?.value || '',
      }),
    })
      .then(function (res) {
        // console.log(res);
        FetchWorkData();
        swal({
          title: `OK`,
          //   icon: "error",
          buttons: 'Ok',
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //   786373932

  const [check, setCheck] = useState(false);
  return (
    <DrowerModal name='Добавить нового рабочего'>
      <Title> Данные нового рабочего </Title>
      <Hr />
      <div className={css.BlockAddUsers}>
        <div>
          <Span>Новый рабочий</Span>
          <label htmlFor=''>
            <input
              type='checkbox'
              onChange={(e) => setCheck(e.target.checked)}
              className={css.StyleInpCheck}
              value={check}
            />{' '}
            <span>Если вводите в ручную</span>
          </label>
          {check ? (
            <input
              className={css.StyleInpSel}
              ref={RefPersonName}
              placeholder='Введите имя рабочего'
            />
          ) : (
            <select
              style={{ width: '100%' }}
              className={css.StyleInpSel}
              defaultValue='default'
              ref={RefName}
            >
              <option value='default' disabled>
                Выберите нового рабочего
              </option>
              {!getName
                ? null
                : getName.map((i) => {
                    return (
                      <option key={i.fp_id} value={i.fp_id}>
                        {i.name}
                      </option>
                    );
                  })}
            </select>
          )}
        </div>
        <div>
          {' '}
          <Span>Статус рабочего</Span>
          <select
            style={{ width: '100%' }}
            className={css.StyleInpSel}
            defaultValue='default'
            ref={RefStatus}
          >
            <option value='default' disabled>
              Выберите статус
            </option>
            {!getStatus
              ? null
              : getStatus.map((d) => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  );
                })}
          </select>
        </div>

        <div>
          {' '}
          <Span>Вид расчёта зарплаты</Span>
          <select
            style={{ width: '100%' }}
            className={css.StyleInpSel}
            defaultValue='default'
            ref={RefVidOpl}
          >
            <option disabled value='default'>
              Выберите вид расчета зарплаты
            </option>
            {!getVidOplati
              ? null
              : getVidOplati.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.birlik}
                    </option>
                  );
                })}
          </select>
        </div>

        <div>
          <Span>Номер(тел.)</Span>
          <NumberFormat
            format='(##) ###-##-##'
            mask='_'
            placeholder='(99) 999-99-99'
            className={css.StyleInpSel}
            ref={RefNumber}
          />
        </div>
        <div>
          <Span>Ежемесячная вставка</Span>
          <NumberFormat
            thousandSeparator={true}
            placeholder='3 000 000'
            className={css.StyleInpSel}
            ref={RefMaosh}
          />
        </div>
        <div>
          <Span>Ежемесячный норматив</Span>
          <NumberFormat
            thousandSeparator={true}
            placeholder='Введите норматив'
            className={css.StyleInpSel}
            ref={RefNorma}
          />
        </div>
        <div>
          <Span>Бот ID</Span>
          <NumberFormat
            placeholder='Введите id из Телеграм бота'
            className={css.StyleInpSel}
            ref={RefBot}
          />
        </div>
      </div>

      <div className={css.StyleBtn}>
        <CardButton
          type='submit'
          onClick={AddNewUser}
          className={css.StyleBtnAdd}
        >
          Добавить
        </CardButton>
      </div>
    </DrowerModal>
  );
};

export default AddUser;
