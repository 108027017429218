import React from 'react';
import CurrencyModal from './HeaderCurrency/CurrencyModal';
import HeaderExit from './HeaderExit/HeaderExit';
import OborotTable from './Oborot/OborotTable';
// import LoginChange from './HeaderLogin/LoginChange';
import css from './Header.module.css';
import Diary from './HeaderDiary/Diary';
import Toggle from 'Component/Theme/Toggler';
import { MiniTitle } from 'Component/Theme/CSSstyles';

const Header = ({ theme, toggleTheme }) => {
	const UserId = localStorage.getItem('DataID');
	const User = localStorage.getItem('DataName');

	return (
		<div className={css.HeaderBlock}>
			<div className={css.HeaderBlockUser}>
				<MiniTitle>{User}</MiniTitle>
			</div>
			<div className={css.HeaderBlockBtn}>
				{UserId === '1' ? (
					<>
						<Diary />
						<OborotTable />
						{/* <LoginChange /> */}
					</>
				) : null}
				<CurrencyModal />
				<HeaderExit />
				<Toggle theme={theme} toggleTheme={toggleTheme} />
			</div>
		</div>
	);
};

export default Header;
