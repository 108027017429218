import React, { useState, useEffect, useRef } from "react";
import { Popconfirm, Typography, message } from "antd";
import { useNavigate } from "react-router";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Span,
  Paragraph,
  MiniTitle,
  MainTable,
  TableTd,
  TableTh,
} from "../../../../Component/Theme/CSSstyles";
import swal from "sweetalert";
import axios from "axios";
import ReactToPrint from "react-to-print";
import AddInfoDataTable from "./AddInfoDataTable";
import { nanoid } from "nanoid";
import "../CardMadal.css";
const confirms = () => {
  message.info(<Text type="danger">Данные будут удалены безвозвратно!!!</Text>);
};
const { Text } = Typography;
const textDelete = "Точно удалить?";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const DataTable = ({ debt, title, tableId, fetchTable, addNew }) => {
  const history = useNavigate();
  const componentRef = useRef();
  let ModDebt = debt;

  // запрос для получения данных их базы данных
  const [getdata, setGetData] = useState([]);
  const FunctionGet = () => {
    let token = localStorage.getItem("DataToken");
    var data = JSON.stringify({
      token: token,
      table_id: tableId,
    });
    var config = {
      method: "post",
      url: `${url}/get-table-data-single.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setGetData([response.data]);
        //   const IfoSt = response.data.rows;
        //   IfoSt.reverse();
        //  console.log("IfoSt", IfoSt);
      })
      .catch(function (error) {
        swal({
          title: "Ошибка!",
          text: `Обновите страницу!`,
          icon: "error",
          buttons: {
            cancel: "Нет!",
            catch: {
              text: "Ok",
              value: "Ok",
            },
          },
        }).then((willDelete) => {
          if (willDelete === "Ok") {
            history("/");
          }
        });
      });
  };

  const handleDelete = (key) => {
    let token = localStorage.getItem("DataToken");
    var data = JSON.stringify({
      token: token,
      id: key,
    });
    var config = {
      method: "DELETE",
      url: `${url}/delete-table-row.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        FunctionGet();
      })
      .catch(function (error) {
        swal({
          title: "Ошибка!",
          text: `Обновите страницу!`,
          icon: "error",
          buttons: {
            cancel: "Нет!",
            catch: {
              text: "Ok",
              value: "Ok",
            },
          },
        }).then((willDelete) => {
          if (willDelete === "Ok") {
            history("/client");
          }
        });
      });
  };

  useEffect(() => {
    FunctionGet();
    // eslint-disable-next-line
  }, []);

  // вывод данных в таблицу
  const TableData = getdata.map((item) => {
    return (
      <div key={nanoid()}>
        <div>
          <p>Учётная единица таблицы: {item.valyuta}</p>
        </div>
        <table style={{ width: "100%" }} className={""}>
          <thead>
            <tr>
              <TableTh>Дата</TableTh>
              <TableTh>Заметки</TableTh>
              <TableTh>Расход</TableTh>
              <TableTh>Калькуляцыя Расхода</TableTh>
              <TableTh>Возвращенная сумма</TableTh>
              <TableTh>Калькуляцыя возврата</TableTh>
              <TableTh>
                <DeleteOutlined />
              </TableTh>
            </tr>
          </thead>
          <tbody>
            {item.rows.map((d) => {
              let itemModDebt = ModDebt - d.rep_pay;
              ModDebt = itemModDebt;
              let itemModRep = ModDebt - d.bor_pay;
              ModDebt = itemModRep;
              return (
                <tr key={nanoid()}>
                  <TableTd
                    style={{ fontSize: 20, width: 150, textAlign: "center" }}
                  >
                    {d.date}
                  </TableTd>
                  <TableTd style={{ fontSize: 20 }}>{d.comment}</TableTd>
                  <TableTd
                    style={{ fontSize: 20, width: 250, textAlign: "center" }}
                  >
                    {priceTo(d.rep_pay)}
                  </TableTd>
                  <TableTd
                    style={{ fontSize: 20, width: 250, textAlign: "center" }}
                  >
                    {d.rep_pay <= 0 ? 0 : priceTo(itemModDebt)}
                  </TableTd>
                  <TableTd
                    style={{ fontSize: 20, width: 250, textAlign: "center" }}
                  >
                    {priceTo(d.bor_pay)}
                  </TableTd>
                  <TableTd
                    style={{ fontSize: 20, width: 250, textAlign: "center" }}
                  >
                    {d.bor_pay <= 0 ? 0 : priceTo(itemModRep)}
                  </TableTd>
                  <TableTd
                    style={{ fontSize: 20, width: 50, textAlign: "center" }}
                  >
                    <Popconfirm
                      title={textDelete}
                      placement="right"
                      okText="ДА"
                      cancelText="НЕТ"
                      onConfirm={() => handleDelete(d.id)}
                    >
                      <CloseCircleOutlined
                        style={{ color: "red" }}
                        onClick={confirms}
                      />
                    </Popconfirm>
                  </TableTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  });

  const Calculation = getdata.map((data) => {
    let totalBor = 0;
    let totalRep = 0;
    let totalOstatok = 0;
    let totalOstatok2 = 0;
    //  let totalRasxod = 0;
    data.rows.forEach(({ rep_pay, bor_pay }) => {
      totalBor += Number(bor_pay);
      totalRep += Number(rep_pay);
      totalOstatok = Number(debt) - totalRep;
      totalOstatok2 = Number(debt) - totalBor;
      // totalRasxod = Number(debt) + totalRep;
      // dataState.map((f) => (f.ostatok = Number(cash) - Number(raschot)));
    });
    return (
      <MainTable key={nanoid()}>
        {totalRep ? (
          <Paragraph className={CSS.CashInfo}>
            Возващенная сумма (дебит):
            <Span className={CSS.CashInfoSpan}> {priceTo(totalRep)}</Span>
          </Paragraph>
        ) : null}

        {totalBor ? (
          <Paragraph className={CSS.CashInfo}>
            Возващенная сумма (кредит):
            <Span className={CSS.CashInfoSpan}>{priceTo(totalBor)}</Span>
          </Paragraph>
        ) : null}

        {!totalBor ? (
          <Paragraph className={CSS.CashInfo}>
            Остаточная сумма для возврата (дебит):
            <Span className={CSS.CashInfoSpan}>{priceTo(totalOstatok)}</Span>
          </Paragraph>
        ) : null}

        {!totalRep ? (
          <Paragraph className={CSS.CashInfo}>
            Остаточная сумма для возврата (кредит):
            <Span className={CSS.CashInfoSpan}>{priceTo(totalOstatok2)}</Span>
          </Paragraph>
        ) : null}

        {/* {totalBor ? null : (
          <Paragraph className={CSS.CashInfo}>
            Общий расход:
            <Span className={CSS.CashInfoSpan}>{priceTo(totalRasxod)}</Span>
          </Paragraph>
        )} */}
      </MainTable>
    );
  });

  return (
    <main>
      <ReactToPrint
        trigger={() => (
          <button className="exit__modal-card-btn">Печатать</button>
        )}
        content={() => componentRef.current}
      />

      <AddInfoDataTable tableId={tableId} />

      <div ref={componentRef} style={{ paddingTop: 20 }}>
        <Paragraph className={CSS.CashInfo} key={nanoid()}>
          <MiniTitle className={CSS.CashInfoSpan}>{title}</MiniTitle>
          <br />
          <MiniTitle className={CSS.CashInfoSpan}>{priceTo(debt)}</MiniTitle>
        </Paragraph>
        <div>{Calculation}</div>
        <div>{TableData}</div>
      </div>
    </main>
  );
};

export default DataTable;
