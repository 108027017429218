import React from "react";
import { Input } from "antd";
const { Search } = Input;
export default function UseSerchHook({ setSerch }) {
  const onSearch = (e) => {
    setSerch({ order: e });
  };
  return (
    <div>
      <Search
        allowClear
        placeholder="Поиск..."
        onSearch={onSearch}
        enterButton
      />
    </div>
  );
}
