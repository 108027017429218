import React, { useState } from "react";
import DrawerModal from "../../../Component/ModalComponent/DrowerModal/DrowerModal";
import { DollarCircleOutlined } from "@ant-design/icons";
import { Title, Span, Hr } from "../../../Component/Theme/CSSstyles";
import style from "./PayCash.module.css";
import CashDataTable from "./CashDataTabnle/CashDataTable";
import CashAddForm from "./CashAddForm/CashAddForm";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const PayCash = ({ id, name, balans, setRenderEyes }) => {
  const [infoCash, setInfoCash] = useState([]);
  return (
    <DrawerModal name={<DollarCircleOutlined title="Выдача зарплаты" />}>
      <div>
        <div className={style.CashTitileBlock}>
          <Title>{name}</Title>
          <Title>Баланс: {priceTo(balans)} сум.</Title>
          <Span>Выдача зарплаты или аванса</Span>
        </div>
        <Hr />
        <CashAddForm
          id={id}
          balans={balans}
          name={name}
          setInfoCash={setInfoCash}
          setRenderEyes={setRenderEyes}
        />
        <Hr />
        <CashDataTable
          id={id}
          infoCash={infoCash}
          setRenderEyes={setRenderEyes}
        />
      </div>
    </DrawerModal>
  );
};

export default PayCash;
