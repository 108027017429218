import React, { useState, useEffect } from 'react';
import { Main, Paragraph, Span } from '../../Component/Theme/CSSstyles';
import axios from 'axios';
import Loding from '../Loding';
import { TRow, TableTh } from '../../Component/Theme/CSSstyles';
import css from './Contragent.module.css';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import swal from 'sweetalert';
import { Tooltip } from 'antd';
import { numberFormat } from '../../tools/numberFormat';
import { config } from 'config/configs';

const ContragentTable = ({ pageId, dataContragent }) => {
  const token = localStorage.getItem('DataToken');
  const UserId = localStorage.getItem('DataID');
  const [oneData, setOneData] = useState([]);

  const GetContagentOneData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-baza.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: token, tashkilot_id: pageId }),
    })
      .then(function (response) {
        if (response.status === 200) {
          const New = response.data;
          New.reverse();
          setOneData(New);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetContagentOneData();
    // eslint-disable-next-line
  }, [pageId, dataContragent]);

  const handleDelete = (id) => {
    swal({
      title: 'Точно удалить!',
      text: `после удаления вы не сможете восстановить данные`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/delete-baza.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify({ token: token, id: id }),
        })
          .then(function (response) {
            if (response.status === 200) {
              GetContagentOneData();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  if (oneData.length === 0) {
    return (
      <div className={css.ContragentLoding}>
        <Loding />
      </div>
    );
  }

  const TableContragent = oneData.map((i) => (
    <TRow key={i.id} className={css.body_row}>
      <td className={css.body_row_td}>{i.username}</td>
      <td className={css.body_row_td}>{i.date}</td>
      <td className={css.body_row_td}>{i.comment}</td>
      <td className={css.body_row_td}>{i.text}</td>
      <td className={css.body_row_td}>{numberFormat(i.oldi)}</td>
      <td className={css.body_row_td}>{numberFormat(i.berdi)}</td>
      <td className={css.body_row_td}>{numberFormat(i.qoldi)}</td>
      <td className={css.body_row_td}>
        {UserId === '1' ? (
          <Tooltip title='Удалить' color={'cyan'}>
            {' '}
            <button
              onClick={() => handleDelete(i.id)}
              className={css.DeleteDataContragent}
            >
              <CloseOutlined />
            </button>
          </Tooltip>
        ) : null}
      </td>
    </TRow>
  ));

  let totalBorrow = 0;
  let totalRepayment = 0;
  let totolDebt = 0;

  oneData.forEach(({ oldi, berdi, qoldi }) => {
    totalBorrow += Number(oldi);
    totalRepayment += Number(berdi);
    totolDebt = totalRepayment - totalBorrow;
  });
  return (
    <div>
      <Main className={''}>
        <Paragraph>
          Приходная сумма:
          <Span className={''}>{totalBorrow.toLocaleString()}</Span>
        </Paragraph>

        <Paragraph>
          Расходная сумма:
          <Span className={''}>{totalRepayment.toLocaleString()}</Span>{' '}
        </Paragraph>
        <Paragraph>
          Задолженность:
          <Span className={''}>{totolDebt.toLocaleString()}</Span>{' '}
        </Paragraph>
      </Main>
      <table className={css.table}>
        <thead>
          <tr className={css.table_header_row}>
            <TableTh className={css.header_row_th}>Пользователь</TableTh>
            <TableTh className={css.header_row_th}>Дата</TableTh>
            <TableTh className={css.header_row_th}>Курс</TableTh>
            <TableTh className={css.header_row_th}>Заметки</TableTh>
            <TableTh className={css.header_row_th}>Приход</TableTh>
            <TableTh className={css.header_row_th}>Расход</TableTh>
            <TableTh className={css.header_row_th}>Остаток</TableTh>
            <TableTh className={css.ContragentTableHead}>
              <DeleteOutlined />
            </TableTh>
          </tr>
        </thead>
        <tbody>{TableContragent}</tbody>
      </table>
    </div>
  );
};

export default ContragentTable;
