import React from "react";
import { TableTh, TableTd } from "../../../../Component/Theme/CSSstyles";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const FilterTable = ({ sumplus, usdplus, summinus, usdminus }) => {
  return (
    <div>
      <table style={{ width: "100%" }} className={""}>
        <thead>
          <tr>
            <TableTh colSpan={2}>CУМ</TableTh>
            <TableTh colSpan={2}>USD</TableTh>
          </tr>
          <tr>
            <TableTh>Приход</TableTh>
            <TableTh>Расхода</TableTh>
            <TableTh>Приход</TableTh>
            <TableTh>Расходы</TableTh>
          </tr>
        </thead>
        <tbody>
          <tr key={""}>
            <TableTd style={{ fontSize: 20 }}>{priceTo(sumplus)}</TableTd>
            <TableTd style={{ fontSize: 20 }}>{priceTo(summinus)}</TableTd>
            <TableTd style={{ fontSize: 20 }}>{priceTo(usdplus)}</TableTd>
            <TableTd style={{ fontSize: 20 }}>{priceTo(usdminus)}</TableTd>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FilterTable;
