export const LOADER_ORDER = "LOADER_ORDER";
export const LOADER_AVANS = "LOADER_AVANS";
export const ADD_ORDER = "ADD_ORDER";
export const FETCH_ORDER = "FETCH_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const MINUS_CASH_ORDER = "MINUS_CASH_ORDER";
export const ADD_CASH_ORDER = "ADD_CASH_ORDER";
export const AVANS_CASH_ORDER = "AVANS_CASH_ORDER";
export const FETCH_AVANS_ORDER = "FETCH_AVANS_ORDER";
export const REMOVE_AVANS_ORDER = "REMOVE_AVANS_ORDER";
