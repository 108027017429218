import React from 'react';
import {NumberOutlined, TableOutlined, TeamOutlined} from '@ant-design/icons';
import StatisticComponent from "../../Component/Statistic/StatisticComponent";
import css from "./Index.module.css"

const StatisticPage = () => {
	return (
		<div className={css.blockStatictiks}>
			<StatisticComponent data="80" icon={<TableOutlined/>} title="Принята заказов" />
			<StatisticComponent data="54" icon={<TableOutlined/>} title="Выполнена заказов" />
			<StatisticComponent data="12" text2="2" text="m" icon={<NumberOutlined/>} title="Количество рабочих" />
			<StatisticComponent data="12" icon={<TeamOutlined/>} title="Каличество рабочих" />
		</div>
	)
};
export default StatisticPage;







