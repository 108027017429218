import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Space } from "antd";
import axios from "axios";
import { Title } from "../../Component/Theme/CSSstyles";
import swal from "sweetalert";
import ModalCenter from "../../Component/ModalComponent/ModalMiddle/ModalCenter";
import SettingLOginForm from "./SettingLOginForm";
import css from "./Setting.module.css";

const url = process.env.REACT_APP_DB_URL;

function SettingLogin() {
  const history = useNavigate();
  // const [form] = Form.useForm();
  const token = localStorage.getItem("DataToken");

  const [getUsers, setGetUsers] = useState([]);

  const relogEffect = () => {
    let data = JSON.stringify({
      token: token,
    });
    let config = {
      method: "POST",
      url: `${url}/get-users.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setGetUsers(response.data);
      })
      .catch(function (error) {
        console.log(error);
        swal({
          title: "Ошибка!",
          text: `${error}`,
          icon: "warning",
          buttons: {
            catch: {
              text: "Перезагрузиться???",
              value: "ДА",
            },
          },
          //dangerMode: true,
        }).then((willDelete) => {
          if (willDelete === "Да") {
            history("/add");
          }
        });
      });
  };

  useEffect(() => {
    relogEffect();
    // eslint-disable-next-line
  }, []);

  //   const editUser = (user) => {
  //     console.log(user);
  //   };

  const column = [
    {
      title: "№",
      key: "index",
      render: (_, rec, index) => {
        return index + 1;
      },
    },
    {
      title: "Пользователь",
      dataIndex: "fio",
      key: "fio",
    },
    {
      title: "Роль",
      dataIndex: "rol",
      key: "rol",
    },
    {
      title: "Логин",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Действия",
      render: (_, rec) => {
        return (
          <Space direction="horizontal">
            <ModalCenter name={"Изменить"}>
              <SettingLOginForm user={rec} />
            </ModalCenter>
            <button className={css.settingDeleteBtn}>Удалить</button>
          </Space>
        );
      },
    },
  ];

  if (!getUsers) {
    return <p>Загрузка....</p>;
  }

  return (
    <>
      <Title>Пользователи</Title>
      <Table
        rowKey={(row) => row.id}
        dataSource={getUsers}
        columns={column}
        pagination={false}
      />
    </>
  );
}

export default SettingLogin;
