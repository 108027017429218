import React, { useReducer } from "react";
import axios from "axios";
import swal from "sweetalert";
import { OrderCashReduser } from "./OperationCashReduser";
import OrderHook from "../OrderHook";
import { AVANS_CASH_ORDER, FETCH_AVANS_ORDER, LOADER_AVANS, REMOVE_AVANS_ORDER } from "typs/orderTypes";
import { config } from "config/configs";
import { OperationCashContext } from "Context/context";


export default function OperationCashState({ children }) {
	const username = localStorage.getItem("DataName");
	const { getOrder2 } = OrderHook();
	const initialState = {
		data: [],
		loading: false,
	};
	const [state, dispatch] = useReducer(OrderCashReduser, initialState);
	// !спиннер
	const Loding = () => dispatch({ type: LOADER_AVANS });
	// ! минусуем сумму из заказа
	const onMinusCash = async (id, refAvans, refComm) => {
		await swal({
			title: "Внимание проверьте данные !",
			text: ` ${refAvans.current.state.numAsString} сумм, будет минусована c заказа!`,
			icon: "warning",
			buttons: {
				cancel: "Отменить!",
				catch: {
					text: "OK",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				axios({
					method: "post",
					url: `${config.DB_URL}/update-order.php`,
					headers: {
						"Content-Type": "text/plain",
					},
					data: JSON.stringify({
						order_id: id,
						minus_cash: refAvans.current.state.numAsString,
						comment: refComm.current.value,
						token: config.TOKEN,
						username: username,
					}),
				}).then(function (res) {
					if (res.status === 200) {
						swal({
							title: "Отлично!",
							text: `${res.data.xabar}`,
							icon: "success",
							buttons: "OK",
						});
						//   dispatch({ type: MINUS_CASH_ORDER });
						getOrder2();
					}
				})
					.catch(function (error) {
						swal({
							title: "Ошибка",
							text: `${error}`,
							icon: "error",
							buttons: "OK",
						});
					});
			}
		});
	};
	// !добавляем сумму к заказу
	const onPlusCash = async (id, refAvans, refComm) => {

		;
		await swal({
			title: "Внимание проверьте данные !",
			text: ` ${refAvans.current.state.numAsString} сумм, добавиться в заказа!`,
			icon: "warning",
			buttons: {
				cancel: "Отменить!",
				catch: {
					text: "OK",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				axios({
					method: "post",
					url: `${config.DB_URL}/update-order.php`,
					headers: {
						"Content-Type": "text/plain",
					},
					data: JSON.stringify({
						order_id: id,
						add_cash: refAvans.current.state.numAsString,
						username: username,
						comment: refComm.current.value,
						token: config.TOKEN,
					}),
				}).then(function (res) {
					if (res.status === 200) {
						swal({
							title: "Отлично!",
							text: `${res.data.xabar}`,
							icon: "success",
							buttons: "OK",
						});
						//   dispatch({ type: MINUS_CASH_ORDER });
						getOrder2();
					}
				})
					.catch(function (error) {
						swal({
							title: "Ошибка",
							text: `${error}`,
							icon: "error",
							buttons: "OK",
						});
					});
			}
		});
	};
	// !Добавляем оплату за заказ
	const onAddAvans = async (id, refAvans, refComm, cash, raschot) => {
		if (refAvans > Number(cash)) {
			swal({
				title: "Внимание!!!",
				text: "Сумма аванса больше, чем сумма заказа!!!",
				icon: "error",
				button: "Ок",
			});
			return null;
		}
		if (Number(refAvans) + Number(raschot) > Number(cash)) {
			swal({
				title: "Ошибка",
				text: "Сумма аванса больше, чем сумма заказа!!!",
				icon: "error",
				button: "Ок",
			});
			return null;
		}
		if (refAvans === Number(cash)) {
			swal({
				title: "Отлично!!!  2",
				text: "Клиент рассчитался данные переданы в Архив.",
				icon: "success",
				button: "Ок",
			});
		}
		if (Number(refAvans) + Number(raschot) === Number(cash)) {
			swal({
				title: "Наконец то!",
				text: "Клиент рассчитался данные переданы в Архив.",
				icon: "success",
				button: "Ок",
			});
		}
		;

		await axios({
			method: "post",
			url: `${config.DB_URL}/add-avans.php`,
			headers: {
				"Content-Type": "text/plain",
			},
			data: JSON.stringify({
				id: id,
				avans: refAvans.current.state.numAsString,
				token: config.TOKEN,
				comment: refComm.current.value,
			}),
		}).then(function (res) {
			dispatch({
				type: AVANS_CASH_ORDER,
				data: res.data,
			});
			swal({
				title: "Сумма добавлена!",
				icon: "success",
				button: "Ок",
			});
			getOrder2();
		})
			.catch(function (error) {
				swal({
					title: "Oops...!",
					icon: "warning",
					text: `${error}`,
					button: "Ок",
				});
			});

		refAvans.current.state.numAsString = "";
		refComm.current.value = "";
	};
	// Получаем данные авансов
	const fetchDataAvans = (id) => {
		Loding();
		axios({
			method: "POST",
			url: `${config.DB_URL}/get-payments-id.php`,
			headers: {
				"Content-Type": "text/plain",
			},
			data: JSON.stringify({
				order_id: id,
				token: config.TOKEN,
			}),
		}).then((res) => {
			if (200 === res.status) {
				dispatch({
					type: FETCH_AVANS_ORDER,
					data: res.data,
				});
				getOrder2();
			}
		}).catch((err) => {
			console.log("GetInfoErr > > > >", err);
		});
	};

	const onRemoveAvans = (key) => {
		swal({
			title: "Вы уверены!",
			icon: "warning",
			buttons: {
				cancel: "Отменить!",
				catch: {
					text: "OK",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				axios({
					method: "POST",
					url: `${config.DB_URL}/delete-payment.php`,
					headers: {
						"Content-Type": "text/plain",
					},
					data: JSON.stringify({
						avans_id: key,
						token: config.TOKEN,
					}),
				}).then(function (res) {
					if (res.status === 200) {
						dispatch({ type: REMOVE_AVANS_ORDER, key: key });
						swal({
							title: "Отлично!",
							text: `${res.data.xabar}`,
							icon: "success",
							buttons: "OK",
						});

						getOrder2();
						//   fetchDataAvans();
					}
				})
					.catch(function (error) {
						swal({
							title: "Ошибка",
							text: `${error}`,
							icon: "error",
							buttons: "OK",
						});
					});
			}
		});
	};
	return (
		<OperationCashContext.Provider
			value={{
				onMinusCash,
				onPlusCash,
				onAddAvans,
				fetchDataAvans,
				onRemoveAvans,
				data: state.data,
				loding: state.loding,
			}}
		>
			{children}
		</OperationCashContext.Provider>
	);
}
