import React from "react";
import "antd/dist/antd.min.css";
// import WorkersMark from "../PageComponent/WorkersMark";
import style from "./Page.module.css";
import { Title } from "../Component/Theme/CSSstyles";
import WorkersFormMonth from "../PageComponent/WorkersTableDay/WorkersFormMonth";
function UserUchot() {
  return (
    <div style={{ paddingTop: "5px" }}>
      <Title className={style.ClientTitle}>Ежедневный учёт рабочих</Title>
      <div>
        <WorkersFormMonth />
      </div>
    </div>
  );
}

export default UserUchot;
