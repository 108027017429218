import React, { forwardRef, useContext } from 'react';
import { DiatyContext } from 'Context/context';
import { CardSpan, TableTd, TableTh, Title } from 'Component/Theme/CSSstyles';
import { numberFormat } from 'tools/numberFormat';
import Loding from 'PageComponent/Loding';
import css from './Diary.module.css';

export const DiaryTable = forwardRef(({ props }, ref) => {
  const Data = useContext(DiatyContext);
  const MapData = Data[0].map((i) => {
    const Ostatok = i.jamikrim - i.jamichiqim;
    return (
      <div style={{ paddingTop: 30 }}>
        <Title>
          <CardSpan style={{ paddingLeft: 30, paddingRight: 30 }}>
            Приход:
          </CardSpan>
          {numberFormat(i.jamikrim)}
        </Title>

        <Title>
          <CardSpan style={{ paddingLeft: 30, paddingRight: 30 }}>
            Расход:
          </CardSpan>
          {numberFormat(i.jamichiqim)}
        </Title>

        <Title>
          <CardSpan style={{ paddingLeft: 30, paddingRight: 30 }}>
            Остаток:
          </CardSpan>
          {numberFormat(Ostatok)}
        </Title>
      </div>
    );
  });
  if (!Data[0][0]) {
    return (
      <div className={css.DiaryLoding}>
        <Loding />
      </div>
    );
  }

  const arrayOfObj = Object.entries(Data[0][0] ?? {}).filter(([key, value]) => {
    return Array.isArray(value);
  });

  const maxLength = Math.max(...arrayOfObj.map((item) => item[1].length));

  const row = new Array(maxLength);

  for (let index = 0; index < maxLength; index++) {
    const obj = {};
    for (const [key, value] of arrayOfObj) {
      obj[key] = value[index];
    }
    row[index] = obj;
  }
  //   console.log("row>>", row);

  //   console.log(row);
  return (
    <div className={css.DiaryTableBlock} ref={ref}>
      <table style={{ width: '100%' }} className={css.DiaryTable}>
        <thead>
          <tr>
            <TableTh colSpan={2}>Приход</TableTh>
            <TableTh colSpan={6}>Расходы</TableTh>
          </tr>
          <tr>
            <TableTh colSpan={2}>От клиентов</TableTh>
            <TableTh colSpan={2}>Контрагенты</TableTh>
            {/* <TableTh colSpan={2}>Стекло</TableTh> */}
            <TableTh colSpan={2}>Другие расходы</TableTh>
            {/* <TableTh colSpan={2}>Зарплаты</TableTh> */}
            <TableTh colSpan={2}>Авансы рабочим</TableTh>
          </tr>
          <tr>
            <TableTh colSpan={1}>Имя</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh>
            <TableTh colSpan={1}>Комент</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh>
            {/* <TableTh colSpan={1}>Комент</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh> */}
            <TableTh colSpan={1}>Комент</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh>
            {/* <TableTh colSpan={1}>Имя</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh> */}
            <TableTh colSpan={1}>Имя</TableTh>
            <TableTh colSpan={1}>Сумма</TableTh>
          </tr>
        </thead>
        <tbody>
          {row.map((item, idx) => {
            return (
              <tr key={idx}>
                <TableTd>{item.order_payments?.client}</TableTd>
                <TableTd>
                  {item.order_payments?.summa &&
                  item.order_payments?.summa !== '0'
                    ? numberFormat(item.order_payments?.summa)
                    : null}
                </TableTd>
                <TableTd>
                  {item.baza?.rasxod > 0 ? item.baza?.text : null}
                </TableTd>
                <TableTd>
                  {item.baza?.rasxod > 0
                    ? numberFormat(item.baza?.rasxod)
                    : null}
                </TableTd>
                {/* <TableTd>{item.oyna?.text}</TableTd>
                <TableTd>{numberFormat(item.oyna?.rasxod)}</TableTd> */}
                <TableTd>{item.rasxod?.comment}</TableTd>
                <TableTd>{numberFormat(item.rasxod?.harajat)}</TableTd>
                {/* <TableTd>{item.maosh?.fio}</TableTd> */}
                {/* <TableTd>{numberFormat(item.maosh?.summa)}</TableTd> */}
                <TableTd>{item.salary_payment?.fio}</TableTd>
                <TableTd>{numberFormat(item.salary_payment?.summa)}</TableTd>
              </tr>
            );
          })}
        </tbody>
      </table>
      {MapData}
    </div>
  );
});
