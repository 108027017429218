import React, { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {Space} from "antd";
import useRequiredAuth from "hook/useRequiredAuth";
import { CardSpan } from "../../Component/Theme/CSSstyles";
import CSS from "./Private.module.css";

const PrivateKeyEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useRequiredAuth();
  const PinRef = useRef();

  const fromPage = location.state?.from?.pathname || null;

  const onSubmitKey = () => {
    const pin = PinRef.current.value;
    if (pin === process.env.REACT_APP_CAVER_KEY) {
      signIn(pin, () => navigate(fromPage, { replace: true }));
    } else if (pin !== process.env.REACT_APP_CAVER_KEY) {
      navigate(process.env.REACT_APP_CAVER);
    }
  };


  useEffect(() => {
    PinRef.current.focus();
  },[fromPage]);

  return (
    <div className={CSS.CaverBlock}>
      <CardSpan>Введите пин код!</CardSpan>
      <Space direction="horizontal">
        <input type="password" ref={PinRef} className={CSS.CaverInp} />
        <button onClick={onSubmitKey} className={CSS.CaverInp}>
          ок
        </button>
      </Space>
    </div>
  );
};

export default PrivateKeyEntry;
