import React, { useContext } from 'react';
import ModalCenter from '../../../Component/ModalComponent/ModalMiddle/ModalCenter';
import {
  Paragraph,
  Title,
  Hr,
  CardButton,
} from '../../../Component/Theme/CSSstyles';
import { Select, Form, Input } from 'antd';
import NumberFormat from 'react-number-format';
import css from './WorkPrize.module.css';
import axios from 'axios';
import swal from 'sweetalert';
import { WorkingRender } from 'Context/context';
import { config } from 'config/configs';

const WorkDeduction = ({ workData }) => {
  const FetchWorkData = useContext(WorkingRender);
  const [form] = Form.useForm();
  let option = [];
  workData.map((i) =>
    option.push({
      value: i.id,
      label: i.username,
    })
  );

  const AddDeduction = (value) => {
    value.token = localStorage.getItem('DataToken');

    swal({
      title: 'Внимание!',
      text: `Вы уверены о штрафе на ${value.summa} сум?!`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/add-jarima.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify(value),
        })
          .then(function (response) {
            if (response.status === 200) {
              FetchWorkData();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        form.resetFields();
      }
    });
  };
  return (
    <ModalCenter name='Штраф'>
      <Title>Штраф</Title>
      <Hr />
      <Form layout='vertical' onFinish={AddDeduction} form={form}>
        <Form.Item
          label={<Paragraph>Выберите рабочего</Paragraph>}
          name='worker_id'
          rules={[
            {
              required: true,
              message: 'Вы не выбрали мастера!',
            },
          ]}
        >
          <Select
            mode='tags'
            style={{ width: '600px', fontSize: 20 }}
            placeholder='Выберите рабочего'
            options={option}
          />
        </Form.Item>
        <Form.Item
          label={<Paragraph>Введите сумму премии</Paragraph>}
          name='summa'
          rules={[
            {
              required: true,
              message: 'а где премия?!',
            },
          ]}
        >
          <NumberFormat
            thousandSeparator={true}
            placeholder='3 000 000'
            customInput={Input}
            className={css.WorkPrizeInp}
          />
        </Form.Item>
        <Form.Item label={<Paragraph>Заметка</Paragraph>} name='comment'>
          <Input.TextArea className={css.WorkPrizeInp} />
        </Form.Item>
        <Form.Item>
          <div className={css.WorkPrizeBlockBtn}>
            <CardButton className={css.WorkPrizeBtn}>Добавить</CardButton>
          </div>
        </Form.Item>
      </Form>
    </ModalCenter>
  );
};

export default WorkDeduction;
