import React from "react";
import "antd/dist/antd.min.css";
import TableClientRusset from "../PageComponent/TableClientRusset";
import style from "./Page.module.css";
import { Title } from "../Component/Theme/CSSstyles";
const ClientRusset = ({ propsInfo }) => {
  return (
    <div>
      <Title className={style.ClientTitle}>Выполненные заказы</Title>
      <div className={style.Russetclients}>
        <TableClientRusset propsInfo={propsInfo} />
      </div>
    </div>
  );
};

export default ClientRusset;
