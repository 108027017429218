import { Space, Spin } from "antd";
import React from "react";

const Loding = () => (
  <Space size="middle" style={{ paddingTop: 50 }}>
    <Spin size="large" />
  </Space>
);

export default Loding;
