import React, { useRef } from "react";
import NumberFormat from "react-number-format";
import ModalCenter from "../../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import { MiniTitle } from "../../../../Component/Theme/CSSstyles";
import css from "./FormMinus.module.css";

export default function ClientAvansPlus({
  id,
  onFinesh,
  title,
  cash,
  raschot,
  buttonText,
}) {
  const MyRef = useRef();
  const MyComm = useRef();
  let refAvans = MyRef;
  let refComm = MyComm;

  return (
    <ModalCenter name={title}>
      <MiniTitle>{title}</MiniTitle>
      <div className={css.ClientAvansForm}>
        <label style={{ width: "100%" }}>
          Введите сумму
          <NumberFormat
            className={css.ClientAvansInput}
            ref={MyRef}
            required
            thousandSeparator={true}
            autoComplete="off"
          />
        </label>
        <label>
          Введите коментарии или замутку
          <textarea className={css.ClientAvansInput} ref={MyComm} />
        </label>
        <button
          type="Submit"
          onClick={() => onFinesh(id, refAvans, refComm, cash, raschot)}
          className={css.ClientAvansButton}
        >
          {buttonText}
        </button>
      </div>
    </ModalCenter>
  );
}
