import React from "react";
import { Button, Form, Input } from "antd";
import { Spans, Title } from "../../Component/Theme/CSSstyles";
import axios from "axios";
import swal from "sweetalert";

const url = process.env.REACT_APP_DB_URL;
const SettingLoginForm = ({ user }) => {
  const onChangeLogin = (values) => {
    values.token = localStorage.getItem("DataToken");
    const config = {
      method: "post",
      url: `${url}/update-user.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(values),
    };

    axios(config)
      .then(function (response) {
        swal({
          title: "Отлично!",
          text: `Новые данные сахранены!  Логин: ${values.newlogim}, Пароль: ${values.newpassword} Пожалуйста перезагрузитесь!`,
          icon: "success",
          buttons: {
            cancel: "Нет!",
            catch: {
              text: "Ok",
              value: "Ok",
            },
          },
        }).then((willDelete) => {
          if (willDelete === "Ok") {
            localStorage.removeItem("DataToken");
            localStorage.removeItem("DataID");
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Title>{user.fio}</Title>
      <Form
        layout="vertical"
        colon={false}
        onFinish={onChangeLogin}
        autoComplete="off"
      >
        <Form.Item
          label={<Spans>Новый логин</Spans>}
          name="newlogim"
          rules={[
            {
              required: true,
              message: "Введите новый логин!",
            },
          ]}
        >
          <Input
            size="large"
            style={{ width: "500px" }}
            placeholder="Новый логин."
          />
        </Form.Item>
        <Form.Item
          label={<Spans>Новый пароль</Spans>}
          name="newpassword"
          rules={[
            {
              required: true,
              message: "Введите новый пароль!",
            },
          ]}
        >
          <Input
            size="large"
            style={{ width: "500px" }}
            placeholder="Новый пароль."
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingLoginForm;
