import React from "react";
import Loding from "../../Loding";
import css from "../Contragent.module.css";
import { TableTh } from "../../../Component/Theme/CSSstyles";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const ContragentIntervalTable = ({ intevalContr }) => {
  if (!intevalContr) {
    return <Loding />;
  }

  const DataContragentInterval = intevalContr.map((i, index) => {
    return (
      <tr key={i.id} className={css.ContragentIntervalTr}>
        <td className={css.ContragentIntervalTd}>{index + 1}</td>
        <td className={css.ContragentIntervalTd}>{i.date}</td>
        <td className={css.ContragentIntervalTd}>{i.comment}</td>
        <td className={css.ContragentIntervalTd}>{i.text}</td>
        <td className={css.ContragentIntervalTd}>{priceTo(i.oldi)}</td>
        <td className={css.ContragentIntervalTd}>{priceTo(i.berdi)}</td>
        <td className={css.ContragentIntervalTd}>{priceTo(i.qoldi)}</td>
      </tr>
    );
  });

  let TotalOstatok = 0;
  let TotalOldi = 0;
  let TotalBerdi = 0;

  intevalContr.forEach(({ oldi, berdi, qoldi }) => {
    TotalOstatok += Number(qoldi);
    TotalOldi += Number(oldi);
    TotalBerdi += Number(berdi);
  });

  return (
    <div>
      <div className={css.ContragentIntervalCashData}>
        <p className={css.ContragentIntervalP}>Приход: {priceTo(TotalOldi)}</p>
        <p className={css.ContragentIntervalP}>Расход: {priceTo(TotalBerdi)}</p>
        <p className={css.ContragentIntervalP}>
          Остаток: {priceTo(TotalOstatok)}
        </p>
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <TableTh>№</TableTh>
            <TableTh>Дата</TableTh>
            <TableTh>Курс</TableTh>
            <TableTh>Заметки</TableTh>
            <TableTh>Приход</TableTh>
            <TableTh>Расход</TableTh>
            <TableTh>Остаток</TableTh>
          </tr>
        </thead>
        <tbody>{DataContragentInterval}</tbody>
      </table>
    </div>
  );
};

export default ContragentIntervalTable;
