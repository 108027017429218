export const lightTheme = {
  body: "#FFF",
  text: "#000",
  text2: "#727272",
  text3: "rgba(0,0,0,.85)",
  toggleBorder: "#FFF",
  background: "#FFF",
  background1: "#FFF",
  background2: "#17212b45",
  boxShadow: "0px 0px 20px 1px #000",
  CardShadow: "0px 0px 15px 3px rgba(0,0,0,0.2)",
  border: "1px solid #ff4d4f",
  tableTd: "1px solid #e0e0e0",
};
export const darkTheme = {
  body: "#0e1621",
  text: "#fff",
  text2: "#fff",
  text3: "#FFF",
  th: "#000",
  toggleBorder: "#6B8096",
  background: "#2c4054",
  background1: "#17212b",
  background2: "#17212b",
  boxShadow: "0px 0px 20px 1px #fff",
  CardShadow: "0px 0px 7px 1px #fff",
  border: "1px solid #fff",
  tableTd: "1px solid #6B8096",
};
export const mayTheme = {
  body: "#008080",
  text: "#FFF",
  text2: "#fff",
  text3: "#FFF",
  toggleBorder: "#6B8096",
  background: "#008080",
  background1: "#019191",
  background2: "#019191",
  boxShadow: "0px 3px 20px 1px #fff",
  CardShadow: "0px 0px 7px 1px #fff",
  border: "1px solid #fff",
  tableTd: "1px solid #058686",
};
export const redTheme = {
  body: "#fa8c16",
  text: "#FFF",
  text2: "#FFF",
  text3: "#FFF",
  toggleBorder: "#6B8096",
  background: "#fa8c16",
  background1: "#fa8c16",
  background2: "#ad4e00",
  boxShadow: "0px 3px 20px 1px #fff",
  CardShadow: "0px 0px 7px 1px #fff",
  border: "1px solid #fff",
  tableTd: "1px solid #db6301",
};
export const LimeTheme = {
  body: "#7cb305",
  text: "#fff",
  text2: "#005",
  text3: "#FFF",
  toggleBorder: "#6B8096",
  background: "#7cb305",
  background1: "#7cb305",
  background2: "#5b8c00",
  boxShadow: "0px 3px 20px 1px #000",
  CardShadow: "0px 0px 7px 1px #fff",
  border: "1px solid #008080",
  tableTd: "1px solid #7cb305",
};
