import React, { createContext, useState } from "react";

export const RequiredContext = createContext();

export default function RequiredAuthProvider({ children }) {
  const [isAuth, setIsAuth] = useState(null);
  const signIn = (userKey, cb) => {
    setIsAuth(userKey);
    cb();
  };
  const signOut = (cb) => {
    setIsAuth(null);
    cb();
  };
  const value = { isAuth, signIn, signOut };
  return (
    <RequiredContext.Provider value={value}>
      {children}
    </RequiredContext.Provider>
  );
}
