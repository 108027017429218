import { TableTd, TableTh, TRow } from "Component/Theme/CSSstyles";
import React from "react";
import css from "./OrderTable.module.css";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const OrdersTable = ({ order }) => {
  //   adres: "Шохи Зинда";
  //   cash: "11500000";
  //   date: "13-09-2022";
  //   holati: "progres";
  //   holati2: "progres";
  //   id: "9";
  //   info: "";
  //   installationDate: "25-09-2022";
  //   kv: "0";
  //   name: "Малика";
  //   raschot: "6500000";
  //   tel: "(99) 112-71-47";
  //   username: "Xasanov Javohir";
  const orders = order?.map((i) => {
    return (
      <TRow key={i.id}>
        <TableTd>
          {i.date}, <br /> {i.installationDate}
        </TableTd>
        <TableTd>{i.kv}</TableTd>
        <TableTd>{priceTo(i.cash)}</TableTd>
        <TableTd>{priceTo(i.raschot)}</TableTd>
        <TableTd>{priceTo(i.cash - i.raschot)}</TableTd>
        <TableTd>{i.username}</TableTd>
      </TRow>
    );
  });

  return (
    <div>
      <table className={css.orderTable}>
        <thead>
          <TRow>
            <TableTh>
              Дата заказа <br /> и установки
            </TableTh>
            <TableTh>Объём заказа</TableTh>
            <TableTh>Сумма заказа</TableTh>
            <TableTh>Сумма взноса</TableTh>
            <TableTh>Остаток</TableTh>
            <TableTh>Заказ принял</TableTh>
          </TRow>
        </thead>
        <tbody>{orders}</tbody>
      </table>
    </div>
  );
};
