import React, { useState, useEffect } from 'react';
import AddValume from './AddFrame/AddValume';
import axios from 'axios';
import TableFrame from './TableFrame/TableFrame';
import { config } from 'config/configs';
import { RenFrameFunction } from 'Context/context';

const FrameMaste = ({ modalGotov }) => {
  const token = config.TOKEN;

  // State для получения имен рабочиз
  const [workerName, setWorkerName] = useState([]);
  // State для получения данных о сборке и стоимости
  const [workerFrame, setWorkerFrame] = useState([]);

  // Запрос для получения имен рабочиз
  const FetchWorkData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-workers.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: token }),
    })
      .then(function (res) {
        if (res.status === 200) {
          setWorkerName(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Запрос для получения данных для таблицы
  const FetchTableData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-yuza-works.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: token }),
    })
      .then(function (res) {
        //   console.log("данных для таблицы Мастер", res.data);
        if (res.status === 200) {
          setWorkerFrame(res.data);
          //  console.log("данных для таблицы Мастер", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchWorkData();
    FetchTableData();
    // eslint-disable-next-line
  }, [modalGotov]);

  return (
    <RenFrameFunction.Provider value={FetchTableData}>
      <h1>Данные о сборке заказа</h1>
      <AddValume modalGotov={modalGotov} workerName={workerName} />
      <TableFrame workerFrame={workerFrame} modalGotov={modalGotov} />
    </RenFrameFunction.Provider>
  );
};

export default FrameMaste;
