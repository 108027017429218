import {
  LOADER_ORDER,
  ADD_ORDER,
  FETCH_ORDER,
  REMOVE_ORDER,
  MINUS_CASH_ORDER,
} from '../../typs/orderTypes';

const handlers = {
  [LOADER_ORDER]: (state) => ({ ...state, loading: true }),
  [FETCH_ORDER]: (state, { response }) => ({
    ...state,
    order: response,
    loading: false,
  }),
  [REMOVE_ORDER]: (state, { key }) => ({
    ...state,
    order: state.order.filter((order) => order.key !== key),
  }),
  [ADD_ORDER]: (state) => ({
    ...state,
    order: [...state.order],
  }),
  [MINUS_CASH_ORDER]: (state) => ({
    ...state,
    order: [...state.order],
  }),
  DEFAULT: (state) => state,
};
export const OrderReduser = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  //   console.log(state, action);
  return handle(state, action);
};
