import React from "react";
import ModalCenter from "../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import { Row, Col, Form, DatePicker, Input, TimePicker } from "antd";
import {
  Title,
  Hr,
  Span,
  CardButton,
} from "../../../Component/Theme/CSSstyles";
import styled from "./Accouting.module.css";
import { FieldTimeOutlined } from "@ant-design/icons";
import axios from "axios";
// import "moment/locale/ru_RU";
import locale from "antd/es/date-picker/locale/ru_RU";
import swal from "sweetalert";

const url = process.env.REACT_APP_DB_URL;

const WorkAccounting = ({
  id,
  fp_id,
  username,
  telegram_id,
  FetchWorkData,
}) => {
  const [form] = Form.useForm();
  const configs = {
    rules: [
      {
        type: "object",
        required: true,
        message: "введите данные!",
      },
    ],
  };

  const AccountingAdd = (value) => {
    value = {
      ...value,
      authDate: value["authDate"].format("YYYY-MM-DD"),
      authTime: value["authTime"].format("HH:mm:ss"),
    };
    value.authDateTime = `${value["authDate"] + " " + value["authTime"]}`;
    value.token = localStorage.getItem("DataToken");

    const config = {
      method: "post",
      url: `${url}/add-davomat-day.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: JSON.stringify(value),
    };
    //  console.log(data);

    swal({
      title: "Внимание!",
      text: `Проверьте данные! Число:  ${value.authDate ?? null} Время: ${
        value.authTime ?? null
      }`,
      icon: "warning",
      buttons: {
        cancel: "Нет!",
        catch: {
          text: "Да",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: "Успешно",
                text: `${res.data.xabar}`,
                icon: "success",
              });
            }
            // console.log(res);
            FetchWorkData();
          })
          .catch(function (error) {
            console.log("accaunting", error);
            swal({
              title: "Oops",
              text: `Какаято ошибка ${error} \xa0 обратитесь к разработчику`,
              icon: "warning",
            });
          });
      }
    });

    form.resetFields();
  };

  return (
    <ModalCenter
      name={<FieldTimeOutlined title="Отметить время прихода и ухода" />}
    >
      <Title>Ежедневный учёт</Title>
      <Hr />
      <Form name="global" form={form} onFinish={AccountingAdd}>
        <Row key={id} className={styled.AccoutingUserData}>
          <Form.Item name="employeeID" initialValue={fp_id}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="worker_id" initialValue={id}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="telegram_id" initialValue={telegram_id}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="personName" initialValue={username}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="cardNo" initialValue={""}>
            <Input type="hidden" />
          </Form.Item>
          <Col span={4}>
            <div>
              <Span style={{ marginTop: 10 }}>{username}</Span>
            </div>
          </Col>
          <Col span={14} offset={4}>
            <Form.Item name="authDate" placeholder="Дата" {...configs}>
              <DatePicker style={{ width: 200 }} locale={locale} />
            </Form.Item>
            <Form.Item name="authTime" {...configs}>
              <TimePicker style={{ width: 200 }} placeholder="Время" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <CardButton htmlType="submit" className={styled.BtnAddAccounting}>
            Выполнить
          </CardButton>
        </Form.Item>
      </Form>
    </ModalCenter>
  );
};

export default WorkAccounting;
