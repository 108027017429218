import React from "react";
import Workers from "../PageComponent/Workers/Workers";
import { Title } from "../Component/Theme/CSSstyles";
const WorkerInfo = () => {
  return (
    <div>
      <Title>Данные персоналов</Title>
      <Workers />
    </div>
  );
};

export default WorkerInfo;
