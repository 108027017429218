import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import swal from "sweetalert";

const url = process.env.REACT_APP_DB_URL;

export const Authorization = () => {
  const signIn = useSignIn();
  const history = useNavigate();

  const handleLogIn = (e) => {
    e.preventDefault();
    const form = e.target;
    const login = form.login.value;
    const parol = form.parol.value;

    const config = {
      method: "post",
      url: `${url}/login.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: JSON.stringify({
        login,
        parol,
      }),
    };
    axios(config)
      .then(function (response) {
        if (response.data.error === 0) {
          localStorage.setItem("DataToken", response.data.token);
          localStorage.setItem("DataID", response.data.id);
          localStorage.setItem("DataName", response.data.fio);
          if (
            signIn({
              token: response.data.token,
              authState: {
                id: response.data.id,
                fio: response.data.fio,
                token: response.data.token,
                rol: response.data.rol,
              },
              expiresIn: response.data.tokentime_string,
              refreshToken: response.data.token,
              refreshTokenExpireIn: response.data.tokentime_string,
            })
          ) {
            history("/");
            swal({
              title: `Добро пожаловать  ${response.data.fio}`,
              icon: "success",
              button: "Спасибо",
            });
          }
        } else {
          swal({
            title: "Авторизуйтесь или проверте логин и пароль!!!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        swal({
          title: "Ошибка!!!",
          text: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  return (
    <main className="loginForm">
      <form onSubmit={handleLogIn} name="autorization">
        <h2>Авторизация</h2>
        <div>
          <input
            className="loginFormInput"
            type="text"
            placeholder="Логин"
            name="login"
          />
        </div>
        <div>
          <input
            className="loginFormInput"
            type="password"
            placeholder="Пароль"
            name="parol"
          />
        </div>
        <div>
          <button className="Btn" type="submit">
            Войти
          </button>
        </div>
      </form>
    </main>
  );
};

// eslint-disable-next-line
