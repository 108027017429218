import React from "react";
import { Route, Routes } from "react-router-dom";
import RecuiredAuth from "../Component/RecuiredAuth/RecuiredAuth";
import PrivareWindow from "../page/dinamikTable/PrivareWindow";
import IndexPage from "../page/IndexPage";
import ClientNew from "../page/ClientNew";
import ClientSingle from "../page/ClientSingle";
import PrivateKeyEntry from "../page/dinamikTable/PrivateKeyEntry";
import ClientRusset from "../page/ClientRusset";
import BuyRasxod from "../page/BuyRasxod";
import UsersArxiv from "../page/UsersArxiv";
import UsersUchot from "../page/UsersUchot";
import WorkerInfo from "../page/WorkerInfo";
import ContragentAll from "../PageComponent/Kontragent/ContragentAll/ContragentAll";
import PageContragent from "../page/PageContragent";
import Error404 from "../Component/404/Error404";
import Setting from "../page/Setting";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route index element={<IndexPage />} />
        <Route path="client" element={<ClientNew />} />
        <Route path="client/:key" element={<ClientSingle />} />
        <Route
          path="/private"
          element={
            <RecuiredAuth>
              <PrivareWindow />
            </RecuiredAuth>
          }
        />
        <Route path="caver" element={<PrivateKeyEntry />} />
        <Route path="russetclients" element={<ClientRusset />} />
        <Route path="/:key" element={<PageContragent />} />
        <Route path="expenses" element={<BuyRasxod />} />
        <Route path="arxiv" element={<UsersArxiv />} />
        <Route path="uchot" element={<UsersUchot />} />
        <Route path="workinfo" element={<WorkerInfo />} />
        <Route path="contragenti" element={<ContragentAll />} />
        <Route path="contragenti/:pageId" element={<PageContragent />} />
        <Route
          path="loginChange"
          element={
            <RecuiredAuth>
              <Setting />
            </RecuiredAuth>
          }
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default Routing;
