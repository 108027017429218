import React from "react";
import { func, string } from "prop-types";
import styled from "styled-components";
import { WiSolarEclipse } from "react-icons/wi";

const Button = styled.button`
  color: ${({ theme }) => theme.text};
  padding: 0;
  margin: 10px 5px;
  background: ${({ theme }) => theme.background2};
  border: 2px solid;
  border-color: ${({ theme }) => theme.background};
  border-radius: 5px;
  font-size: 1.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
const Toggle = ({ theme, toggleTheme }) => {
  return (
    <Button onClick={toggleTheme}>
      <WiSolarEclipse style={{ padding: 0 }} />
    </Button>
  );
};
Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};
export default Toggle;
