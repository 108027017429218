import React, { useEffect, useState, useContext } from 'react';
import css from './Workers.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import PayCash from './PayCash/PayCash';
import EditWorking from './EditWorking/EditWorking';
import EyesWork from './EyesWork/EyesWork';
import { Span, TableTh } from '../../Component/Theme/CSSstyles';
import AddUser from './AddUser/AddUser';
import WorkPrices from './WorkPrices/WorkPrices';
import WorkAccounting from './WorkAccounting/WorkAccounting';
import Loding from '../Loding';
import Prize from './WorkPrize/Prize';
import WorkDeduction from './WorkPrize/WorkDeduction';

import WorkIntervalModal from './WorkInterval/WorkIntervalModal';
import { numberFormat } from '../../tools/numberFormat';
import { PersonalContext, WorkingRender } from 'Context/context';

const Workers = () => {
  const UserId = localStorage.getItem('DataID');
  const { FetchWorkData, workData, FetchDelete } = useContext(PersonalContext);

  // получаем данные из PayCash для rendera EyesWork
  const [renderEyes, setRenderEyes] = useState([]);

  // получаем данные из компонента EditPrice
  const [priceData, setPriseData] = useState([]);

  useEffect(() => {
    FetchWorkData();
    // eslint-disable-next-line
  }, []);

  function sorts(a, b) {
    if (a.lavozim_name > b.lavozim_name) {
      return 1;
    }
    if (a.lavozim_name < b.lavozim_name) {
      return -1;
    }
    if (a.lavozim_name === b.lavozim_name) {
      return 0;
    }
  }

  //   выводим в таблицу данные из запроса
  if (workData.length === 0) {
    return (
      <div className={css.LodingBlock}>
        <Loding />
      </div>
    );
  }
  //   console.log(">>>", workData);
  // eslint-disable-next-line
  const infoState = workData
    .slice()
    .sort(sorts)
    .map((i) => {
      return (
        <tr className={css.WorkerTableTd} key={i.id}>
          <td>
            <Span>{i.id}</Span>
          </td>
          <td>
            <Span>{i.date}</Span>
          </td>
          <td>
            <Span>{i.username}</Span>
          </td>
          <td className={css.WorkerTableTdata}>
            <Span>{i.telefon}</Span>
          </td>
          <td className={css.WorkerTableTdata}>
            <Span>{i.lavozim_name}</Span>
          </td>

          <td className={css.WorkerTableTdata}>
            <Span>{i.birlik}</Span>
          </td>
          {/* <td className={css.WorkerTableTdata}>
            <Span>{priceTo(i.maosh)}</Span>
          </td>
          
          <td className={css.WorkerTableTdata}>
            <Span>{priceTo(i.norma)}</Span>
          </td> */}
          <td className={css.WorkerTableTdata}>
            <Span>{numberFormat(i.maosh)}</Span>
          </td>
          <td className={css.WorkerTableTdata}>
            <Span>{numberFormat(Math.ceil(i.fee))}</Span>
          </td>
          <td className={css.WorkerTableTdata}>
            <Span>{Math.ceil(i.bajarilgan_norma)}</Span>
          </td>
          <td className={css.WorkerTableTdata}>
            <Span>{numberFormat(i.balans)}сум</Span>
          </td>
          {/* <td className={css.WorkerTableTdata}>
					 <Span>{priceTo(i.zadolrabotadatel)}</Span>
				  </td>
				  <td className={css.WorkerTableTdata}>
					 <Span>{priceTo(i.zadolrabochego)}</Span>
				  </td> */}
          <td className={css.WorkerTableTButton}>
            <EditWorking
              id={i.id}
              name={i.username}
              tel={i.telefon}
              bot_id={i.telegram_id}
              ishturi_id={i.ishturi_id}
              fp_id={i.fp_id}
              priceData={priceData}
              maosh={i.maosh}
              norma={i.norma}
            />
            {''}
            {UserId === '1' ? (
              <PayCash
                id={i.id}
                name={i.username}
                status={i.status}
                pay={i.payment}
                vidoplati={i.vidoplati}
                norm={i.normativ}
                vidopla={i.ishturi}
                balans={i.balans}
                zadolrabotadatel={i.zadolrabotadatel}
                zadolrabochego={i.zadolrabochego}
                setRenderEyes={setRenderEyes}
              />
            ) : null}

            {''}
            <EyesWork
              id={i.id}
              date={i.date}
              tel={i.telefon}
              lavozim_name={i.lavozim_name}
              balans={i.balans}
              username={i.username}
              norma={i.norma}
              fee={i.fee}
              vidoplati={i.ishturi}
              bajarilgan_norma={i.bajarilgan_norma}
              telegram_id={i.telegram_id}
              birlik={i.birlik}
              renderEyes={renderEyes}
            />
            <WorkAccounting
              id={i.id}
              date={i.date}
              tel={i.telefon}
              lavozim_name={i.lavozim_name}
              balans={i.balans}
              username={i.username}
              norma={i.norma}
              fee={i.fee}
              vidoplati={i.ishturi}
              bajarilgan_norma={i.bajarilgan_norma}
              zadolrabotadatel={i.zadolrabotadatel}
              zadolrabochego={i.zadolrabochego}
              telegram_id={i.telegram_id}
              birlik={i.birlik}
              fp_id={i.fp_id}
              FetchWorkData={FetchWorkData}
            />
            {''}
            {UserId === '1' ? (
              <button
                type='button'
                className={css.Workerbutton}
                onClick={() => FetchDelete(i.id)}
                title='Удалить'
              >
                <DeleteOutlined />
              </button>
            ) : null}
          </td>
        </tr>
      );
    });

  return (
    <WorkingRender.Provider value={FetchWorkData}>
      <div className={css.WorkerModalBlock}>
        <AddUser />
        <WorkPrices setPriseData={setPriseData} />
        {UserId === '1' ? (
          <>
            {' '}
            <Prize workData={workData} />
            <WorkDeduction workData={workData} />
          </>
        ) : null}
        <WorkIntervalModal workData={workData} />
      </div>
      <div className={css.WorkerTableBlock}>
        <table className={css.WorkerTable}>
          <thead>
            <tr className={css.WorkerTableTh}>
              <TableTh>ID</TableTh>
              <TableTh>
                Дата приёма <br /> на работу
              </TableTh>
              <TableTh>Ф.И.О</TableTh>
              <TableTh>Номер тел.</TableTh>
              <TableTh>Статус</TableTh>
              <TableTh>
                Вид оплаты <br /> труда
              </TableTh>
              <TableTh>Ежемесячная вставка</TableTh>
              <TableTh>Цена</TableTh>
              {/* <TableTh>Ежемесячная вставка</TableTh>
              <TableTh>Цена</TableTh>
              <TableTh>Норматив</TableTh> */}
              <TableTh>Отработана</TableTh>
              <TableTh>Баланс</TableTh>
              {/* <TableTh>
                Задолженность <br /> работадателя
              </TableTh>
              <TableTh>
                Задолженность <br /> рабочего
              </TableTh> */}
              <TableTh style={{ width: 150 }}>Действия</TableTh>
            </tr>
          </thead>
          <tbody>{infoState}</tbody>
        </table>
      </div>
    </WorkingRender.Provider>
  );
};

export default Workers;

// id,
//   date,
//   tel,
//   lavozim_name,
//   balans,
//   username,
//   norma,
//   fee,
//   vidoplati,
//   bajarilgan_norma,
//   zadolrabotadatel,
//   zadolrabochego,
//   telegram_id,
//   birlik
