import React from "react";
import CSS from "../PageComponent.module.css";
import { Span, Main, Paragraph } from "../../Component/Theme/CSSstyles";

export default function ClientTableHeader({ filtered }) {
  let totalCash = 0;
  let totalRaschot = 0;
  let totalOstatok = 0;
  let totalYuza = 0;
  filtered.forEach(({ cash, raschot, ostatok, yuza }) => {
    totalCash += Number(cash);
    totalRaschot += Number(raschot);
    totalOstatok += Number(ostatok);
    totalYuza += Number(yuza);
    //   dataState.map(
    //     (f) => (f.ostatok = Number(cash) - Number(raschot))
    //   );
  });
  return (
    <>
      {/* {hoverColor === false ? (
        <EyeInvisibleOutlined
          onClick={() => setHoverColor(true)}
          style={{ fontSize: 20, color: "red" }}
        />
      ) : (
        <EyeOutlined
          onClick={() => setHoverColor(false)}
          style={{ fontSize: 20, color: "#1890ff" }}
        />
      )} */}

      {/* <div className={hoverColor ? "hover__color active" : "hover__color"}> */}
      <div>
        <Main>
          <Paragraph className={CSS.CashInfo}>
            Общий объем:
            <Span className={CSS.CashInfoSpan}>
              {totalYuza.toLocaleString()}
            </Span>
            m
            <sup>
              <small>2</small>
            </sup>
          </Paragraph>
          <Paragraph className={CSS.CashInfo}>
            Сумма заказов:
            <Span className={CSS.CashInfoSpan}>
              {totalCash.toLocaleString()}
            </Span>
          </Paragraph>
          <Paragraph className={CSS.CashInfo}>
            Сумма авансов:
            <Span className={CSS.CashInfoSpan}>
              {totalRaschot.toLocaleString()}
            </Span>
          </Paragraph>
          <Paragraph className={CSS.CashInfo}>
            Сумма остатков:
            <Span className={CSS.CashInfoSpan}>
              {totalOstatok.toLocaleString()}
            </Span>
          </Paragraph>
        </Main>
      </div>
    </>
  );
}
