import React, { useRef } from "react";
import Loding from "../../Loding";
import { MiniTitle, TableTh } from "../../../Component/Theme/CSSstyles";
import css from "../WorkersArchive.module.css";
import ReactToPrint from "react-to-print";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const WorkersIntervalTable = ({ interval }) => {
  const componentRef = useRef();

  if (!interval) {
    return <Loding />;
  }

  const DataInterval = interval.map((i) => (
    <tr key={i.id} className={css.WorkerArchiveInterval}>
      <td className={css.WorkerArchiveData}>{i.date}</td>
      <td className={css.WorkerArchiveData}>{i.fio}</td>
      <td className={css.WorkerArchiveData} style={{ textAlign: "left" }}>
        {i.comment}
      </td>
      <td className={css.WorkerArchiveData}>{priceTo(i.cash)}</td>
    </tr>
  ));
  let TotalInterval = 0;
  interval.forEach(({ cash }) => {
    TotalInterval += Number(cash);
  });
  return (
    <div>
      <div className={css.IntervalArxivHeader}>
        <ReactToPrint
          trigger={() => (
            <button className="exit__modal-card-btn">Печатать</button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef}>
        <div>
          <MiniTitle>Выдана авансов на {priceTo(TotalInterval)} </MiniTitle>сум.
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <TableTh style={{ width: "10%" }}>Дата</TableTh>
              <TableTh style={{ width: "20%" }}>Имя</TableTh>
              <TableTh style={{ width: "50%" }}>Заметки</TableTh>
              <TableTh style={{ width: "20%" }}>
                Выданные <br /> суммы
              </TableTh>
            </tr>
          </thead>
          <tbody>{DataInterval}</tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkersIntervalTable;
