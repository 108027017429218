import React, { useEffect, useState } from "react";
import ModalCenter from "../../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import {
  Title,
  TableTd,
  TableTh,
  Hr,
} from "../../../../Component/Theme/CSSstyles";
import axios from "axios";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const AddInfoDataTable = ({ tableId }) => {
  // запрос для получени истории изменения
  const [gethistory, setHistory] = useState([]);
  const FetchHistory = () => {
    let token = localStorage.getItem("DataToken");
    var data = JSON.stringify({
      token: token,
      table_id: tableId,
    });
    var config = {
      method: "post",
      url: `${url}/get-update-table-history.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setHistory(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchHistory();
    // eslint-disable-next-line
  }, [gethistory]);

  // вывод измененных данных
  if (!gethistory.rows) {
    return "Загрузка...";
  }
  const FetchData = gethistory.rows.map((j) => (
    <tr key={j.id}>
      <TableTd>{j.sana}</TableTd>
      <TableTd>{priceTo(j.new_cash)}</TableTd>
      <TableTd>{j.note}</TableTd>
      <TableTd>
        {j.switch === "1" ? "сумма добавлена" : "сумма минусована"}
      </TableTd>
    </tr>
  ));
  return (
    <ModalCenter name="Новые данные таблицы">
      <Title>Новые данные таблицы</Title>
      <Hr />
      <table style={{ width: "100%", marginBottom: 15 }}>
        <thead>
          <tr>
            <TableTh style={{ width: 150 }}> Измененный число</TableTh>
            <TableTh style={{ width: 200 }}>Сумма</TableTh>
            <TableTh>Заметка</TableTh>
            <TableTh style={{ width: 200 }}>Операции</TableTh>
          </tr>
        </thead>
        <tbody>{FetchData}</tbody>
      </table>
    </ModalCenter>
  );
};

export default AddInfoDataTable;
