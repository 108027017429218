import React, { useState } from "react";
import ModalTop from "../../../Component/ModalComponent/ModalTop/ModalTop";
import WorkersIntervalTable from "./WorkersIntervalTable";
import WorkersIntervslForm from "./WorkersIntervslForm";
import { Title } from "../../../Component/Theme/CSSstyles";
import css from "../WorkersArchive.module.css";
const WorkersIntervalModal = () => {
  const [interval, setInterval] = useState([]);
  return (
    <ModalTop name="Интерваль авансов">
      <div className={css.WorkersIntervalArxiv}>
        <Title>Интерваль авансов</Title>
        <WorkersIntervslForm setInterval={setInterval} />
      </div>
      <WorkersIntervalTable interval={interval} />
    </ModalTop>
  );
};

export default WorkersIntervalModal;
