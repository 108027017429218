export const MonthList = [
  {
    tr: 1,
    name: "Январь",
    days: 31,
  },
  {
    tr: 2,
    name: "Февраль",
    days: 31,
  },
  {
    tr: 3,
    name: "Март",
    days: 31,
  },
  {
    tr: 4,
    name: "Апрель",
    days: 31,
  },
  {
    tr: 5,
    name: "Май",
    days: 31,
  },
  {
    tr: 6,
    name: "Июнь",
    days: 31,
  },
  {
    tr: 7,
    name: "Июль",
    days: 31,
  },
  {
    tr: 8,
    name: "Август",
    days: 31,
  },
  {
    tr: 9,
    name: "Сентябрь",
    days: 31,
  },
  {
    tr: 10,
    name: "Октябрь",
    days: 31,
  },
  {
    tr: 11,
    name: "Ноябрь",
    days: 31,
  },
  {
    tr: 12,
    name: "Декабрь",
    days: 31,
  },
];
