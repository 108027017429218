import React, { useRef, useContext } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import style from '../PayCash.module.css';
import { WorkingRender } from 'Context/context';
import { config } from 'config/configs';
import { CardButton } from 'Component/Theme/CSSstyles';

const CashAddForm = ({ id, balans, name, setInfoCash, setRenderEyes }) => {
  const FetchWorkData = useContext(WorkingRender);
  const CommRef = useRef();
  const CashRef = useRef();

  const FatchCash = () => {
    if (CashRef.current.state.numAsString === '0') {
      swal({
        title: 'Бози накун!!!!!',
        text: `Пули дойсудагета навес ${CashRef.current.state.value}  дароварси!`,
        icon: 'error',
        buttons: 'Ok',
      });
      return null;
    }
    swal({
      title: 'Внимание!',
      text: `Вы уверены о выдаче  ${CashRef.current.state.value} сум?!`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/add-salary-avans.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify({
            token: config.TOKEN,
            id: id,
            fio: name,
            comment: CommRef.current.value,
            cash: CashRef.current.state.numAsString,
          }),
        })
          .then(function (response) {
            if (response.status === 200) {
              FetchWorkData();
              setInfoCash(response.data);
              setRenderEyes(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  return (
    <div>
      <div className={style.CashBlockForm}>
        <input
          type='text'
          placeholder='Поле для Заметки'
          className={style.PayCashInp}
          ref={CommRef}
        />
        <NumberFormat
          thousandSeparator={true}
          placeholder={balans}
          defaultValue={balans}
          className={style.PayCashInp}
          ref={CashRef}
        />
        <div className={style.CashButton}>
          <CardButton
            type='submit'
            onClick={FatchCash}
            className={style.StyleBtnAdd}
          >
            Выполнить
          </CardButton>
        </div>
      </div>
    </div>
  );
};

export default CashAddForm;
