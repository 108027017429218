import React, { useRef, useEffect, useState } from "react";
import ModalCenter from "../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import NumberFormat from "react-number-format";
import {
  Title,
  Hr,
  Spans,
  CardButton,
} from "../../../Component/Theme/CSSstyles";
import css from "./Contragent.module.css";
import swal from "sweetalert";
import axios from "axios";
import ContragentHooks from "../../../Context/Contragent/ContragetHooks";

const url = process.env.REACT_APP_DB_URL;

const AddNewContragent = () => {
  const [tipContragent, setTipContragent] = useState([]);

  const { getContragent, setContragent } = ContragentHooks();
  const token = localStorage.getItem("DataToken");
  const NameRef = useRef();
  const NamberRef = useRef();
  const AdresRef = useRef();
  const TypeRef = useRef();

  const FetchContragent = () => {
    const data = JSON.stringify({
      token: token,
      name: NameRef.current.value,
      telefon: NamberRef.current.state.value,
      izoh: AdresRef.current.value,
      tashkilot_tur: TypeRef.current.value,
    });
    if (!NameRef.current.value) {
      swal({
        title: "Введите название Фирмы или имя контрагента",
        icon: "error",
        buttons: "ok",
      });
      return null;
    }
    if (TypeRef.current.value === "disabled") {
      swal({
        title: "Выберите тип контрагента",
        icon: "error",
        buttons: "ok",
      });
      return null;
    }
    const config = {
      method: "POST",
      url: `${url}/add-tashkilot.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    swal({
      title: "Внимание!",
      text: `проверьте данные  ${"Название:" + NameRef.current.value},  ${
        "Номер:" + NamberRef.current.state.value
      }, ${"Адрес:" + AdresRef.current.value}!`,
      icon: "warning",
      buttons: {
        cancel: "Нет!",
        catch: {
          text: "Да",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (res) {
            if (res.status === 200) {
              console.log("new add", res);
              swal({
                title: "Отлично!!!",
                text: `Новый контрагент добавлен`,
                icon: "success",
                buttons: "OK",
              });
              setContragent(res.data);
              getContragent();
            }
          })
          .catch(function (error) {
            swal({
              title: "Ошибка",
              text: `${error}`,
              icon: "error",
              buttons: "OK",
            });
          });
      }
    });
  };

  const TypeContragent = () => {
    const data = JSON.stringify({
      token: token,
    });
    const config = {
      method: "POST",
      url: `${url}/get-tashkilot-tur.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then(function (res) {
        if (res.status === 200) {
          setTipContragent(res.data);
        }
      })
      .catch(function (error) {
        swal({
          title: "Ошибка",
          text: `${error}`,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  useEffect(() => {
    TypeContragent();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalCenter name="Добавить нового Контрагента">
      <Title>Добавить нового контрагента</Title>
      <Hr />
      <div className={css.BlockAddNewContr}>
        <div>
          <Spans>Название фирмы или контрагента</Spans>
          <select
            ref={TypeRef}
            defaultValue="disabled"
            className={css.NewNameCotragent}
          >
            <option value="disabled">Виберите тип контрагента</option>
            {!tipContragent
              ? null
              : tipContragent.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
          </select>
        </div>
        <div>
          <Spans>Название фирмы или контрагента</Spans>
          <input
            type="name"
            className={css.NewNameCotragent}
            min={3}
            ref={NameRef}
          />
        </div>
        <div>
          <Spans>Адрес фирмы</Spans>
          <input
            type="izoh"
            className={css.NewNameCotragent}
            min={3}
            ref={AdresRef}
          />
        </div>
        <div>
          <Spans>Контактный номер фирмы</Spans>
          <NumberFormat
            format="(##) ###-##-##"
            mask="_"
            placeholder="(99) 999-99-99"
            className={css.NewNameCotragent}
            ref={NamberRef}
          />
        </div>
        <div className={css.BtnBlock}>
          <CardButton className={css.NewNameBtn} onClick={FetchContragent}>
            Добавить
          </CardButton>
        </div>
      </div>
    </ModalCenter>
  );
};

export default AddNewContragent;
