import React, { useRef, useState, useEffect } from "react";
import { EyeOutlined } from "@ant-design/icons";
import ModalFul from "../../../Component/ModalComponent/ModalFul/ModalFul";
import {
  Title,
  TableTh,
  TableTd,
  Hr,
  CardTitle,
} from "../../../Component/Theme/CSSstyles";
import ReactToPrint from "react-to-print";
import css from "./EyesWork.module.css";
import axios from "axios";
const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const EyesWork = ({
  id,
  date,
  tel,
  lavozim_name,
  balans,
  username,
  norma,
  fee,
  vidoplati,
  bajarilgan_norma,
  zadolrabotadatel,
  zadolrabochego,
  telegram_id,
  birlik,
  renderEyes,
}) => {
  const componentRef = useRef();
  // Получаем данные из EyesTable
  const [eyesInfo, setEyesInfo] = useState(renderEyes);
  const token = localStorage.getItem("DataToken");

  // получаем данные аванса
  const FetchAvans = () => {
    const data = JSON.stringify({
      worker_id: id,
      token: token,
    });

    const config = {
      method: "post",
      url: `${url}/get-salary-payments.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setEyesInfo(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchAvans();
    // eslint-disable-next-line
  }, [id, renderEyes]);

  const InfoUser = (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <TableTh style={{ padding: 15 }}>№</TableTh>
          <TableTh style={{ padding: 15 }}>Дата</TableTh>
          <TableTh style={{ padding: 15 }}>Ф.И.О</TableTh>
          <TableTh style={{ padding: 15 }}>Номер(тел)</TableTh>
          <TableTh style={{ padding: 15 }}>Статус</TableTh>
          <TableTh style={{ padding: 15 }}>
            Вид <br /> оплаты
          </TableTh>
          <TableTh style={{ padding: 15 }}>Цена</TableTh>
          <TableTh style={{ padding: 15 }}>Норматив</TableTh>
          <TableTh style={{ padding: 15 }}>Выполнена</TableTh>
          <TableTh style={{ padding: 15 }}>Баланс</TableTh>
          <TableTh style={{ padding: 15 }}>
            Задолженность <br /> работадателя
          </TableTh>
          <TableTh style={{ padding: 15 }}>
            Задолженность <br /> рабочего
          </TableTh>
          <TableTh style={{ padding: 15 }}>Бот ID</TableTh>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TableTd style={{ padding: 10 }}>{id}</TableTd>
          <TableTd style={{ padding: 10 }}>{date}</TableTd>
          <TableTd style={{ padding: 10 }}>{username}</TableTd>
          <TableTd style={{ padding: 10 }}>{tel}</TableTd>
          <TableTd style={{ padding: 10 }}>{lavozim_name}</TableTd>
          <TableTd style={{ padding: 10 }}>{birlik}</TableTd>
          <TableTd style={{ padding: 10 }}>{fee} сум</TableTd>
          <TableTd style={{ padding: 10 }}>{norma}</TableTd>
          <TableTd style={{ padding: 10 }}>{bajarilgan_norma}</TableTd>
          <TableTd style={{ padding: 10 }}>{priceTo(balans)}</TableTd>
          <TableTd style={{ padding: 10 }}>{zadolrabotadatel}</TableTd>
          <TableTd style={{ padding: 10 }}>{zadolrabochego}</TableTd>
          <TableTd style={{ padding: 10 }}>{telegram_id}</TableTd>
          {/* <TableTd>
            {" "}
            <CardButton onClick={() => Remove()}>&times;</CardButton>
          </TableTd> */}
        </tr>
      </tbody>
    </table>
  );
  //   console.log("eyesInfo", eyesInfo);
  //   if (eyesInfo.length === 0) {
  //     return "Загрузка...";
  //   }

  const DataAvans = eyesInfo.map((i) =>
    id === i.worker_id ? (
      <tr key={i.id}>
        <TableTd>{i.date}</TableTd>
        <TableTd style={{ textAlign: "left" }}>{i.comment}</TableTd>
        <TableTd>{priceTo(i.cash)}</TableTd>
      </tr>
    ) : null
  );

  return (
    <ModalFul name={<EyeOutlined title="Подробный просмотр" />}>
      <Title>Просмотр данных</Title>
      <Hr />
      <div className={css.BlockBtnPrint}>
        <ReactToPrint
          trigger={() => (
            <button className="exit__modal-card-btn">Печатать</button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef}>
        <div>{InfoUser}</div>
        <div className={css.BlockInfoUser}>
          <CardTitle>Авансы и зарплаты</CardTitle>
          <table style={{ width: "100%", marginTop: 20 }}>
            <thead>
              <tr>
                <TableTh style={{ width: "150px" }}>Дата</TableTh>
                <TableTh>Заметки</TableTh>
                <TableTh style={{ width: "200px" }}>
                  Выданные <br /> суммы
                </TableTh>
              </tr>
            </thead>
            <tbody>{DataAvans}</tbody>
          </table>
        </div>
      </div>
    </ModalFul>
  );
};

export default EyesWork;
