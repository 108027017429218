import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "antd";
import { ClientOneTable } from "./ClientOneTable";
import "antd/dist/antd.min.css";
export default function ClientPrint({ activeRecord }) {
  const componentRef = useRef();
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button type="danger" style={{ padding: 0, width: "10%" }}>
            Печатать
          </Button>
        )}
        content={() => componentRef.current}
      />
      <ClientOneTable ref={componentRef} active={activeRecord} />
    </div>
  );
}
