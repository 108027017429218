import React, { useContext } from 'react';
import css from './WorkersData.module.css';
import { Tooltip, Tag } from 'antd';
import { nanoid } from 'nanoid';
import { MonthContext } from 'Context/context';
import { Span, Spans, TRow, TableTh, Title } from 'Component/Theme/CSSstyles';

const WorkersTable = ({ workerInfo }) => {
  const StoreMonth = useContext(MonthContext);
  //  массив для название месяцев
  let ArrayMonth = [];
  // функцыя для сравнения номера месяцев
  const InfoMonth = (month) => {
    const MonthNumber = Number(month);
    // eslint-disable-next-line
    StoreMonth.map((o) => {
      if (o.tr === Number(MonthNumber) && !null) {
        return ArrayMonth.push(o.name);
      }
    });
  };
  // получаем номер месяца из базы и передаём в функцыю
  workerInfo.data?.map((h) => InfoMonth(h.oy));
  let uniArrayMonth = [...new Set(ArrayMonth)];
  // выводим в таблицу
  const NameMonth = uniArrayMonth.map((g) => g);

  if (workerInfo.length === 0) {
    return 'Загрузка...';
  }
  // массив для чисел месяца
  const result = [];

  // выводим даты месяца
  const getDaysArray = (year, month) => {
    const monthIndex = month - 1;
    const names = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'су'];
    const date = new Date(year, monthIndex, 1);
    while (date.getMonth() === monthIndex) {
      result.push(date.getDate() + '-' + names[date.getDay()]);
      date.setDate(date.getDate() + 1);
    }
    return result;
  };

  // передаём в функцыю год и месяц
  workerInfo.data.map((i) => getDaysArray(i.yil, i.oy));
  // объединяем одинаковые данные массива
  let uniАrr = [...new Set(result)];

  // выводит header таблицы

  const TestUnique = uniАrr.map((f) => {
    return (
      <TableTh className={css.WorkerTableBlockTh} key={nanoid()}>
        <Span className={css.WorkerTableBlockSpans}>{f}</Span>
      </TableTh>
    );
  });
  const styleShrift = {
    fontSize: '20px',
    color: '#73d13d',
  };
  const styleShriftN = {
    fontSize: '20px',
    color: '#00af4d',
  };
  //   console.log(workerInfo.data);
  const DataTableRow = workerInfo.data.map((s, index) => {
    //  console.log(uniАrr.length);
    //  console.log("object", Object.entries(s.data).slice(0, 31).length);
    //  let NewDateLength = Object.entries(s.data).slice(0, 31);
    //  if (uniАrr.length === 30) {
    //    console.log(NewDateLength.slice(1, 31));
    //  }
    return (
      <TRow key={s.data.id}>
        <td className={css.WorketDayRow}>{s.username}</td>
        {Object.entries(s.data).map(([key, value]) => (
          <td key={nanoid()} className={css.WorketDayRow}>
            {key !== 'worker_id' &&
              key !== 'id' &&
              key !== 'status' &&
              key !== 'user_id' &&
              parseInt(key.slice(1, 3)) <= uniАrr.length && (
                <Tooltip
                  key={nanoid()}
                  title={
                    <>
                      <span>
                        Пришол: {'\xa0\xa0' + JSON.parse(value)?.vaqt}
                      </span>
                      <br />
                      <span>
                        Ушол: {'\xa0\xa0' + JSON.parse(value)?.vaqt2 ?? 0}
                      </span>
                    </>
                  }
                  color={'volcano'}
                  placement='top'
                >
                  {value !== null ? (
                    <Tag color='#0b6e00' style={styleShrift}>
                      П
                    </Tag>
                  ) : (
                    <Tag color='cyan' style={styleShriftN}>
                      0
                    </Tag>
                  )}
                </Tooltip>
              )}
          </td>
        ))}
      </TRow>
    );
  });

  return (
    <div>
      <Title>
        <Spans>месяц:</Spans>
        {NameMonth}
        {/* <Spans>число:</Spans>
        {new Date().getDate()}
        <Spans>время:</Spans>
        {new Date().toLocaleTimeString()} */}
      </Title>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <TableTh style={{ width: '400px' }}>Имя</TableTh>
            <TableTh></TableTh>
            {TestUnique}
          </tr>
        </thead>
        <tbody>{DataTableRow}</tbody>
      </table>
    </div>
  );
};

export default WorkersTable;
