import React, { useState } from "react";
import DataTable from "./CardTable/DataTable";
import { CardButton, CardTitle } from "../../../Component/Theme/CSSstyles";
import "./CardMadal.css";
import { Sections } from "../../../Component/Theme/CSSstyles";
import { FolderOpenOutlined } from "@ant-design/icons";
// import { Divider } from "antd";
import FormCommTable from "../AddCommTable/FormCommTable";

const CardModal = ({ debt, title, tableId, addNew }) => {
  const [visible, setVisible] = useState(false);
  const [fetchTable, setFetchTable] = useState([]);

  return (
    <>
      <CardButton className="name-table-btn" onClick={() => setVisible(true)}>
        <CardTitle>
          <FolderOpenOutlined /> Открыть таблицу
        </CardTitle>
      </CardButton>
      <section
        className={visible ? "block-name-table active" : "block-name-table"}
        onClick={() => setVisible(false)}
      >
        <Sections
          className="block-name-table-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="exit__modal-card">
            <FormCommTable
              debt={debt}
              title={title}
              tableId={tableId}
              addNew={addNew}
              setFetchTable={setFetchTable}
            />
            <button
              onClick={() => setVisible(false)}
              className="exit__modal-card-btn"
            >
              Закрыть
            </button>
          </div>

          {/* <Divider /> */}
          <DataTable
            debt={debt}
            title={title}
            tableId={tableId}
            fetchTable={fetchTable}
            addNew={addNew}
          />
        </Sections>
      </section>
    </>
  );
};

export default CardModal;
