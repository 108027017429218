import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MiniTitle,
  Title,
  Hr,
  CardButton,
} from '../../../Component/Theme/CSSstyles';
import { DeleteOutlined } from '@ant-design/icons';
import EditContragent from './EditContragent/EditContragent';

import { numberFormat } from 'tools/numberFormat';
import ContragentHooks from 'Context/Contragent/ContragetHooks';
import AddNewContragent from '../AddNewCotragent/AddNewContragent';
import css from '../Contragent.module.css';

const ContragentAll = () => {
  const { ContragentDelete, isContragent, getContragent } = ContragentHooks();
  const navigation = useNavigate();

  function toOneContragent(id) {
    navigation(id);
  }

  useEffect(() => {
    getContragent();
    // eslint-disable-next-line
  }, []);

  if (isContragent.length === 0) {
    return 'Загрузка...';
  }

  const ContragentData = isContragent.map((i, index) => (
    <tr key={i.id} className={css.ContragentAllBodyTr}>
      <td className={css.ContragentAllTd}>{index + 1}</td>
      <td className={css.ContragentAllTd}>
        <CardButton onClick={() => toOneContragent(i.id)}>{i.name}</CardButton>
      </td>
      <td className={css.ContragentAllTd}>{i.izoh}</td>
      <td className={css.ContragentAllTd}>{i.telefon}</td>
      <td className={css.ContragentAllTd}>{numberFormat(i.oldi)}</td>
      <td className={css.ContragentAllTd}>{numberFormat(i.berdi)}</td>
      <td className={css.ContragentAllTd}>{numberFormat(i.qoldi)}</td>
      <td className={css.ContragentAllTd}>
        <EditContragent
          id={i.id}
          name={i.name}
          izoh={i.izoh}
          telefon={i.telefon}
        />{' '}
        <button
          className={css.contragetDelete}
          onClick={() => ContragentDelete(i.id)}
        >
          <DeleteOutlined />
        </button>
      </td>
    </tr>
  ));

  // выводит общую сумму
  let Prixod = 0;
  let Rasxod = 0;
  let Raznitsa = 0;
  isContragent.forEach(({ oldi, berdi, qoldi }) => {
    Prixod += Number(oldi);
    Rasxod += Number(berdi);
    Raznitsa = Prixod - Rasxod;
  });

  return (
    <section style={{ padding: 20 }}>
      <div className={css.ContragentAllHeader}>
        <Title>Данные контрагентов</Title>
        <div style={{ paddingTop: 10 }}>
          <AddNewContragent />
        </div>
      </div>
      <div className={css.BlockKalkulyation}>
        <div>
          Сумма приходов: <MiniTitle>{numberFormat(Prixod)}</MiniTitle>{' '}
        </div>
        <div>
          Сумма расходов: <MiniTitle>{numberFormat(Rasxod)}</MiniTitle>{' '}
        </div>
        <div>
          Остаточная сумма для возврата:{' '}
          <MiniTitle>{numberFormat(Raznitsa)}</MiniTitle>{' '}
        </div>
      </div>
      <Hr />
      <table className={css.ContragentAllTable}>
        <thead>
          <tr>
            <th className={css.ContragentAllTh}>№</th>
            <th className={css.ContragentAllTh}>Контрагент</th>
            <th className={css.ContragentAllTh}>Адрес</th>
            <th className={css.ContragentAllTh}>Номер</th>
            <th className={css.ContragentAllTh}>Приход</th>
            <th className={css.ContragentAllTh}>Расход</th>
            <th className={css.ContragentAllTh}>Остаток</th>
            <th className={css.ContragentAllTh}>Действия</th>
          </tr>
        </thead>
        <tbody>{ContragentData}</tbody>
      </table>
    </section>
  );
};

export default ContragentAll;
