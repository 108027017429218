// import React, { useState, useEffect } from "react";
// import { Table } from "antd";
// import Loding from "./Loding";
// import axios from "axios";
// import CSS from "./PageComponent.module.css";
// import { Span, Main, Paragraph } from "../Component/Theme/CSSstyles";
// const { Column } = Table;
// const priceTo = (price) => {
//   if (!price) {
//     return null;
//   }
//   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
// };
// const url = process.env.REACT_APP_DB_URL;

// function TableNewClient() {
//   const token = localStorage.getItem("DataToken");
//   const [dataState, setDataState] = useState([]);

//   const AxiosSave = () => {
//     const data = JSON.stringify({
//       token: token,
//     });
//     const config = {
//       method: "post",
//       url: `${url}/get-salary-arxiv.php`,
//       headers: {
//         "Content-Type": "text/plain",
//       },
//       data: data,
//     };

//     axios(config)
//       .then(function (response) {
//         setDataState(response.data);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
//   useEffect(() => {
//     AxiosSave();
//     // eslint-disable-next-line
//   }, []);

//   if (dataState.length === 0) return <Loding />;
//   return (
//     <>
//       <Table
//         dataSource={dataState}
//         pagination={false}
//         rowKey={(row) => row.key}
//         title={(dataState) => {
//           let totalCash = 0;

//           dataState.forEach(({ totalcash }) => {
//             totalCash += Number(totalcash);
//           });
//           return (
//             <Main>
//               <Paragraph className={CSS.CashInfo}>
//                 Сумма выдданных зарплат:
//                 <Span className={CSS.CashInfoSpan}>
//                   {totalCash.toLocaleString()}
//                 </Span>
//               </Paragraph>
//             </Main>
//           );
//         }}
//       >
//         <Column width={25} align="center" title="№" dataIndex="key" key="id" />
//         <Column
//           width={200}
//           align="center"
//           title="Имя"
//           dataIndex="username"
//           key="username"
//         />
//         <Column
//           width={200}
//           align="center"
//           title="Ежемесячная вставка"
//           dataIndex="salary"
//           key="salary"
//           render={(_, record) => {
//             const Salary = record.salary;
//             return priceTo(Salary);
//           }}
//         />
//         <Column
//           align="center"
//           title="Выданная зарплата"
//           dataIndex="totalcash"
//           key="totalCash"
//           width={200}
//           render={(_, record) => {
//             const TotalCash = record.totalcash;
//             return priceTo(TotalCash);
//           }}
//         />
//         <Column
//           width={180}
//           align="center"
//           title="Дата выдачи зарплаты"
//           dataIndex="date"
//           key="date"
//         />
//       </Table>
//     </>
//   );
// }

// export default TableNewClient;

import React, { useRef, useEffect, useState } from "react";
import { TableTh, TRow, MiniTitle } from "../../Component/Theme/CSSstyles";
import axios from "axios";
import ReactToPrint from "react-to-print";
import css from "./WorkersArchive.module.css";
import Loding from "../Loding";
import WorkersIntervalModal from "./workersInterval/WorkersIntervalModal";
// import swal from "sweetalert";
// import { MonthContext } from "../../Context/MonthContext";
const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
const url = process.env.REACT_APP_DB_URL;

const CashDataTable = ({ id, infoCash }) => {
  const componentRef = useRef();
  const token = localStorage.getItem("DataToken");

  const [dataAvans, setDataAvans] = useState([]);

  // получаем данные аванса
  const FetchAvans = () => {
    const data = JSON.stringify({
      token: token,
    });

    const config = {
      method: "post",
      url: `${url}/get-j-salary-arxiv.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //   console.log("архив", response.data);
        if (response.status === 200) {
          setDataAvans(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchAvans();
    // eslint-disable-next-line
  }, []);

  if (dataAvans.length === 0) {
    return (
      <div className={""}>
        <Loding />
      </div>
    );
  }
  const HistoryCash = dataAvans.map((i) => (
    <TRow key={i.id} className={css.WorkerArchiveInterval}>
      <td className={css.WorkerArchiveData}>{i.date}</td>
      <td className={css.WorkerArchiveData}>{i.fio}</td>
      <td className={css.WorkerArchiveData} style={{ textAlign: "left" }}>
        {i.comment}
      </td>
      <td className={css.WorkerArchiveData}>{priceTo(i.cash)}</td>
    </TRow>
  ));

  let TotalInterval = 0;
  dataAvans.forEach(({ cash }) => {
    TotalInterval += Number(cash);
  });

  return (
    <>
      <div className={css.WorkersArchivePrint}>
        <div>
          <WorkersIntervalModal />
        </div>
        <ReactToPrint
          trigger={() => (
            <button className="exit__modal-card-btn">Печатать</button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef} className={css.WorkersArchiveTableBlock}>
        <div>
          <MiniTitle>Выдана авансов на {priceTo(TotalInterval)} </MiniTitle>сум.
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <TableTh style={{ width: "10%" }}>Дата</TableTh>
              <TableTh style={{ width: "20%" }}>Имя</TableTh>
              <TableTh style={{ width: "50%" }}>Заметки</TableTh>
              <TableTh style={{ width: "20%" }}>
                Выданные <br /> суммы
              </TableTh>
            </tr>
          </thead>
          <tbody>{HistoryCash}</tbody>
        </table>
      </div>
    </>
  );
};

export default CashDataTable;
