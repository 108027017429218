import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Title } from "../Component/Theme/CSSstyles";
import ContragentAddData from "../PageComponent/Kontragent/ContragentAddData";
import ContragentTable from "../PageComponent/Kontragent/ContragentTable";
import ContragentInterval from "../PageComponent/Kontragent/ContragentInterval";
import css from "./Page.module.css";

const PageContragent = ({ pageName }) => {
  const { pageId, key } = useParams();

  const [dataContragent, setDataContargent] = useState([]);

  return (
    <div>
      <div className={css.HeaderContragent}>
        <Title>{pageName}</Title>
        <div className={css.ContragentBlockBtn}>
          <ContragentAddData
            pageName={pageName}
            pageId={pageId}
            setDataContargent={setDataContargent}
          />
          <ContragentInterval pageName={pageName} pageId={pageId} />
        </div>
      </div>
      <ContragentTable
        pageName={pageName}
        pageId={pageId || key}
        dataContragent={dataContragent}
      />
    </div>
  );
};

export default PageContragent;
