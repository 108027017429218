import React, { useState } from "react";
import axios from "axios";
import { Button, DatePicker, Form, Select } from "antd";
import swal from "sweetalert";
const url = process.env.REACT_APP_DB_URL;

const WorkInterval = ({ workData, setDataInterval }) => {
  //   console.log(workData);
  const onFinish = (value) => {
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    value.token = localStorage.getItem("DataToken");

    const data = JSON.stringify(value);
    //  console.log("value", value);
    const config = {
      method: "POST",
      url: `${url}/get-workers-work-interval.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          //  console.log("data>>", response.data);
          setDataInterval(response.data);
        }
      })
      .catch((err) => {
        swal({
          title: "Oops",
          text: `Какаято ошибка ${err} \xa0 обратитесь к разработчику`,
          icon: "warning",
        });
      });
  };

  const [placement] = useState("bottomRight");
  return (
    <Form
      // layout="vertical"
      onFinish={onFinish}
      className={""}
      style={{ marginLeft: 5, display: "flex" }}
    >
      <Form.Item
        label={"Выберите мастера"}
        name="worker_id"
        rules={[
          {
            required: true,
            message: "Вы не выбрали мастера!",
          },
        ]}
      >
        <Select
          style={{ width: "300px", fontSize: 20, marginRight: 5 }}
          //  defaultValue="default"
        >
          {/* <Select.Option value={"default"}>Выберите мастера</Select.Option> */}
          {workData.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {i.username}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="sana1"
        rules={[
          {
            required: true,
            message: "Выберете дату",
          },
        ]}
      >
        <DatePicker placement={placement} placeholder="Начальная" />
      </Form.Item>

      <Form.Item
        name="sana2"
        rules={[
          {
            required: true,
            message: "Выберете дату",
          },
        ]}
      >
        <DatePicker
          placement={placement}
          style={{ marginLeft: 5 }}
          placeholder="Конечная"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 5, padding: 0, width: 100 }}
        >
          Запрос
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WorkInterval;
