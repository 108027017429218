import { useReducer } from "react";
import axios from "axios";
import swal from "sweetalert";
import { OrderReduser } from "./OrderReduser.js";
import {
	ADD_ORDER,
	FETCH_ORDER,
	LOADER_ORDER,
	REMOVE_ORDER,
} from "../../typs/orderTypes.js";
import { config } from "config/configs.js";
import { OrdersContext } from "Context/context.js";



export const OrdersState = ({ children }) => {
	const token = localStorage.getItem("DataToken");
	const initialState = {
		order: [],
		loading: false,
	};
	const [state, dispatch] = useReducer(OrderReduser, initialState);
	// !LOADER
	const showLoader = () => dispatch({ type: LOADER_ORDER });
	//! Запрос для получения данных для таблицы клиентов
	const getOrder = async () => {
		showLoader();
		try {
			const response = await axios({
				method: "POST",
				url: `${config.DB_URL}/get-orders.php`,
				headers: {
					"Content-Type": "text/plain",
				},
				data: JSON.stringify({ token: token }),
			}).then((res) => {
				return res.data;
			});

			dispatch({ type: FETCH_ORDER, response });

			if (!response) {
				swal({
					title: `Ощибка`,
				});
				//   navigare("/login");
			}
		} catch (error) {
			swal({
				icon: "warning",
				title: `${error}`,
				text: "Ошибка в получения данных для таблицы клиентов",
			});
			if (error.code === "ERR_NETWORK") {
				swal({
					title: "O_o!",
					text: "Проверьте подключение к интернету",
					icon: "warning",
					timer: 2000,
				});
			}
		}
	};
	// TODO повторный запрос рендеринга без перезагрузки
	const getOrder2 = async () => {
		try {
			const response = await axios({
				method: "POST",
				url: `${config.DB_URL}/get-orders.php`,
				headers: {
					"Content-Type": "text/plain",
				},
				data: JSON.stringify({ token: token }),
			}).then((res) => {
				return res.data;
			});

			dispatch({ type: FETCH_ORDER, response });

			if (!response) {
				swal({
					title: `Ощибка`,
				});
				//   navigare("/login");
			}
		} catch (error) {
			swal({
				icon: "warning",
				title: `${error}`,
				text: "Ошибка в получения данных для таблицы клиентов",
			});
			if (error.code === "ERR_NETWORK") {
				swal({
					title: "O_o!",
					text: "Проверьте подключение к интернету",
					icon: "warning",
					timer: 2000,
				});
			}
		}
	};
	//! удаляем заказ
	const handleDelete = async (key) => {
		dispatch({
			type: REMOVE_ORDER,
			key: key,
		});
		await axios({
			method: "POST",
			url: `${config.DB_URL}/delete-order.php`,
			headers: {
				"Content-Type": "text/plain",
			},
			data: JSON.stringify({
				token: token,
				order_id: key,
			}),
		})
			.then(function (res) {
				if (200 === res.status) {
					//  getOrder();
					swal({
						title: "Успешно удалён",
						icon: "success",
					});
				}
			})
			.catch(function (error) {
				swal({
					title: "Ошибка",
					text: `${error}`,
					icon: "warning",
				});
				if (error.code === "ERR_NETWORK") {
					swal({
						title: "O_o!",
						text: "Проверьте подключение к интернету",
						icon: "warning",
						timer: 2000,
					});
				}
			});
	};
	//!Добавляем заказ
	const addNote = async (data) => {
		try {
			await axios({
				method: "post",
				url: `${config.DB_URL}/new-order.php`,
				headers: {
					"Content-Type": "text/plain",
				},
				data: data,
			}).then((res) => {
				//   console.log(res);
				if (0 === res.data.error) {
					getOrder2();
					swal({
						icon: "success",
						title: `${res.data.xabar}`,
					});
				}

				if (res.data.error === 1) {
					swal({
						title: `${res.data.xabar}`,
						icon: "warning",
					});
				}
			});
			dispatch({ type: ADD_ORDER });
			throw new Error(
				swal({
					icon: "warning",
					title: `${Error}`,
				})
			);
		} catch (e) {
			if (e.code === "ERR_NETWORK") {
				swal({
					title: "O_o!",
					text: "Проверьте подключение к интернету",
					icon: "warning",
					timer: 2000,
				});
			}
		}
	};

	// ! получаем данные об оплату
	return (
		<OrdersContext.Provider
			value={{
				showLoader,
				handleDelete,
				getOrder,
				getOrder2,
				addNote,
				loading: state.loading,
				order: state.order,
			}}
		>
			{children}
		</OrdersContext.Provider>
	);
};
