import React, { useRef } from "react";
import { Title } from "../../../Component/Theme/CSSstyles";
import Loding from "../../Loding";
import css from "./WorkDataInterval.module.css";
import WorkersWorkInterval from "./WorkersWorkInterval";
import WorkAvansInterval from "./WorkAvansInterval";
import ReactToPrint from "react-to-print";

const WorkData = ({ dataInterval }) => {
  const componentRef = useRef();
  if (!dataInterval) {
    return (
      <div className={css.WorkIntervalLoading}>
        <Loding />
      </div>
    );
  }

  // выводим имя рабочего
  const TitleInterval = dataInterval.work.map((b) => b.name);
  let NewTitle = [...new Set(TitleInterval)];

  // выводим данные о авансах

  return (
    <div>
      <Title>{NewTitle}</Title>
      <ReactToPrint
        trigger={() => (
          <button className="exit__modal-card-btn">Печатать</button>
        )}
        content={() => componentRef.current}
      />
      <div className={css.WorkBlockTable} ref={componentRef}>
        <WorkersWorkInterval dataInterval={dataInterval} />
        <WorkAvansInterval dataInterval={dataInterval} />
      </div>
    </div>
  );
};

export default WorkData;
