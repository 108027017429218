import React, { useState } from "react";
import RasxodIntervalForm from "./RasxodIntervalForm";
import RasxodIntervalTable from "./RasxodIntervalTable";
import ModalTop from "../../../Component/ModalComponent/ModalTop/ModalTop";

const RasxodInterval = () => {
  const [rasxodInterval, setRasxodInterval] = useState([]);
  return (
    <ModalTop name="Интервал расходов">
      <RasxodIntervalForm setRasxodInterval={setRasxodInterval} />
      <RasxodIntervalTable rasxodInterval={rasxodInterval} />
    </ModalTop>
  );
};

export default RasxodInterval;
