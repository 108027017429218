import React from "react";
import AccauntingForm from "./AccauntingForm";
import axios from "axios";
import swal from "sweetalert";
import { Form } from "antd";

const url = process.env.REACT_APP_DB_URL;

export default function GetAccaunting({ FetchWorkersDataGEt }) {
  const [form] = Form.useForm();
  // отправляем данные в бд
  const GetDataAccaunting = (value) => {
    value = {
      ...value,
      authDate: value["authDate"].format("YYYY-MM-DD"),
      authTime: value["authTime"].format("HH:mm:ss"),
    };
    value.authDateTime = `${value["authDate"] + " " + value["authTime"]}`;
    value.token = localStorage.getItem("DataToken");
    const data = JSON.stringify(value);
    //  console.log(data);
    const config = {
      method: "post",
      url: `${url}/add-davomat-day.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    //  console.log(value);

    swal({
      title: "Внимание!",
      text: `Проверьте данные! Число:  ${value.authDate ?? null} Время: ${
        value.authTime ?? null
      }`,
      icon: "warning",
      buttons: {
        cancel: "Нет!",
        catch: {
          text: "Да",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: "Успешно",
                text: `${res.data.xabar}`,
                icon: "success",
              });
            }
            // console.log(res);
            FetchWorkersDataGEt();
          })
          .catch(function (error) {
            // console.log("accaunting", error);
            swal({
              title: "Oops",
              text: `Какаято ошибка ${error} \xa0 обратитесь к разработчику`,
              icon: "warning",
            });
          });
      }
    });

    form.resetFields();
  };
  return <AccauntingForm GetDataAccaunting={GetDataAccaunting} form={form} />;
}
