import React from "react";
import { Form, Input, DatePicker, Button } from "antd";
import axios from "axios";
import NumberFormat from "react-number-format";
import Style from "./Contragent.module.css";
import swal from "sweetalert";
import DrowerModal from "../../Component/ModalComponent/DrowerModal/DrowerModal";
import { Span, Title, Hr } from "../../Component/Theme/CSSstyles";

import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";

const url = process.env.REACT_APP_DB_URL;

function ContragentGet({ setDataContargent, pageId, pageName }) {
  const [form] = Form.useForm();
  const UserName = localStorage.getItem("DataName");

  const onFinish = (values) => {
    values.token = localStorage.getItem("DataToken");
    values.tashkilot_id = pageId;
    values.username = UserName;
    const data = JSON.stringify(values);
    const config = {
      method: "POST",
      url: `${url}/add-baza.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    //  console.log("value>>>", data);
    swal({
      title: "Внимание!",
      text: `проверьте данные  Расход: ${values.repayment ?? "0"}, Приход: ${
        values.borrow ?? "0"
      } сум?!  Добавить данные???`,
      icon: "warning",
      buttons: {
        cancel: "Нет!",
        catch: {
          text: "Да",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              setDataContargent(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });

    form.resetFields();
  };

  return (
    <DrowerModal name={"Добавить запись"}>
      <Title>Добавить запись</Title>
      <Hr />
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        //   hideRequiredMark
        form={form}
      >
        <div className={Style.blockform}>
          <Form.Item
            name={["date"]}
            label={<Span>Дата</Span>}
            key="data"
            rules={[
              {
                required: true,
                message: "Введите дату",
              },
            ]}
          >
            <DatePicker
              placeholder="0000-00-00"
              locale={locale}
              className={Style.StyleInp}
            />
          </Form.Item>
          <Form.Item label={<Span>Курс $ / сум.</Span>} name={["comment"]}>
            <Input className={Style.StyleInp} />
          </Form.Item>
          <Form.Item label={<Span>Заметка.</Span>} name={["text"]}>
            <Input.TextArea className={Style.StyleInp} />
          </Form.Item>

          <Form.Item label={<Span>Приход</Span>} name={["borrow"]}>
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              autoComplete="off"
              className={Style.StyleInp}
            />
          </Form.Item>

          <Form.Item label={<Span>Расход</Span>} name={["repayment"]}>
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              autoComplete="off"
              className={Style.StyleInp}
            />
          </Form.Item>

          <Form.Item>
            <div className={Style.blockformBtn}>
              <Button
                type="primary"
                danger
                htmlType="submit"
                className={Style.formBtn}
              >
                Добавить
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </DrowerModal>
  );
}

export default ContragentGet;
