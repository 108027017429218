import React, { useState, useEffect } from "react";
import { Form, Input, Popconfirm, Table, Typography } from "antd";
import ModalCenter from "../../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import { Title, Hr } from "../../../../Component/Theme/CSSstyles";
import axios from "axios";
import NumberFormat from "react-number-format";
import Loding from "../../../Loding";
const url = process.env.REACT_APP_DB_URL;

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <NumberFormat
        //   thousandSeparator={true}
        customInput={Input}
        autoComplete="off"
      />
    ) : (
      <Input type="text" />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const App = ({ setPriceEdit }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const VidOplati = () => {
    const token = localStorage.getItem("DataToken");
    const data = JSON.stringify({ token: token });
    const config = {
      method: "post",
      url: `${url}/get-ish-turi.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    VidOplati();
    // eslint-disable-next-line
  }, []);
  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      id: "",
      name: "",
      norma: "",
      maosh: "",
      fee: "",
      birlik: "",
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (id) => {
    const row = await form.validateFields();
    row.id = id;
    row.token = localStorage.getItem("DataToken");
    const data = JSON.stringify(row);
    //  console.log(">>>>", data);
    const config = {
      method: "post",
      url: `${url}/update-ish-turi.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setData(response.data);
          setPriceEdit(response.data);
          <Loding />;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setEditingKey("");
  };
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "Название",
      dataIndex: "name",
      width: "25%",
      editable: true,
      key: "name",
    },
    {
      title: "Расчет зарплаты по",
      dataIndex: "birlik",
      width: "20%",
      key: "birlik",
      editable: true,
    },
    {
      title: "Норматив",
      dataIndex: "norma",
      width: "15%",
      key: "norma",
      editable: true,
      render: (_, record) => {
        return priceTo(record.norma);
      },
    },
    //   birlik: "",
    {
      title: "Цена",
      dataIndex: "fee",
      width: "20%",
      key: "fee",
      editable: true,
      render: (_, record) => {
        return priceTo(record.fee);
      },
    },
    {
      title: "Сумма зарплаты",
      dataIndex: "maosh",
      width: "35%",
      key: "maosh",
      editable: true,
      render: (_, record) => {
        return priceTo(record.maosh);
      },
    },

    {
      title: "Действия",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Сахранить
            </Typography.Link>
            <Popconfirm
              title="Точно отменить?"
              onConfirm={cancel}
              okText="да"
              cancelText="нет"
            >
              <span>Отмена</span>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Изменить
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "name"
            ? "text"
            : col.dataIndex === "birlik"
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <ModalCenter name={"Изменить"}>
      <Title>Таблица для изменения минимальной вставки</Title>
      <Hr />
      <Form form={form} component={false}>
        <Table
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey={(row) => row.id}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </ModalCenter>
  );
};
export default App;
