import React, { useEffect, useContext, useRef } from 'react';
import { Select, Input, Button, Form, Card, InputNumber } from 'antd';
import axios from 'axios';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import css from '../FinishedOrder.module.css';
import { RenFunction, RenderContext } from 'Context/context';
import { config } from 'config/configs';
import { numberFormat } from 'tools/numberFormat';

const PostKv = ({ modalGotov, workData }) => {
  const GetToken = useContext(RenderContext);
  const RenderFunction = useContext(RenFunction);
  const [form] = Form.useForm();

  const option = [];
  const RefSelect = useRef(null);

  workData.map((i) =>
    option.push({
      value: i.id,
      label: i.username,
    })
  );

  useEffect(() => {
    if (RefSelect.current) {
      RefSelect.current.focus();
    }
    // eslint-disable-next-line
  }, [modalGotov, RefSelect]);

  const onFinish = (values) => {
    values.token = config.TOKEN;
    values.client_id = modalGotov.key;

    swal({
      title: 'Внимание!',
      text: `Проверьте введенные данные объём: ${values.yuza}кв.  цена: ${
        values.sum ?? 'Стандартная цена с БД'
      }`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/add-ish-haqi-ustanovshik.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify(values),
        })
          .then(function (res) {
            // console.log(res);
            if (res.data.error === 1) {
              swal({
                title: 'Oops.....',
                text: `${res.data.xabar}`,
                icon: 'error',
                button: 'Ок',
              });
            } else if (res.data.error === 0) {
              swal({
                title: 'Отлично',
                text: `${res.data.xabar}  сумма: + ${numberFormat(
                  res.data.fee
                )}`,
                icon: 'success',
                button: 'Ок',
              });
              RenderFunction();
              GetToken();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
    form.resetFields();
  };

  return (
    <div className={css.BlockPrint}>
      <div className='site-card-border-less-wrapper'>
        <Card
          bordered={true}
          style={{
            width: '100%',
          }}
        >
          {' '}
          <Form
            autoComplete='off'
            form={form}
            name='basic'
            onFinish={onFinish}
            layout='vertical'
            className={css.FormAddInst}
          >
            <Form.Item
              label={'Выберите рабочего'}
              name='worker_id'
              rules={[
                {
                  required: true,
                  message: 'Вы не выбрали рабочего!',
                },
              ]}
            >
              <Select
                mode='tags'
                style={{
                  width: '600px',
                  fontSize: 20,
                }}
                placeholder='Выберите рабочего'
                options={option}
                ref={RefSelect}
              />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Введите{' '}
                  <>
                    m
                    <sup>
                      <small>2</small>
                    </sup>
                  </>{' '}
                  установки
                </span>
              }
              name='yuza'
              // defaultValue={modalGotov.yuza}

              rules={[
                {
                  required: true,
                  message: 'Вы не ввели квадрат!',
                },
              ]}
            >
              <InputNumber
                type={'number'}
                style={{
                  width: '200px',
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Введите стоимость установки за{' '}
                  <>
                    1m
                    <sup>
                      <small>2</small>
                    </sup>
                  </>
                </span>
              }
              name='sum'
              // defaultValue={modalGotov.yuza}

              //   rules={[
              //     {
              //       required: true,
              //       message: "Вы не ввели цену!",
              //     },
              //   ]}
            >
              <NumberFormat
                style={{ width: '200px' }}
                thousandSeparator={true}
                customInput={Input}
                autoComplete='off'
              />
            </Form.Item>
            <Form.Item>
              <div className={css.FormAddInstBtn}>
                <Button type='primary' danger htmlType='submit'>
                  Добавить
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PostKv;
