import React, { forwardRef, useState, useEffect } from "react";
import ClientAvansComm from "./ClientAvansTable/ClientAvansComm";
import ProductNameComm from "./ProductCommTable/ProductNameComm";
import FormMinusPlusCash from "./ClientCashOperation/FormMinusPlusCash";
import ProductAddComm from "./CommAddModal/ProductAddComm";
import OperationCashHook from "../../../Context/OrderContext/OperationCash/OperationCashHook";
import CSS from "./Component.module.css";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const ClientOneTable = forwardRef(({ active }, ref) => {
  const {
    onMinusCash,
    onPlusCash,
    onAddAvans,
    fetchDataAvans,
    onRemoveAvans,
    data,
  } = OperationCashHook();

  useEffect(() => {
    fetchDataAvans(active.key);
  }, []);
  //   const [getClientAvans, setGetClientAvans] = useState([]);
  const [getClientComm, setGetClientComm] = useState([]);

  return (
    <div ref={ref} className={CSS.MadalSection}>
      <div className={CSS.BlockBtbModal}>
        <ProductAddComm id={active.key} setGetClientComm={setGetClientComm} />

        <FormMinusPlusCash
          id={active.key}
          cash={active.cash}
          raschot={active.raschot}
          onFinesh={onAddAvans}
          title={"Добавить оплату за заказ"}
          buttonText={"Принять оплату"}
        />
        <FormMinusPlusCash
          id={active.key}
          onFinesh={onPlusCash}
          title={"Добавить сумму к заказу"}
          buttonText={"Добавить сумму"}
        />
        <FormMinusPlusCash
          id={active.key}
          onFinesh={onMinusCash}
          title={"Минусовать сумму с заказа"}
          buttonText={"Минусовать"}
        />
      </div>

      <h1 className={CSS.MadalTitleH1}>Накладной №: {active.key}</h1>
      <table className={CSS.ModalBlockText}>
        <thead>
          <tr>
            <th className={CSS.ModalClientTH}>№</th>
            <th className={CSS.ModalClientTH}>Дата заказа</th>
            <th className={CSS.ModalClientTH}>Дата установки</th>
            <th className={CSS.ModalClientTH}>Клиент</th>
            <th className={CSS.ModalClientTH}>Номер</th>
            <th className={CSS.ModalClientTH}>Адрес</th>
            <th className={CSS.ModalClientTH}>Сумма заказа</th>
            <th className={CSS.ModalClientTH}>Остаток</th>
            <th className={CSS.ModalClientTH}>Сумма аванса</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={CSS.ModalClientInfo}>{active.key}</td>
            <td className={CSS.ModalClientInfo}>{active.date}</td>
            <td className={CSS.ModalClientInfo}>{active.installationDate}</td>
            <td className={CSS.ModalClientInfo}> {active.name}</td>
            <td className={CSS.ModalClientInfo}> {active.phone}</td>
            <td className={CSS.ModalClientInfo}>{active.adres}</td>
            <td className={CSS.ModalClientInfo}>{priceTo(active.cash)}</td>
            <td className={CSS.ModalClientInfo}>{priceTo(active.ostatok)}</td>
            <td className={CSS.ModalClientInfo}>{priceTo(active.raschot)}</td>
          </tr>
        </tbody>
      </table>
      <main className={CSS.AvansPlusBlock}>
        <div style={{ width: "100%" }}>
          <h3 className={CSS.AvansPlusH1}>
            Добавочная сумма к авансу клиента.
          </h3>

          <ClientAvansComm
            id={active.key}
            data={data}
            onRemoveAvans={onRemoveAvans}
          />
        </div>

        <div>
          <h3 className={CSS.AvansPlusH1}>Перечень заказа.</h3>
          <ProductNameComm
            id={active?.key}
            getClientComm={getClientComm}
            comm={active.description}
          />
        </div>
      </main>

      <div className={CSS.PrintTextClient}>
        <div>
          <p className={CSS.PrintText}>Клиент: {active?.name}</p>
          <p className={CSS.PrintText}>Тавар получил подпись:</p>
        </div>
        <div>
          <p className={CSS.PrintText}>Поставщик: Хасанов Азамат</p>
          <p className={CSS.PrintText}>Подпись:</p>
        </div>
      </div>
      <p className={CSS.PrintTextPechat}>
        Печать даёт гарантию обслуживания 3 года со дня установки;
      </p>
    </div>
  );
});

// Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores asperiores molestias labore, esse amet eum quia fuga odio ea? Eum inventore quisquam assumenda quam corrupti consequatur, cumque voluptatum laborum rem!
// Provident, sunt optio? Alias mollitia aut facilis excepturi quaerat, enim, delectus suscipit odit ullam eius maxime assumenda doloremque voluptas magnam sint iste laborum sequi pariatur inventore earum? Provident, error accusamus?
