import React from "react";
import Loding from "../../Loding";
import CartComponent from "../../Test/CartComponent";
import OrderClientInfo from "./OrdersTable/OrderClientInfo";
import { OrdersTable } from "./OrdersTable/OrdersTable";
import css from "./OrderSingle.module.css";
import OrdersPayment from "./OrdersPayment/OrdersPayment";
import OrdersCahPlusMinus from "./OrdersCashPlusMinus/OrdersCahPlusMinus";
export default function OrderSingle({
  loding,
  fetchData,
  data,
  onRemoveAvans,
}) {
  if (loding === true) {
    return <Loding />;
  }
  const [{ order, paument, plus_minus }] = fetchData;

  const dates = order?.map((i) => (
    <CartComponent key={i.id} cash={i.cash} ras={i.raschot} />
  ));
  return (
    <div>
      <div className={css.orderSingleHeader}>
        <OrderClientInfo order={order} />
        {dates}
      </div>
      <OrdersTable order={order} />
      <div className={css.orderCashData}>
        <OrdersPayment payment={paument} onRemoveAvans={onRemoveAvans} />
        <OrdersCahPlusMinus plusMinus={plus_minus} />
      </div>
    </div>
  );
}
