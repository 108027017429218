import axios from 'axios';
import { useState } from 'react';
import swal from 'sweetalert';
import Loding from '../../PageComponent/Loding';
import { config } from 'config/configs';
import { ContragetContext } from 'Context/context';

export const ContragentState = ({ children }) => {
  const token = localStorage.getItem('DataToken');
  const [isContragent, setContragent] = useState([]);

  const getContragent = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.DB_URL}/get-tashkilot.php`,
        headers: {
          'Content-Type': 'text/plain',
        },
        data: JSON.stringify({ token: token }),
      });
      const { data } = response;
      if (response.data.error !== 1) {
        setContragent(data);
      } else {
        return <Loding />;
      }
    } catch (error) {
      swal({
        title: 'Ошибка',
        text: `${error}`,
        icon: 'error',
        buttons: 'OK',
      });
    }
  };
  const ContragentDelete = (event) => {
    swal({
      title: 'Внимание точно удалить!',
      text: `вы не сможете восстановить данные после удаления!`,
      icon: 'warning',
      buttons: {
        cancel: 'Отменить!',
        catch: {
          text: 'Удалить',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'POST',
          url: `${config.DB_URL}/delete-tashkilot.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify({ id: event, token: token }),
        })
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: 'Отлично!',
                text: `${res.data.xabar}`,
                icon: 'success',
                buttons: 'OK',
              });
            }
            getContragent();
          })
          .catch(function (error) {
            swal({
              title: 'Ошибка',
              text: `${error}`,
              icon: 'error',
              buttons: 'OK',
            });
          });
      }
    });
  };

  return (
    <ContragetContext.Provider
      value={{ getContragent, isContragent, ContragentDelete, setContragent }}
    >
      {children}
    </ContragetContext.Provider>
  );
};
