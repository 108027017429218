import React from "react";
import { ExportOutlined } from "@ant-design/icons";
import { useSignOut } from "react-auth-kit";

const HeaderExit = () => {
  const signOut = useSignOut();

  return (
    <button style={style.btnStyle} onClick={() => signOut()}>
      <ExportOutlined style={style.iconStyle} />
    </button>
  );
};
export default HeaderExit;

const style = {
  btnStyle: {
    background: "#ff4d4f",
    color: "#fff",
    borderRadius: "5px",
    borderColor: "#ff4d4f",
    padding: "1px 10px",
    margin: "0 2px 0 0",
    fontSize: "14px",
    cursor: "pointer",
  },
  iconStyle: {
    color: "#fff",
    fontSize: 18,
    padding: "3px 0",
  },
};
