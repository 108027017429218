import React from "react";
import { TableTh,  Title } from "../../../Component/Theme/CSSstyles";
import css from "./RasxodInterval.module.css";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const RasxodIntervalTable = ({ rasxodInterval }) => {
  if (!rasxodInterval) {
    return <h1>Загрузка...</h1>;
  }
  const RasxodTableInterval = rasxodInterval.map((i, index) => (
    <tr key={i.id} className={css.RasxodIntervalTr}>
      <td className={css.RasxodIntervalTd}>{index + 1}</td>
      <td className={css.RasxodIntervalTd}>{i.othersDate}</td>
      <td className={css.RasxodIntervalTd}>{i.othersComment}</td>
      <td className={css.RasxodIntervalTd}>{priceTo(i.othersBorrow)}</td>
    </tr>
  ));
  let Rasxod = 0;
  rasxodInterval.forEach(({ othersBorrow }) => {
    Rasxod += Number(othersBorrow);
  });
  return (
    <div>
      <Title>Сумма расхода: {priceTo(Rasxod)} сум</Title>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <TableTh>№</TableTh>
            <TableTh>Дата</TableTh>
            <TableTh>Заметки</TableTh>
            <TableTh>Сумма</TableTh>
          </tr>
        </thead>
        <tbody>{RasxodTableInterval}</tbody>
      </table>
    </div>
  );
};

export default RasxodIntervalTable;
