import React from "react";
import { Span, TableTh, TRow } from "../../../Component/Theme/CSSstyles";
import Loding from "../../Loding";
import css from "./WorkDataInterval.module.css";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const WorkersWorkInterval = ({ dataInterval }) => {
  if (!dataInterval) {
    return (
      <div className={css.WorkIntervalLoading}>
        <Loding />
      </div>
    );
  }

  // выводим данные о работах
  const DataInterval = dataInterval.work.map((i) => (
    <TRow key={i.id}>
      <td className={css.WorkIntervalData} >{i.date}</td>
      <td className={css.WorkIntervalData} >{i.order}</td>
      <td className={css.WorkIntervalData} >{i.comment}</td>
      <td className={css.WorkIntervalData} >{round(i.yuza, 2)}</td>
      <td className={css.WorkIntervalData} >{priceTo(i.pay)}</td>
    </TRow>
  ));
  let TotalKv = 0;
  let TotalCash = 0;
  dataInterval.work.forEach(({ cash, yuza }) => {
    TotalCash += Number(cash);
    TotalKv += Number(yuza);
  });

  return (
    <div>
      <div>
        <Span>сумма:</Span>
        {""}
        <Span>{priceTo(round(TotalCash))}</Span>
      </div>
      <div>
        <Span>объём:</Span> {""}
        <Span>{round(TotalKv, 2)}</Span>
      </div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <TableTh>Дата</TableTh>
            <TableTh>Номер заказа</TableTh>
            <TableTh>Данные</TableTh>
            <TableTh>Квадрат</TableTh>
            <TableTh>Цена</TableTh>
          </tr>
        </thead>
        <tbody>{DataInterval}</tbody>
      </table>
    </div>
  );
};

export default WorkersWorkInterval;

// cash: "4440";
// comment: "За установку заказа под № 179 добавлена сумма в баланс4440";
// date: "07.12.2022";
// id: "390";
// name: "Shoxrux aka";
// order: "179";
// yuza: "0.222";
