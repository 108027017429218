import React, { useRef, useState, useEffect, useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import css from './EditWorking.module.css';
import axios from 'axios';
import swal from 'sweetalert';
import DrowerModal from 'Component/ModalComponent/DrowerModal/DrowerModal';
import { WorkingRender } from 'Context/context';
import { config } from 'config/configs';
import { CardButton, Span, Title } from 'Component/Theme/CSSstyles';

const EditWorking = ({
  priceData,
  id,
  name,
  tel,
  fp_id,
  bot_id,
  maosh,
  norma,
}) => {
  // context для обновления таблицы рабочих
  const FetchWorkData = useContext(WorkingRender);
  const [getStatus, setGetStatus] = useState([]);
  const RefName = useRef();
  const RefTel = useRef();
  const RefRol = useRef();
  const RefView = useRef();
  const RefTelelegram = useRef();
  const RefMaosh = useRef();
  const RefNorma = useRef();

  // получение статуса для формы
  const GetStatus = async () => {
    await axios({
      method: 'post',
      url: `${config.DB_URL}/get-lavozim.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (response) {
        if (response.status === 200) {
          setGetStatus(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetStatus();
    // eslint-disable-next-line
  }, []);

  const FetchEdit = () => {
    if (
      RefRol.current.value === 'default' &&
      RefView.current.value === 'default'
    ) {
      swal({
        title: "Ma'lumotasha to'gri daror!",
        text: `Статус, Вид оплаты труда ва Телеграм ID а эс набурот!!!`,
        icon: 'error',
        buttons: 'Ok',
      });
      return null;
    }

    axios({
      method: 'post',
      url: `${config.DB_URL}/update-workers.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        username: RefName.current.value,
        telefon: RefTel.current.state.value,
        lavozim_id: RefRol.current.value,
        ishturi_id: RefView.current.value,
        token: config.TOKEN,
        id: id,
        fp_id: fp_id,
        telegram_id: RefTelelegram.current.state.value,
        maosh: RefMaosh.current.state.numAsString,
        norma: RefNorma.current.state.numAsString,
      }),
    })
      .then(function (res) {
        FetchWorkData();
        swal({
          title: 'Shud daromad',
          icon: 'success',
          buttons: 'Ok',
        });
      })
      .catch(function (error) {
        swal({
          title: 'Oops...',
          text: `${error}`,
          icon: 'warning',
          buttons: 'Ok',
        });
      });
  };

  return (
    <DrowerModal name={<EditOutlined title='Изменить' />}>
      <Title>Изменения данных рабочего</Title>

      <div className={css.EditWorkingContainer}>
        <div>
          <Span>Имя рабочего</Span>
          <input
            type='text'
            defaultValue={name}
            className={css.clientAddInp}
            ref={RefName}
          />
        </div>
        <div>
          <Span>Номер(тел)</Span>
          <NumberFormat
            format='(##) ###-##-##'
            mask='_'
            placeholder={tel}
            defaultValue={tel}
            className={css.clientAddInp}
            ref={RefTel}
          />
        </div>

        <div>
          <Span>Статус рабочего</Span>
          <select
            style={{ width: '100%' }}
            className={css.clientAddInp}
            defaultValue={'default'}
            ref={RefRol}
          >
            <option disabled value={'default'}>
              Выберите статус рабочего
            </option>
            {getStatus.map((k) => (
              <option key={k.id} value={k.id}>
                {k.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <Span>Вид оплаты труда</Span>
          <select
            style={{ width: '100%' }}
            className={css.clientAddInp}
            defaultValue='default'
            ref={RefView}
          >
            <option disabled value='default'>
              Виберите вид оплаты труда
            </option>
            {priceData.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Span>Ежемесячная вставка</Span>
          <NumberFormat
            thousandSeparator={true}
            placeholder={maosh}
            defaultValue={maosh}
            className={css.clientAddInp}
            ref={RefMaosh}
          />
        </div>
        <div>
          <Span>Ежемесячная норматив</Span>
          <NumberFormat
            thousandSeparator={true}
            // placeholder="Введите норматив"
            defaultValue={norma}
            className={css.clientAddInp}
            ref={RefNorma}
          />
        </div>
        <div>
          <Span>Бот(id)</Span>
          <NumberFormat
            placeholder={bot_id}
            defaultValue={bot_id}
            className={css.clientAddInp}
            ref={RefTelelegram}
          />
        </div>
      </div>
      <div style={{ paddingTop: 20 }}>
        <CardButton
          type='submit'
          onClick={FetchEdit}
          className={css.StyleBtnAdd}
        >
          Изменить
        </CardButton>
      </div>
    </DrowerModal>
  );
};

export default EditWorking;
