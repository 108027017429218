import React, { useState, useEffect } from "react";
import DinamikTable from "./AddNewTable/DinamikTable";
import CardModal from "./blockCardTable/CardModal";
import TheadCss from "./Private.module.css";
import {
	MainCard,
	CardSpan,
	CardTitle,
	CardButton,
} from "../../Component/Theme/CSSstyles";
import axios from "axios";
import swal from "sweetalert";
import { DeleteOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import EditCard from "./EditCard/EditCard";
import FilterModal from "./FilterTable/FilterModal";
import Loding from "../../PageComponent/Loding";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import useRequiredAuth from "hook/useRequiredAuth";
import { RenFunction, RenderContext } from "Context/context";

const priceTo = (price) => {
	if (!price) return "";
	return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const PrivareWindow = () => {
	const { signOut } = useRequiredAuth();
	// обновление rows
	//   const [rowsData, setRowsData] = useState();
	// обновление rows

	const navigate = useNavigate();

	const token = localStorage.getItem("DataToken");
	const [addNew, setAddNew] = useState([]);

	const [newTable, setNewTable] = useState([]);

	const Restitle = () => {
		let data = JSON.stringify({
			token: token,
		});

		var config = {
			method: "post",
			url: `${url}/get-tables.php`,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};
		axios(config)
			.then(function (response) {
				if (response.data.error === 1) {
					swal({
						title: "Ошибка",
						text: "Ошибка с соединением!",
						icon: "error",
						button: "Ок",
					});
				} else if (response.data.error !== 1) {
					setNewTable(response.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	useEffect(() => {
		Restitle();
		// eslint-disable-next-line
	}, [addNew]);

	const FetchDelete = (i) => {
		var data = JSON.stringify({
			token: token,
			id: i,
		});
		var config = {
			method: "post",
			url: `${url}/delete-table.php`,
			headers: {
				"Content-Type": "text/plain",
			},
			data: data,
		};
		swal({
			title: "Внимание, точно удалить???",
			text: `Таблица будет удалён со всеми данными!`,
			icon: "warning",
			buttons: {
				cancel: "Нет!",
				catch: {
					text: "Да",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				axios(config)
					.then(function (response) {
						Restitle();
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	if (newTable.length === 0) {
		return (
			<div className={TheadCss.LoadingBlock}>
				<Loding />
			</div>
		);
	}

	return (
		<>
			<RenFunction.Provider value={Restitle}>
				<RenderContext.Provider value={newTable}>
					<div className={TheadCss.block__btn}>
						<div>
							<Button
								type="primary"
								danger
								shape="circle"
								onClick={() => signOut(() => navigate("/", { replace: true }))}
								style={{ marginRight: "5px" }}
							>
								<RollbackOutlined />
							</Button>
							<DinamikTable setAddNew={setAddNew} />
							<EditCard />
							<FilterModal />
						</div>
						{/* <Button onClick={Restitle} type="primary" danger shape="circle">
              <LoadingOutlined />
            </Button> */}
					</div>
					<div className={TheadCss.CardContainer}>
						<TransitionGroup component="main" className="list-group">
							{newTable.map((i) => (
								<CSSTransition key={i.id} classNames={"note"} timeout={500}>
									<MainCard className={TheadCss.CardContainerData}>
										<CardTitle>
											Название таблицы: <CardSpan>{i.name}</CardSpan>
										</CardTitle>

										<CardTitle>
											Операция:{" "}
											<CardSpan>
												{i.oldi_berdi === "berdi" ? "Кредит" : "Дебит"}
											</CardSpan>
										</CardTitle>

										<CardTitle>
											Сумма: <CardSpan>{priceTo(i.debt)}</CardSpan>
										</CardTitle>

										<CardTitle>
											Ч.М.Г: <CardSpan>{i.sana}</CardSpan>
										</CardTitle>

										<div className={TheadCss.CardBlockBtn}>
											<CardModal
												title={i.name}
												debt={i.debt}
												tableId={i.id}
												key={i.id.toString()}
												addNew={addNew}
											/>
											<CardButton onClick={() => FetchDelete(i.id)}>
												<DeleteOutlined />
											</CardButton>
										</div>
									</MainCard>
								</CSSTransition>
							))}
						</TransitionGroup>
					</div>
				</RenderContext.Provider>
			</RenFunction.Provider>
		</>
	);
};

export default PrivareWindow;
