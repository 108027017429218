import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import "./Colaps.css";
import ContragentHooks from "Context/Contragent/ContragetHooks";
import {
  FolderOpenOutlined,
  HomeOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  //   MailOutlined,
  LinkOutlined,
  OrderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const ColopsMenu = () => {
  const navigate = useNavigate();
  const { isContragent } = ContragentHooks();
  const onClick = (e) => {
    navigate(e.key);
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Главная", "sub1", <HomeOutlined />, [
      getItem("Домащняя страница", "/"),
      getItem("Мои портнёры", "portner"),
      getItem("Личное", "private"),
    ]),
    getItem("Клиенты", "sub2", <OrderedListOutlined />, [
      getItem("Новый клиент", "client", <FolderOpenOutlined />),
      getItem("Архив", "russetclients"),
    ]),
    getItem("Персонал", "sub3", <TeamOutlined />, [
      getItem("Учёт", "uchot"),
      getItem("Информацыя рабочих", "workinfo", <FolderOpenOutlined />),
      getItem("Архив зарплат", "arxiv", <FolderOpenOutlined />),
    ]),
    getItem("Сумовые операции", "sub4", <ShoppingCartOutlined />, [
      getItem(
        "База",
        "sub5",
        null,
        isContragent?.map((i) => {
          return i.tashkilot_tur === "1"
            ? getItem(i.name, i.id, <FolderOpenOutlined />)
            : null;
        })
      ),
      getItem(
        "Стекло",
        "sub6",
        null,
        isContragent.map((i) => {
          return i.tashkilot_tur === "2"
            ? getItem(i.name, i.id, <FolderOpenOutlined />)
            : null;
        })
      ),
      getItem(
        "Разные",
        "sub7",
        null,
        isContragent.map((i) => {
          return i.tashkilot_tur === "3"
            ? getItem(i.name, i.id, <FolderOpenOutlined />)
            : null;
        })
      ),
      getItem("Расходы", "sub8", null, [
        getItem("Разные расходы", "expenses", <FolderOpenOutlined />),
      ]),
    ]),
    getItem("Контрагенты", "contragenti", <LinkOutlined />),
    getItem("Настройки", "sub9", <SettingOutlined />, [
      getItem("Логиг/Пароль", "loginChange"),
    ]),
  ];
  return (
    <Menu
      onClick={onClick}
      mode="inline"
      items={items}
      style={{
        color: "#fff",
      }}
      theme="dark"
    />
  );
};

export default ColopsMenu;
