import React, { useState } from "react";
import axios from "axios";
import { Button, DatePicker, Form } from "antd";
// const priceTo = (price) => {
//   if (!price) return "";

//   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
// };

const url = process.env.REACT_APP_DB_URL;

const ContragentGetInterval = ({ pageId, setIntervalContr }) => {
  const onFinish = (value) => {
    value = {
      ...value,
      date: value["sana1"].format("YYYY-MM-DD"),
      date2: value["sana2"].format("YYYY-MM-DD"),
    };
    value.token = localStorage.getItem("DataToken");
    value.tashkilot_id = pageId;

    const data = JSON.stringify(value);
    const config = {
      method: "POST",
      url: `${url}/get-baza-interval.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          //  console.log(response.data);
          const dataInterval = response.data.reverse();
          setIntervalContr(dataInterval);
        }
      })
      .catch((err) => {
        console.log("GetInfoErr > > > >", err);
      });
  };
  const [placement] = useState("bottomRight");
  return (
    <Form
      onFinish={onFinish}
      className={""}
      style={{ marginLeft: 5, display: "flex" }}
    >
      <Form.Item
        name="sana1"
        rules={[
          {
            required: true,
            message: "Выберете дату",
          },
        ]}
      >
        <DatePicker placement={placement} placeholder="Начальная" />
      </Form.Item>

      <Form.Item
        name="sana2"
        rules={[
          {
            required: true,
            message: "Выберете дату",
          },
        ]}
      >
        <DatePicker
          placement={placement}
          style={{ marginLeft: 5 }}
          placeholder="Конечная"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 5, padding: 0, width: 100 }}
        >
          Запрос
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContragentGetInterval;
