import React, { useRef } from "react";
import { EditOutlined } from "@ant-design/icons";
import DrowerModal from "../../../../Component/ModalComponent/DrowerModal/DrowerModal";
import { Title } from "../../../../Component/Theme/CSSstyles";
import css from "../../Contragent.module.css";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import axios from "axios";
import ContragentHooks from "../../../../Context/Contragent/ContragetHooks";

const url = process.env.REACT_APP_DB_URL;

const EditContragent = ({ id, name, izoh, telefon }) => {
  const { getContragent } = ContragentHooks();
  const token = localStorage.getItem("DataToken");
  const RefLinkName = useRef();
  const RefAdres = useRef();
  const RefNumber = useRef();

  const EditingContragent = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      name: RefLinkName.current.value,
      izoh: RefAdres.current.value,
      telefon: RefNumber.current.state.value,
      id: id,
      token: token,
    });
    const config = {
      method: "POST",
      url: `${url}/update-tashkilot.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    swal({
      title: "Внимание проверьте данные !",
      text: ` ${"Название:" + RefLinkName.current.value},  ${
        "Номер:" + RefNumber.current.state.value
      }, ${"Адрес:" + RefAdres.current.value}!`,
      icon: "warning",
      buttons: {
        cancel: "Отменить!",
        catch: {
          text: "Изменить",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (res) {
            console.log(res);
            if (res.status === 200) {
              swal({
                title: "Отлично!",
                text: `${res.data.xabar}`,
                icon: "success",
                buttons: "OK",
              });
            }
            getContragent();
          })
          .catch(function (error) {
            swal({
              title: "Ошибка",
              text: `${error}`,
              icon: "error",
              buttons: "OK",
            });
          });
      }
    });
  };
  return (
    <DrowerModal name={<EditOutlined />}>
      <Title>Изменить данные контрагента</Title>

      <form onSubmit={EditingContragent}>
        <div>
          <label className={css.ContragentEditLabel} htmlFor="">
            Название фирмы или контрагента{" "}
          </label>
          <input
            type="text"
            defaultValue={name}
            ref={RefLinkName}
            className={css.ContragentEditInp}
          />
        </div>

        <div>
          <label className={css.ContragentEditLabel} htmlFor="">
            Адрес
          </label>
          <input
            type="text"
            defaultValue={izoh}
            ref={RefAdres}
            className={css.ContragentEditInp}
          />
        </div>

        <div>
          <label className={css.ContragentEditLabel} htmlFor="">
            Контактный номер фирмы
          </label>
          <NumberFormat
            format="(##) ###-##-##"
            mask="_"
            placeholder={telefon}
            value={telefon}
            ref={RefNumber}
            className={css.ContragentEditInp}
          />
        </div>

        <button type="submit" className={css.contragetEditBtn}>
          Изменить
        </button>
      </form>
    </DrowerModal>
  );
};

export default EditContragent;
