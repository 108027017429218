import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TableNewClient from "../PageComponent/Client/TableNewClient";
import FormAddClient from "../PageComponent/Client/ClientMiniComponent/ClientAdd/FormAddClient";
import style from "./Page.module.css";
import { Title } from "../Component/Theme/CSSstyles";
import { Col, Row } from "antd";
import UseSerchHook from "../PageComponent/Client/UseSerchHook";
import OrderHook from "../Context/OrderContext/OrderHook";

function ClientNew(props) {
  // const auth = useAuthUser()
  // console.log(auth())
  const [search, setSerch] = useSearchParams();
  const serchUrl = search.get("order") || "";
  const { handleDelete, getOrder, loading, order } = OrderHook();

  useEffect(() => {
    getOrder();
    //   eslint-disable-next-line
  }, []);

  return (
    <div style={{ paddingTop: 10 }}>
      {/* <NewMenu /> */}
      <FormAddClient />
      <div className={style.Client}>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col span={4}>
            <Title>Заказы</Title>
          </Col>
          <Col span={6}>
            <UseSerchHook setSerch={setSerch} />
          </Col>
        </Row>
        <TableNewClient
          filtered={order.reverse()}
          loading={loading}
          onRemove={handleDelete}
          serchUrl={serchUrl}
        />
      </div>
    </div>
  );
}

export default ClientNew;
