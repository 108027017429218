import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostKv from '../FormPostData/PostKv';
import GetInstaller from '../GetDataIns/GetInstaller';
import { config } from 'config/configs';
import { RenFunction } from 'Context/context';

const InstallersWorkers = ({ modalGotov }) => {
  // State для получения данных рабочих
  const [workData, setWorkData] = useState([]);
  // State для получения данных рабочих кто установил
  const [getData, setGetData] = useState([]);

  // Запрос для получения данных рабочих
  const FetchWorkData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-workers.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (res) {
        if (res.status === 200) {
          setWorkData(res.data);
          GethWorkData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // запрос данных кто устоновил рамки
  const GethWorkData = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-installer.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (res) {
        if (res.status === 200) {
          setGetData(res.data);
          //  console.log("Установщики", res);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchWorkData();
    GethWorkData();
    // eslint-disable-next-line
  }, [modalGotov]);

  return (
    <RenFunction.Provider value={GethWorkData}>
      <h1>Данные о установках </h1>
      <PostKv workData={workData} modalGotov={modalGotov} />
      <GetInstaller getData={getData} modalGotov={modalGotov} />
    </RenFunction.Provider>
  );
};

export default InstallersWorkers;
