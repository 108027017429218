import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { DiaryTable } from "./DiaryTable/DiaryTable";
import { Button } from "antd";

export function DiaryPrint({ fetchInfo }) {
  const componentRef = useRef();
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button type="danger" style={{ padding: 0, width: "10%" }}>
            Печатать
          </Button>
        )}
        content={() => componentRef.current}
      />

      <DiaryTable ref={componentRef} fetchInfo={fetchInfo} />
    </div>
  );
}
