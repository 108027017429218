import React from "react";
import { Button, Form, Input, Row, Col, DatePicker } from "antd";
import axios from "axios";
import { Spans, MiniTitle } from "../../../Component/Theme/CSSstyles";
import swal from "sweetalert";
import NumberFormat from "react-number-format";

// import { useNavigate } from "react-router-dom";

import ModalCenter from "../../../Component/ModalComponent/ModalMiddle/ModalCenter";

const url = process.env.REACT_APP_DB_URL;

function FormCommTable({ setFetchTable, title, tableId }) {
  //   const history = useNavigate();

  const [form] = Form.useForm();

  const onChangeLogin = (values) => {
    values.table_id = tableId;
    values.token = localStorage.getItem("DataToken");
    var data = JSON.stringify(values);
    var config = {
      method: "post",
      url: `${url}/add-data-table.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    swal({
      title: "Внимание",
      text: `Вы уверены добавить данные?! Расходная сумма: ${
        values.repayment ?? "0"
      }, Возвращенная сумма: ${values.borrow ?? "0"}`,
      icon: "warning",
      buttons: {
        cancel: "Нет!",
        catch: {
          text: "Ok",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        axios(config)
          .then(function (response) {
            swal({
              title: "Отлично",
              text: "Данные добавлены",
              icon: "success",
              buttons: "Ok",
            });
            setFetchTable(response.data);
          })
          .catch(function (error) {
            console.logdata(error);
          });
      }
    });

    form.resetFields();
  };

  return (
    <ModalCenter name={"Добавить данные"}>
      <MiniTitle>{title}</MiniTitle>
      <MiniTitle>Добавление данных</MiniTitle>
      <div style={{ width: 600 }}>
        <Form
          layout="vertical"
          colon={false}
          form={form}
          onFinish={onChangeLogin}
          autoComplete="off"
        >
          <Form.Item
            label={<Spans>Выберите дату</Spans>}
            name="date"
            rules={[
              {
                required: true,
                message: "Вы не выбрали дату!",
              },
            ]}
          >
            <DatePicker
              placeholder="Дата"
              style={{ width: "100%", fontSize: 20 }}
            />
          </Form.Item>
          <Form.Item
            label={<Spans>Добавьте заметку!</Spans>}
            name="comment"
            rules={[
              {
                required: true,
                message: "Вы не добавили заметку!",
              },
            ]}
          >
            <Input.TextArea placeholder="Заметка" style={{ fontSize: 20 }} />
          </Form.Item>

          <Form.Item label={<Spans>Расходная сумма</Spans>} name="repayment">
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              autoComplete="off"
              placeholder="Введите сумму расхода"
              style={{ fontSize: 20 }}
            />
          </Form.Item>

          <Form.Item label={<Spans>Сумма возврата</Spans>} name="borrow">
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              autoComplete="off"
              placeholder="Введите сумму возврата"
              style={{ fontSize: 20 }}
            />
          </Form.Item>

          <Row>
            <Col span={6} offset={10}>
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  htmlType="submit"
                  style={{
                    fontSize: 20,
                    padding: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  Добавить
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </ModalCenter>
  );
}

export default FormCommTable;
