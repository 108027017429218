import React, { useState } from "react";
import { Form, Input, Popconfirm, Table, Typography, Tooltip } from "antd";
import axios from "axios";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import Loding from "../Loding";
import { EditOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

const url = process.env.REACT_APP_DB_URL;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <NumberFormat
        thousandSeparator={true}
        customInput={Input}
        autoComplete="off"
      />
    ) : (
      <Input autoComplete="off" type="text" />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          autoComplete="off"
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const datates = [
  {
    key: 1,
    othersDate: new Date().toLocaleDateString(),
    othersComment: "",
    othersBorrow: "",
  },
];

const PostTableRaznoe = ({ setGetOthers }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isLoadin, setIsLoading] = useState(false);
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      othersDate: "",
      othersComment: "",
      othersBorrow: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    const row = await form.validateFields();
    row.token = localStorage.getItem("DataToken");
    row.username = localStorage.getItem("DataName");
    const data = JSON.stringify(row);
    const config = {
      method: "POST",
      url: `${url}/add-rasxod.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    if (!row.othersComment && !row.othersBorrow) {
      swal({
        title: "Ошибка",
        text: `Введите данные!`,
        icon: "error",
        buttons: "OK",
      });
      return null;
    } else {
      return swal({
        title: "Внимание!",
        text: `Добавить проверьте данные  ${
          "Заметка:" + row.othersComment ?? "0"
        },  ${"Расход:" + row.othersBorrow ?? "0"}!`,
        icon: "warning",
        buttons: {
          cancel: "Нет!",
          catch: {
            text: "Да",
            value: "Ok",
          },
        },
      }).then((willDelete) => {
        if (willDelete === "Ok") {
          setIsLoading(true);
          axios(config)
            .then(function (res) {
              if (res.status === 200) {
                setIsLoading(false);
                setGetOthers(res.data);
              }
            })
            .catch(function (error) {
              swal({
                title: "Ошибка",
                text: `${error}`,
                icon: "error",
                buttons: "OK",
              });
            });

          setEditingKey("");
        }
      });
    }
  };

  if (isLoadin === true) {
    return <Loding />;
  }

  const columns = [
    {
      title: "Дата",
      width: "13%",
      dataIndex: "othersDate",
      editable: true,
    },
    {
      title: "Наименование или причина расхода",
      dataIndex: "othersComment",
      editable: true,
    },
    {
      title: "Сумма",
      dataIndex: "othersBorrow",
      width: "20%",
      editable: true,
    },

    {
      title: <EditOutlined />,
      dataIndex: "operation",
      width: "7%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 35,
                color: "red",
              }}
            >
              <Tooltip title="Сахранить" color={"#008080"}>
                <PlusOutlined />
              </Tooltip>
            </Typography.Link>
            <Popconfirm
              title="Отменить?"
              onConfirm={cancel}
              okText="ДА"
              cancelText="НЕТ"
              placement="left"
            >
              <span
                style={{
                  color: "red",
                }}
              >
                <Tooltip title="Отмена" color={"#008080"}>
                  <MinusOutlined />
                </Tooltip>
              </span>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
            style={{
              color: "red",
            }}
          >
            <Tooltip title="Добавить" color={"#008080"}>
              <EditOutlined />
            </Tooltip>
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "othersComment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={datates}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </>
  );
};
export default PostTableRaznoe;
