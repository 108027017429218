import { createContext } from 'react';

export const ContragetContext = createContext(null);
export const OrdersContext = createContext(null);
export const DiatyContext = createContext(null);
export const MonthContext = createContext(null);
export const NameUser = createContext(null);
export const RenderContext = createContext(null);
export const RenFunction = createContext(null);
export const RenFrameFunction = createContext(null);
export const WorkingRender = createContext(null);
export const PersonalContext = createContext(null);
export const OperationCashContext = createContext(null);
