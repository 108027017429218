import React, { useState, useEffect } from "react";
import axios from "axios";
import { Title, Span, Hr, TableTh } from "../../../Component/Theme/CSSstyles";
import DrowerModal from "../../../Component/ModalComponent/DrowerModal/DrowerModal";
import css from "./WorkPrices.module.css";
import PriceEditTable from "./PriceEdit/PriceEditTable";

const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const WorkPrices = ({ setPriseData }) => {
  // получаем данные из PriceEditTable
  const [priceEdit, setPriceEdit] = useState([]);

  const [getVidOplati, setGetVidOplati] = useState([]);

  const token = localStorage.getItem("DataToken");

  // получаем стоимость и вид оплаты труда
  const VidOplati = () => {
    const data = JSON.stringify({ token: token });
    const config = {
      method: "post",
      url: `${url}/get-ish-turi.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setGetVidOplati(response.data);
          //   отправляем данные в WorkEdit
          setPriseData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    VidOplati();
    // eslint-disable-next-line
  }, [priceEdit]);

  const DataPrice = getVidOplati.map((j) => {
    return (
      <tr key={j.id}>
        <td className={css.WorkerPriceTdata}>
          <Span> {j.name}</Span>
        </td>
        <td className={css.WorkerPriceTdata}>
          <Span>{priceTo(j.norma)}</Span>
        </td>
        <td className={css.WorkerPriceTdata}>
          <Span>{priceTo(j.fee)} сум</Span>
        </td>
        <td className={css.WorkerPriceTdata}>
          {" "}
          <Span>{priceTo(j.maosh)} сум</Span>{" "}
        </td>
      </tr>
    );
  });

  return (
    <DrowerModal name={"Настройки оплаты труда"}>
      <div className={css.WorcPriceHeader}>
        <Title>Ставки</Title>
        <PriceEditTable setPriceEdit={setPriceEdit} />
      </div>
      <Hr />
      <table style={{ width: 800 }}>
        <thead>
          <tr>
            <TableTh>Вид труда</TableTh>
            <TableTh>Норматив</TableTh>
            <TableTh>
              Минималльная <br /> стоимость работы
            </TableTh>
            <TableTh>Минимальная З/П</TableTh>
          </tr>
        </thead>
        <tbody>{DataPrice}</tbody>
      </table>
    </DrowerModal>
  );
};

export default WorkPrices;
