import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Button, DatePicker, Form, Divider } from 'antd';
import { numberFormat } from 'tools/numberFormat';
import { DiaryPrint } from './DiaryData/DiaryPrint';
import css from './Diary.module.css';
import { config } from 'config/configs';
import { MiniTitle, Paragraph, Span } from 'Component/Theme/CSSstyles';
import ModalTop from 'Component/ModalComponent/ModalTop/ModalTop';
import { DiatyContext } from 'Context/context';

const Diary = () => {
  const [fetchInfo, setFetchInfo] = useState([]);
  const [cashFetchInfo, setCashFetchInfo] = useState([]);

  const DiaryClick = (value) => {
    value = {
      ...value,
      sana1: value['sana1'].format('YYYY-MM-DD'),
      sana2: value['sana2'].format('YYYY-MM-DD'),
    };
    value.token = config.TOKEN;
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-interval.php`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(value),
    })
      .then(function (response) {
        if (response.request.status === 200) {
          setFetchInfo([response.data]);
          //  console.log([response.data]);
        } else if (response.request.status !== 200) {
          swal({
            title: 'Внимание!!!',
            text: ' Ошибка соединения с интернетом!',
            icon: 'warning',
            button: 'Ок',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CashInfo = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-balans.php`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ token: config.TOKEN }),
    })
      .then(function (response) {
        if (response.request.status === 200) {
          setCashFetchInfo(response.data);
          //   console.log("get-balans", response.data);
        } else if (response.request.status !== 200) {
          swal({
            title: 'Внимание!!!',
            text: ' Ошибка соединения с интернетом!',
            icon: 'warning',
            button: 'Ок',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    CashInfo();
  }, []);

  const ChexBalans =
    cashFetchInfo.length !== 0
      ? cashFetchInfo?.map((i, index) => {
          return (
            <div key={index.toString()}>
              <Paragraph>
                Бабло в кармане:
                <Span>{numberFormat(i.naqdsum)}</Span>
              </Paragraph>
            </div>
          );
        })
      : null;

  const [placement] = useState('bottomRight');
  return (
    <ModalTop name={'Ежедневник'}>
      <DiatyContext.Provider value={[fetchInfo]}>
        <div className={css.FormBlock}>
          <MiniTitle>Окно ежедневной информации</MiniTitle>
          <Form
            name='time_related_controls'
            onFinish={DiaryClick}
            className={css.Form}
            style={{ marginLeft: 5 }}
          >
            <Form.Item
              name='sana1'
              rules={[
                {
                  required: true,
                  message: 'Выберете дату',
                },
              ]}
            >
              <DatePicker
                placement={placement}
                placeholder='Начальная дата'
                style={{ width: 250 }}
              />
            </Form.Item>

            <Form.Item
              name='sana2'
              rules={[
                {
                  required: true,
                  message: 'Выберете дату',
                },
              ]}
            >
              <DatePicker
                placement={placement}
                style={{ marginLeft: 5, width: 250 }}
                placeholder='Конечная дата'
              />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginLeft: 5, padding: 0, width: 100 }}
              >
                Запрос
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={css.cashFetchInfo}>{ChexBalans}</div>
        <Divider />
        <DiaryPrint />
      </DiatyContext.Provider>
    </ModalTop>
  );
};

export default Diary;
