import React, { useState } from "react";
import { Button, DatePicker, Form } from "antd";
import axios from "axios";
import swal from "sweetalert";
import { MiniTitle } from "../../../../Component/Theme/CSSstyles";
import css from "./FilterTable.module.css";

const url = process.env.REACT_APP_DB_URL;

const FilterDateForm = ({ setFetchInfo }) => {
  const DiaryClick = (value) => {
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    value.token = localStorage.getItem("DataToken");
    var data = JSON.stringify(value);
    var config = {
      method: "post",
      url: `${url}/get-tables-interval.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.request.status === 200) {
          setFetchInfo([response.data]);
        } else if (response.request.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [placement] = useState("bottomRight");

  return (
    <div>
      <div>
        <MiniTitle>Окно ежедневной информации по личным делам</MiniTitle>
        <Form
          name="time_related_controls"
          onFinish={DiaryClick}
          className={css.formFilter}
        >
          <Form.Item
            name="sana1"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker
              placement={placement}
              placeholder="Начальная дата"
              style={{ width: 250 }}
            />
          </Form.Item>

          <Form.Item
            name="sana2"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker
              placement={placement}
              style={{ marginLeft: 5, width: 250 }}
              placeholder="Конечная дата"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100 }}
            >
              Запрос
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FilterDateForm;
