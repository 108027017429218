import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Popconfirm,
  message,
  Typography,
  Modal,
  Button,
  Tag,
  Switch,
} from 'antd';
import { useAuthUser } from 'react-auth-kit';
import { TbWindow } from 'react-icons/tb';
import ClientPrint from './ClientMiniComponent/ClientPrint';
import Loding from '../Loding';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  SwapOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import Master from './Master';
import ClientTableHeader from './ClientTableHeader';
import { numberFormat } from '../../tools/numberFormat';

const { Text } = Typography;
const { Column } = Table;

const confirms = () => {
  message.info(<Text type='danger'>Данные клиента будут удалены?</Text>);
};
const Tekst = 'Точно удалить!?';

function TableNewClient({ loading, filtered, onRemove, serchUrl }) {
  const auth = useAuthUser();
  // ! Данные модального окна
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const closeModal = () => {
    setIsModalVisible(false);
  };
  // ! Данные модального окна

  //TODO Функцыя для отправи данных устаноыщикам и мастерам
  const [isModalGotov, setIsModalGotov] = useState(false);
  const [modalGotov, setModalGotov] = useState(null);
  const closeGotovModal = () => {
    setIsModalGotov(false);
  };
  const FunctionGatov = (record) => {
    setIsModalGotov(true);
    setModalGotov(record);
  };
  //TODO стилизацыя
  const [showTitle, showSetTitle] = useState(false);
  const [showfooter] = useState(true);
  const handleHeaderChange = (enable) => {
    showSetTitle(enable);
  };
  //   const handleFooterChange = (enable) => {
  //     setShowFooter(enable);
  //   };
  const defaultFooter = () => true;
  const defaultTitle = () => <ClientTableHeader filtered={filtered} />;
  const tableProps = {
    Column: showfooter ? defaultFooter : undefined,
    title: showTitle ? defaultTitle : undefined,
  };

  if (loading) {
    return <Loding />;
  } else {
    return (
      <>
        {/* <Switch
          checked={showfooter}
          onChange={handleFooterChange}
          checkedChildren="Скрыть калонки"
          unCheckedChildren="Показать калонки"
        /> */}
        <div style={{ width: '100%', paddingBottom: 10 }}>
          <Switch
            checked={showTitle}
            onChange={handleHeaderChange}
            checkedChildren={<EyeInvisibleOutlined />}
            unCheckedChildren={<EyeOutlined />}
          />
        </div>

        <Table
          bordered
          {...tableProps}
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  height: 'max-content',
                }}
              >
                {record.description}
              </p>
            ),
            // rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          scroll={{
            x: 1500,
          }}
          //  rowKey={(row) => row.key}
          pagination={false}
          dataSource={filtered.filter(
            (i) =>
              i.name?.includes(serchUrl) ||
              i.adres?.includes(serchUrl) ||
              i.yuza?.includes(serchUrl) ||
              i.cash?.includes(serchUrl) ||
              i.username?.includes(serchUrl)
          )}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setActiveRecord(record);
                setIsModalVisible(true);
              },
            };
          }}
        >
          <Column
            title='Пользователь'
            dataIndex='username'
            key='key'
            className={showfooter ? 'clientt__table-key' : 'class__none'}
            // width={150}
          />
          <Column
            title='№'
            dataIndex='key'
            className='clientt__table-key'
            // width={10}
          />
          <Column
            width={150}
            title='З/У'
            dataIndex='date'
            key='date'
            className='client__table-date'
            render={(_, record) => {
              const date1 = record.date;
              const date2 = record.installationDate;
              return (
                <>
                  <div>{date1}</div>
                  <div>{date2}</div>
                </>
              );
            }}
          />
          <Column
            width={300}
            title='Заказчик'
            dataIndex='name'
            key='name'
            render={(_, record) => {
              return <Link to={`${record.key}`}> {record.name} </Link>;
            }}
          />
          <Column
            title='Номер'
            dataIndex='phone'
            key='phone'
            width={220}
            className='client__table-date'
            sorter={(a, b) => a.name.length - b.name.length}
          />
          <Column
            width={250}
            // align='center'
            title='Адрес'
            dataIndex='adres'
            key='adres'
            className='client__table-date'
          />
          <Column
            // align="center"
            title={
              <>
                М
                <sup>
                  <small>2</small>
                </sup>
              </>
            }
            dataIndex='yuza'
            key='yuza'
            width={100}
            sorter={(a, b) => a.yuza - b.yuza}
          />
          <Column
            // align='center'
            title='Сумма заказа'
            dataIndex='cash'
            key='cash'
            width={220}
            className='cash__column-color'
            render={(_, record) => {
              const Cash = record.cash;
              return numberFormat(Cash);
            }}
          />
          <Column
            width={220}
            // align='center'
            title='Предоплата'
            dataIndex='raschot'
            className='raschot__column-color'
            key='raschot'
            render={(_, record) => {
              const Raschot = record.raschot;
              return numberFormat(Raschot);
            }}
          />
          <Column
            width={220}
            title='Остаток'
            dataIndex='ostatok'
            className='ostatok__column-color'
            key='ostatok'
            render={(_, record) => {
              record.ostatok = record.cash - record.raschot;
              return numberFormat(record.ostatok);
            }}
          />
          <Column
            width={30}
            align='center'
            dataIndex={'holati'}
            title={'Статус сборки'}
            className='status__column-color'
            render={(_, record) => {
              let text;
              let color;
              if (record.holati === 'progres') {
                color = '#d41717';
              } else {
                color = '#3ac957';
              }
              if (record.holati === 'progres') {
                text = 'Не передан';
              } else {
                text = 'Передан';
              }
              return (
                <>
                  <Tag color={color} key={record.holati}>
                    {text}
                  </Tag>
                </>
              );
            }}
          />
          <Column
            width={30}
            align='center'
            dataIndex={'holati'}
            title={'Статус установки'}
            className='status__column-color'
            render={(_, record) => {
              let color;
              let text;
              if (record.holati2 === 'progres') {
                color = '#d41717';
              } else {
                color = '#3ac957';
              }
              if (record.holati2 === 'progres') {
                text = 'Не передан';
              } else {
                text = 'Передан';
              }
              return (
                <>
                  <Tag color={color} key={record.holati2}>
                    {text}
                  </Tag>
                </>
              );
            }}
          />

          <Column
            align='center'
            width={100}
            title={<SwapOutlined />}
            key='delete'
            render={(_, record) => (
              <>
                {auth().id === process.env.REACT_APP_ADMIN_KEY ? (
                  <Popconfirm
                    placement='left'
                    title={Tekst}
                    okText={<CheckCircleOutlined />}
                    cancelText={<CloseCircleOutlined />}
                    onConfirm={() => onRemove(record.key)}
                  >
                    <Button
                      type='primary'
                      danger
                      shape='circle'
                      onClick={confirms}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                ) : null}

                <Button
                  type='primary'
                  shape='circle'
                  icon={<TbWindow style={{ fontSize: 20 }} />}
                  onClick={() => FunctionGatov(record)}
                />
              </>
            )}
          />
        </Table>
        <Modal
          title='Данные Клиента'
          open={isModalVisible}
          onCancel={closeModal}
          footer={null}
          width={'80vw'}
        >
          <ClientPrint activeRecord={activeRecord} />
        </Modal>
        <Modal
          title='Передать объём заказа мастерам'
          open={isModalGotov}
          onCancel={closeGotovModal}
          footer={null}
          width={'80vw'}
        >
          <Master modalGotov={modalGotov} />
        </Modal>
      </>
    );
  }
}

export default TableNewClient;
