import React, { useRef } from "react";
import "antd/dist/antd.min.css";
import { Form } from "antd";
import axios from "axios";
import CSS from "../ProductCommTable/ProductComm.module.css";
import ModalCenter from "../../../../Component/ModalComponent/ModalMiddle/ModalCenter";
import { MiniTitle } from "../../../../Component/Theme/CSSstyles";

const url = process.env.REACT_APP_DB_URL;

function ProductComm({ id, setGetClientComm }) {
  const MyRef = useRef();
  const token = localStorage.getItem("DataToken");

  const onFinish = (e) => {
    let refComment = MyRef.current.value;

    const data = JSON.stringify({
      id: id,
      comment: refComment,
      token: token,
    });

    const config = {
      method: "POST",
      url: `${url}/add-comment.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setGetClientComm(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    MyRef.current.value = "";
  };

  return (
    <ModalCenter name="Коментари к товару">
      <MiniTitle>Добавить коментарии</MiniTitle>
      <div>
        <Form onFinish={onFinish} className={CSS.ClientProductForm}>
          <textarea
            type="number"
            className={CSS.ClientProductTextarea}
            ref={MyRef}
            name="avansplus"
            required
            rows={10}
            cols={10}
          />
          <div className={CSS.ClientProduct__btn}>
            <button className={CSS.ClientProductButton}>Добавить</button>
          </div>
        </Form>
      </div>
    </ModalCenter>
  );
}

export default ProductComm;
