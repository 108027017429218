import {
  SingleTableTd,
  SingleTableTh,
  SingleTRow,
} from "Component/Theme/CSSstyles";
import React from "react";
import css from "./OrderTable.module.css";

export default function OrderClientInfo({ order }) {
  const orders2 = order?.map((i) => {
    return (
      <tbody key={i.id}>
        <SingleTRow>
          <SingleTableTd>Клиент</SingleTableTd>
          <SingleTableTd>{i.name}</SingleTableTd>
        </SingleTRow>
        <SingleTRow>
          <SingleTableTd style={{ wordBreak: "break-word" }}>
            Адрес проживания
          </SingleTableTd>
          <SingleTableTd>{i.adres}</SingleTableTd>
        </SingleTRow>
        <SingleTRow>
          <SingleTableTd>Контактный номер (тел.)</SingleTableTd>
          <SingleTableTd>{i.tel}</SingleTableTd>
        </SingleTRow>
      </tbody>
    );
  });
  return (
    <div>
      <table className={css.orderTableSingle}>
        <thead>
          <SingleTRow>
            <SingleTableTh colSpan={2}>Данные клиента</SingleTableTh>
          </SingleTRow>
        </thead>
        {orders2}
      </table>
    </div>
  );
}
