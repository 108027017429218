import React, { useState } from "react";
import { Button, DatePicker, Form } from "antd";
import axios from "axios";
import swal from "sweetalert";
import { MiniTitle } from "../../../Component/Theme/CSSstyles";
import css from "./RasxodInterval.module.css";

const url = process.env.REACT_APP_DB_URL;

const RasxodIntervalForm = ({ setRasxodInterval }) => {
  const IntervalData = (value) => {
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    value.token = localStorage.getItem("DataToken");
    var data = JSON.stringify(value);
    var config = {
      method: "post",
      url: `${url}/get-rasxod-interval.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //  get-tables-interval.php
    axios(config)
      .then(function (res) {
        if (res.status === 200) {
          setRasxodInterval(res.data);
          console.log("Rasxod interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [placement] = useState("bottomRight");

  return (
    <section className={css.IntervalFormBlock}>
      <MiniTitle>Интервал расходов по заданным датам...</MiniTitle>
      <Form name="time_related_controls" onFinish={IntervalData}>
        <div className={css.IntervalForm}>
          <Form.Item
            name="sana1"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker
              placement={placement}
              placeholder="Начальная дата"
              style={{ width: 250 }}
            />
          </Form.Item>

          <Form.Item
            name="sana2"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker
              placement={placement}
              style={{ marginLeft: 5, width: 250 }}
              placeholder="Конечная дата"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100 }}
            >
              Запрос
            </Button>
          </Form.Item>
        </div>
      </Form>
    </section>
  );
};

export default RasxodIntervalForm;
