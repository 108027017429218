import React, { useState } from "react";
// import Loding from "../../Loding";
import {
  TableTh,
  TableTd,
  Title,
  Spans,
} from "../../../Component/Theme/CSSstyles";
import ModalTop from "../../../Component/ModalComponent/ModalTop/ModalTop";
import axios from "axios";
import CSS from "../Header.module.css";
import { Button, DatePicker, Form } from "antd";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const OborotTable = () => {
  //
  const [isOborot, setIsOborot] = useState([]);
  const onFinish = (value) => {
    value = {
      ...value,
      date: value["date"].format("YYYY-MM-DD"),
      date2: value["date2"].format("YYYY-MM-DD"),
    };
    value.token = localStorage.getItem("DataToken");

    const data = JSON.stringify(value);
    const config = {
      method: "POST",
      url: `${url}/get-hisobot.php`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setIsOborot([response.data]);
        console.log(response.data);
      })
      .catch((err) => {
        console.log("GetInfoErr > > > >", err);
      });
  };

  const [placement] = useState("bottomRight");

  const TableOborot = isOborot.map((i) => {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <TableTh colSpan={2}>Приход-расход контрагентов</TableTh>

              <TableTh colSpan={2}>Квадрат</TableTh>
              <TableTh>Сумма выполненые заказы</TableTh>
              <TableTh>Сумма выданых зарплат</TableTh>
              <TableTh>Сумма расходов</TableTh>
              <TableTh> Прибыль по заданному диапазону</TableTh>
            </tr>
            <tr>
              <TableTh>Получено товаров на сумму:</TableTh>
              <TableTh>Оплачено товаров на сумму:</TableTh>
              <TableTh>Объём принятого заказа</TableTh>
              <TableTh>Объём готового заказа</TableTh>
              <TableTh>Оплачено товаров на сумму:</TableTh>
              <TableTh>Сумма</TableTh>
              <TableTh>Сумма</TableTh>
              <TableTh>Сумма</TableTh>
            </tr>
          </thead>
          <tbody>
            <tr key={i.id}>
              <TableTd className="OborotTAble">{priceTo(i.bazaprixod)}</TableTd>
              <TableTd className="OborotTAble">{priceTo(i.bazarasxod)}</TableTd>
              <TableTd className="OborotTAble">
                {Math.ceil(i.umumiy_yuza)}
              </TableTd>
              <TableTd className="OborotTAble">
                {Math.ceil(i.bajarilgan_yuza)}
              </TableTd>
              <TableTd className="OborotTAble">{priceTo(i.zakaz)}</TableTd>
              <TableTd className="OborotTAble">{priceTo(i.maosh)}</TableTd>
              <TableTd className="OborotTAble">{priceTo(i.harajat)}</TableTd>
              <TableTd className="OborotTableFoyda">
                {priceTo(i.daromad)}
              </TableTd>
            </tr>
          </tbody>
        </table>
      </div>
    );
  });

  return (
    <ModalTop name="Оборот">
      <div className={CSS.OborotHeader}>
        <Title>
          Оборотные Данные <Spans>(ежемесячный или квартальный)</Spans>
        </Title>
        <Form
          name="time_related_controls"
          onFinish={onFinish}
          className={CSS.FormOborotForm}
          style={{ marginLeft: 5 }}
        >
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker placement={placement} placeholder="Начальная" />
          </Form.Item>

          <Form.Item
            name="date2"
            rules={[
              {
                required: true,
                message: "Выберете дату",
              },
            ]}
          >
            <DatePicker
              placement={placement}
              style={{ marginLeft: 5 }}
              placeholder="Конечная"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100 }}
            >
              Запрос
            </Button>
          </Form.Item>
        </Form>
      </div>
      {TableOborot}
    </ModalTop>
  );
};

export default OborotTable;
