import {
	MINUS_CASH_ORDER,
	AVANS_CASH_ORDER,
	LOADER_ORDER,
	FETCH_AVANS_ORDER,
	REMOVE_AVANS_ORDER,
} from "../../../typs/orderTypes";

const handlers = {
	[LOADER_ORDER]: (state) => ({ ...state, loading: true }),
	[FETCH_AVANS_ORDER]: (state, { data }) => ({
		...state,
		data: data,
		loading: false,
	}),
	[REMOVE_AVANS_ORDER]: (state, { key }) => ({
		...state,
		data: state.data.filter((i) => i.tr !== key),
	}),
	[AVANS_CASH_ORDER]: (state, { data }) => ({
		...state,
		data: data,
	}),
	[MINUS_CASH_ORDER]: (state) => ({
		...state,
		order: [...state.order],
	}),
	DEFAULT: (state) => state,
};
export const OrderCashReduser = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	//   console.log(state, action);
	return handle(state, action);
};
