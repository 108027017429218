import React from "react";
import { Pie } from "@ant-design/plots";

const DemoPie = ({ cash, ras }) => {
  const ost = Number(cash) - Number(ras);
  const data = [
    //  {
    //    sex: "Общая сумма",
    //    sold: Number(cash),
    //  },
    {
      sex: "Сумма расcчёта",
      sold: Number(ras),
    },
    {
      sex: "Остаток",
      sold: Number(ost),
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "sold",
    colorField: "sex",
    radius: 0.8,
    //  tooltip: {
    //    customContent: (sex, data) => {
    //      return `<div>${sex}</div>`;
    //    },
    //  },
    //  tooltip: {
    //    formatter: (datum: Datum) => {
    //      return { name: datum.x, value: datum.y + "%" };
    //    },
    //  },
    //  tooltip: {
    //    customItems: (originalItems: data) => {
    //      // process originalItems,
    //      return originalItems;
    //    },
    //  },
    legend: {
      layout: "vertical",
      position: "right",
    },
    statistic: {
      type: "inner",
      offset: "-50%",
      style: {
        fontSize: 120,
        fontWeight: 300,
        textAlign: "center",
        textBaseline: "middle",
        shadowColor: "white",
        shadowBlur: 10,
      },
    },
    areaStyle: {},
    pieStyle: ({ sex }) => {
      if (sex === "Сумма расcчёта") {
        return {
          fill: "#008080",
        };
      } else if (sex === "Общая сумма") {
        return {
          stroke: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        };
      }

      return {
        fill: "red",
      };
    },
    //  tooltip: false,
    interactions: [
      {
        type: "element-single-selected",
      },
    ],
  };
  return <Pie {...config} />;
};
export default DemoPie;
