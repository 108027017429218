import React, { useState, useEffect } from "react";
import DrowerModal from "../../../Component/ModalComponent/DrowerModal/DrowerModal";
import { BsCurrencyExchange } from "react-icons/bs";
import { Title } from "../../../Component/Theme/CSSstyles";
import "./CurrencyMadal.css";

const CurrencyModal = () => {
  const [fetchData, setFetchData] = useState([]);
  const FetchData = () => {
  fetch("https://cbu.uz/ru/arkhiv-kursov-valyut/json/")
      .then((response) => response.json())
      .then((json) => setFetchData(json));
  };
  useEffect(() => {
    FetchData();
  }, []);
  return (
    <DrowerModal name={<BsCurrencyExchange style={{ fontSize: 18 }} />}>
      <Title className="currency-madal-h3">
        Курс валют на {new Date().toLocaleDateString()} год.
      </Title>
      <table style={{ width: "100%" }}>
        <tbody>
          {fetchData.map((i) => (
            <tr key={i.id} style={{ padding: "10px" }}>
              <td className="corrensy-table-name">{i.CcyNm_RU}:</td>
              <td className="corrensy-table-many">{i.Rate}</td>
              <td className="corrensy-table-many"> {i.Ccy}</td>
              <td>
                Разница:{" "}
                <span className="corrensy-table-many-span">{i.Diff}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DrowerModal>
  );
};

export default CurrencyModal;
