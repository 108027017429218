import React from "react";
import ReactDOM from "react-dom/client";
import { RequireAuth } from "react-auth-kit";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import "moment/locale/ru";
import ruRU from "antd/es/locale/ru_RU";
import "antd/dist/antd.variable.min.css";
import { AuthProvider } from "react-auth-kit";
import refreshApi from "./tools/checkToken";
import { Authorization } from "./page/Authorization";
import { ContragentState } from "./Context/Contragent/ContragentState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={ruRU}>
    <ContragentState>
      <AuthProvider
        authType="localstorage"
        authName="_auth"
        refresh={refreshApi}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <RequireAuth loginPath={"/login"}>
                  <App />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Authorization />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ContragentState>
  </ConfigProvider>
);

// REACT_APP_DB_URL = https://global-notepad.uz/hasanov
// REACT_APP_DB_URL = http://azamatakfaphp/
