// import React, { useEffect } from "react";
import axios from "axios";
// import swal from "sweetalert";
import { createRefresh } from "react-auth-kit";

const url = process.env.REACT_APP_DB_URL;

// const MyComponent = () => {
// 	const LogEffect = () => {
// 		const token = localStorage.getItem("DataToken");
//
// 		if (token !== null) {
// 			let data = JSON.stringify({
// 				token: token,
// 			});
//
// 			const config = {
// 				method: "post",
// 				url: `${url}/checktoken.php`,
// 				headers: {
// 					"Content-Type": "text/plain",
// 				},
// 				data: data,
// 			};
//
// 			axios(config)
// 			.then(function (response) {
// 				if (response.data.error === 0) {
// 					localStorage.setItem("DataToken", response.data.token);
// 					setLogIns(true);
// 					// history("/workinfo");
// 					history("/");
// 					swal({
// 						title: "Смена востановлена",
// 						text: "Можете продолжить работать",
// 						icon: "success",
// 						button: "Ок",
// 					});
// 				}
// 			})
// 			.catch(function (error) {
// 				if (error.code === "ERR_NETWORK") {
// 					swal({
// 						title: "O_o!",
// 						text: "Проверьте подключение к интернету",
// 						icon: "warning",
// 						timer: 2000,
// 					});
// 				}
// 				history("/login");
// 			});
// 		}
// 	};
//
// 	useEffect(() => {
// 		LogEffect();
// 	});
// 	return (
// 		<div>
//
// 		</div>
// 	);
// };

// export default MyComponent;

const checkToken = createRefresh({
  interval: 8000, // Refreshs the token in every 10 minutes
  refreshApiCallback: async ({ authToken, authUserState }) => {
    try {
      const response = await axios.post(
        `${url}/checktoken.php`,
        { token: authToken },
        {
          headers: { "Content-Type": "text/plain" },
        }
      );
      return {
        isSuccess: true,
        newAuthToken: response.data.token,
        newAuthTokenExpireIn: response.data.tokentime_string,
        authState: {
          id: response.data.id,
          fio: response.data.fio,
          token: response.data.token,
        },
        // newRefreshTokenExpiresIn: 60
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});

export default checkToken;

// {   // arguments
// 	authToken,
// 		authTokenExpireAt,
// 		refreshToken,
// 		refreshTokenExpiresAt,
// 		authUserState
// }
