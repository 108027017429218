import React, { useRef, useState } from "react";
import ModalTop from "../../Component/ModalComponent/ModalTop/ModalTop";
import ReactToPrint from "react-to-print";
import ContragentGetInterval from "./ContragentGetInterval";
import CSS from "./Contragent.module.css";
import { MiniTitle } from "../../Component/Theme/CSSstyles";
import ContragentIntervalTable from "./ContragentIntervalTable/ContragentIntervalTable";

const ContragentInterval = ({ pageName, pageId }) => {
  const [intevalContr, setIntervalContr] = useState([]);
  const componentRef = useRef();
  return (
    <ModalTop name="Интервал данных">
      <MiniTitle>Интервал данных по данному конртагенту: {pageName}</MiniTitle>
      <div className={CSS.HeaderInterval}>
        <ReactToPrint
          trigger={() => (
            <button className="exit__modal-card-btn">Печатать</button>
          )}
          content={() => componentRef.current}
        />
        <ContragentGetInterval
          pageId={pageId}
          setIntervalContr={setIntervalContr}
        />
      </div>
      <div ref={componentRef}>
        <ContragentIntervalTable intevalContr={intevalContr} />
      </div>
    </ModalTop>
  );
};

export default ContragentInterval;
