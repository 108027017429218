import React, { useRef, useContext, useState } from 'react';
import axios from 'axios';
import style from './DataTable.module.css';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import ModalCenter from '../../../Component/ModalComponent/ModalMiddle/ModalCenter';
import { RenFunction } from 'Context/context';
import { config } from 'config/configs';

const DimakTable = ({ setAddNew }) => {
  const RenData = useContext(RenFunction);
  const [cheng, setChang] = useState('sum');
  const InpRef = useRef();
  const NumRef = useRef();
  const SelectRef = useRef();

  function AddTitle() {
    const token = localStorage.getItem('DataToken');
    const RefValue = InpRef.current.value;
    const RefNum = NumRef.current.state.numAsString;
    const RefSel = SelectRef.current.value;

    axios({
      method: 'post',
      url: `${config.DB_URL}/add-table.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        token: token,
        debt: RefNum,
        valyuta: cheng,
        title: RefValue,
        oldi_berdi: RefSel,
      }),
    })
      .then(function (response) {
        if (response.data.error === 1) {
          swal({
            title: 'Ошибка',
            text: 'Ошибка с соединением!',
            icon: 'error',
            button: 'Ок',
          });
        } else if (response.data.error !== 1) {
          setAddNew(response.data);
          RenData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <ModalCenter name={'Добавить новую карту'}>
      <div className={style.BlocfFormAdd}>
        <input
          type='text'
          ref={InpRef}
          placeholder='Введите название новой таблицы.'
          className={style.NameTable}
        />
        <NumberFormat
          ref={NumRef}
          required
          thousandSeparator={true}
          autoComplete='off'
          className={style.DebtTable}
          placeholder='Сумма покупки или займа.'
        />
        <label htmlFor='select'>Вибирите тип операции</label>
        <select id='select' className={style.DebtTable} ref={SelectRef}>
          <option value='berdi'>Кредит</option>
          <option value='oldi'>Дебит</option>
        </select>

        <label htmlFor='check' className={style.DebtCheckLabel}>
          Устанавите флажок если счет таблицы USD
          <input
            id='check'
            className={style.DebtCheck}
            type='checkbox'
            defaultValue={'sum'}
            onChange={(event) => setChang(event.target.checked ? 'usd' : 'sum')}
          />
        </label>

        <button onClick={AddTitle} className={style.AddTable}>
          Создать таблицу
        </button>
      </div>
    </ModalCenter>
  );
};

export default DimakTable;
