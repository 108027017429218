import React, { useContext } from 'react';
import ModalCenter from '../../../Component/ModalComponent/ModalMiddle/ModalCenter';
import { Button, Form, Select, Switch, Input, Divider } from 'antd';
import { Spans, CardSpan } from '../../../Component/Theme/CSSstyles';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import css from './EditCard.module.css';
import { RenFunction, RenderContext } from 'Context/context';
import { config } from 'config/configs';

const { Option } = Select;

const EditCard = () => {
  const ContextDate = useContext(RenderContext);
  const Render = useContext(RenFunction);

  const onFinish = (values) => {
    values.token = localStorage.getItem('DataToken');

    axios({
      method: 'post',
      url: `${config.DB_URL}/update-table.php`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(values),
    })
      .then((response) => {
        //   console.log("Data", response.data);
        Render();
      })
      .catch(function (error) {
        console.logdata(error);
      });
  };

  return (
    <ModalCenter name='Изменения данных'>
      <CardSpan>Переименование или изменения данных!</CardSpan>
      <Divider />
      <div className={css.BlockEditCard}>
        <Form
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
          colon={false}
        >
          <Form.Item
            name='switch'
            label={<Spans>- или + </Spans>}
            valuePropName='checked'
            initialValue={false}
          >
            <Switch style={{ margin: 10 }} />
          </Form.Item>

          {/* <Form.Item
            name={"switch"}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox className={css.UchotClick} />
          </Form.Item> */}

          <Form.Item
            label={<Spans>Выберите название таблицы!?</Spans>}
            name='table_id'
            rules={[
              {
                required: true,
                message: 'Выберите не название таблицы!?',
              },
            ]}
          >
            <Select placeholder='Выбор' style={{ fontSize: 20 }}>
              {ContextDate.map((i, index) => (
                <Option
                  value={i.id}
                  key={index.toString()}
                  style={{ fontSize: 20 }}
                >
                  {i.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={<Spans>Новое название карточки</Spans>}
            name='new_title'
          >
            <Input style={{ fontSize: 20 }} placeholder='Введите название' />
          </Form.Item>

          <Form.Item label={<Spans>Сумма</Spans>} name='new_cash'>
            <NumberFormat
              thousandSeparator={true}
              customInput={Input}
              autoComplete='off'
              placeholder='Введите сумму'
              style={{ fontSize: 20 }}
            />
          </Form.Item>
          <Form.Item label={<Spans>Заметка</Spans>} name='note'>
            <Input.TextArea
              placeholder='Введите заметку'
              style={{ fontSize: 20 }}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 16,
            }}
          >
            <Button type='primary' danger htmlType='submit'>
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </div>
    </ModalCenter>
  );
};

export default EditCard;
