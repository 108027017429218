import React, { useState } from "react";
import { Column } from "@ant-design/plots";

export const DemoColumn = () => {
  const [data] = useState([
    { city: "Январь", type: "Сумма заказа", value: 14500 },
    { city: "Январь", type: "Оплачено", value: 8500 },
    { city: "Январь", type: "Расход", value: 10000 },
    { city: "Январь", type: "Прибыль", value: 7000 },
    { city: "Февраль", type: "Сумма заказа", value: 9000 },
    { city: "Февраль", type: "Оплачено", value: 8500 },
    { city: "Февраль", type: "Расход", value: 11000 },
    { city: "Февраль", type: "Прибыль", value: 6000 },
    { city: "Март", type: "Сумма заказа", value: 16000 },
    { city: "Март", type: "Оплачено", value: 5000 },
    { city: "Март", type: "Расход", value: 6000 },
    { city: "Март", type: "Прибыль", value: 10000 },
    { city: "Апрель", type: "Сумма заказа", value: 14000 },
    { city: "Апрель", type: "Оплачено", value: 9000 },
    { city: "Апрель", type: "Расход", value: 10000 },
    { city: "Апрель", type: "Прибыль", value: 9000 },
    { city: "Май", type: "Сумма заказа", value: 14000 },
    { city: "Май", type: "Оплачено", value: 9000 },
    { city: "Май", type: "Расход", value: 10000 },
    { city: "Май", type: "Прибыль", value: 6000 },
    { city: "Июнь", type: "Сумма заказа", value: 9000 },
    { city: "Июнь", type: "Оплачено", value: 8500 },
    { city: "Июнь", type: "Расход", value: 10000 },
    { city: "Июнь", type: "Прибыль", value: 6000 },
    { city: "Июль", type: "Сумма заказа", value: 17000 },
    { city: "Июль", type: "Оплачено", value: 6000 },
    { city: "Июль", type: "Расход", value: 7000 },
    { city: "Июль", type: "Прибыль", value: 10000 },
    { city: "Август", type: "Сумма заказа", value: 18000 },
    { city: "Август", type: "Оплачено", value: 11000 },
    { city: "Август", type: "Расход", value: 15000 },
    { city: "Август", type: "Прибыль", value: 14000 },
    { city: "Сентябрь", type: "Сумма заказа", value: 18000 },
    { city: "Сентябрь", type: "Оплачено", value: 11000 },
    { city: "Сентябрь", type: "Расход", value: 15000 },
    { city: "Сентябрь", type: "Прибыль", value: 14000 },
    { city: "Октябрь", type: "Сумма заказа", value: 18000 },
    { city: "Октябрь", type: "Оплачено", value: 11000 },
    { city: "Октябрь", type: "Расход", value: 15000 },
    { city: "Октябрь", type: "Прибыль", value: 14000 },
    { city: "Ноябрь", type: "Сумма заказа", value: 18000 },
    { city: "Ноябрь", type: "Оплачено", value: 11000 },
    { city: "Ноябрь", type: "Расход", value: 15000 },
    { city: "Ноябрь", type: "Прибыль", value: 14000 },
    { city: "Декабрь", type: "Сумма заказа", value: 18000 },
    { city: "Декабрь", type: "Оплачено", value: 11000 },
    { city: "Декабрь", type: "Расход", value: 15000 },
    { city: "Декабрь", type: "Прибыль", value: 14000 },
  ]);

  const config = {
    data,
    xField: "city",
    yField: "value",
    seriesField: "type",
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
      color: ["#008080", "#008080", "#008080", "#008080"],
    },
  };

  return <Column {...config} />;
};
