import { TableTh, TRow } from "Component/Theme/CSSstyles";
import React from "react";
import css from "../OrdersTable/OrderTable.module.css";
const priceTo = (price) => {
  if (!price) {
    return null;
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export default function OrdersPayment({ payment, onRemoveAvans }) {
  //   avans: "1000000";
  //   comment: "";
  //   order_id: "180";
  //   sana: "04-04-2023";
  //   tr: "341";
  //   username: "Xasanov Javohir";

  const dataPayment = payment?.map((i, index) => (
    <TRow key={i.tr}>
      <td>{index + 1}</td>
      <td>{i.sana}</td>
      <td>{priceTo(i.avans)}</td>
      <td>{i.comment}</td>
      <td>{i.username}</td>
      <td>
        <button onClick={() => onRemoveAvans(i.tr)}>Удалить</button>
      </td>
    </TRow>
  ));
  return (
    <div>
      <table className={css.orderTable}>
        <caption style={{ captionSide: "top" }}>Оплата заказа</caption>
        <thead>
          <TRow>
            <TableTh>№</TableTh>
            <TableTh>Дата</TableTh>
            <TableTh>Сумма</TableTh>
            <TableTh>Заметка</TableTh>
            <TableTh>Пользователь</TableTh>
            <TableTh>Удалить</TableTh>
          </TRow>
        </thead>
        <tbody>{dataPayment}</tbody>
      </table>
    </div>
  );
}
