import React, { useState, useEffect } from 'react';
import { Table, Popconfirm } from 'antd';
import axios from 'axios';
import Loding from '../Loding';
import style from '../PageComponent.module.css';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

import { Span, Main, Paragraph } from '../../Component/Theme/CSSstyles';
import { numberFormat } from 'tools/numberFormat';

const url = process.env.REACT_APP_DB_URL;

const TablePokupkaRaznoe = ({ getOthers }) => {
  const UserId = localStorage.getItem('DataID');
  const Others = getOthers;
  const [getState, setGetState] = useState(Others);
  const [isLoding, setIsLoading] = useState(false);

  const GetRequestOthers = async () => {
    const token = localStorage.getItem('DataToken');
    const data = JSON.stringify({ token: token });

    const config = {
      method: 'post',
      url: `${url}/get-rasxod.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setGetState(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetRequestOthers();
  }, [getOthers]);

  const handleDelete = (id) => {
    const token = localStorage.getItem('DataToken');
    const data = JSON.stringify({ token: token, id: id });

    const config = {
      method: 'post',
      url: `${url}/delete-rasxod.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        GetRequestOthers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const columns = [
    {
      title: 'Пользователь',
      dataIndex: 'username',
      width: '7%',
      className: 'UsersFontSize',
    },
    {
      title: 'Дата',
      dataIndex: 'othersDate',
      width: '13%',
    },
    {
      title: 'Наименование расхода',
      dataIndex: 'othersComment',
      className: 'OtherComment',
    },
    {
      title: 'Расходная сумма',
      dataIndex: 'othersBorrow',
      className: 'rasxod__column-color',
      width: '20%',
      render: (_, record) => {
        return numberFormat(record.othersBorrow);
      },
    },
    {
      title: () => <DeleteOutlined />,
      width: '5%',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) =>
        UserId === '1' ? (
          <Popconfirm
            title='Точно удалить?'
            placement='left'
            okText='ДА'
            cancelText='НЕТ'
            onConfirm={() => handleDelete(record.id)}
          >
            <CloseOutlined style={{ color: 'red' }} />
          </Popconfirm>
        ) : null,
    },
  ];
  if (isLoding === false) return <Loding />;
  if (getState.length === 0) return <Loding />;
  return (
    <>
      <Table
        rowKey={(row) => row.id}
        columns={columns}
        dataSource={getState}
        pagination={false}
        title={(dataSource) => {
          let totalBorrow = 0;
          dataSource.forEach(({ othersBorrow }) => {
            totalBorrow += Number(othersBorrow);
          });

          return (
            <Main>
              <Paragraph>
                Cумма расходов:
                <Span className={style.TableSumaryData}>
                  {totalBorrow.toLocaleString()}
                </Span>
              </Paragraph>
            </Main>
          );
        }}
      />
    </>
  );
};

export default TablePokupkaRaznoe;
