import React, { useRef, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';
import css from './CashDataTable.module.css';
import { WorkingRender } from 'Context/context';
import { config } from 'config/configs';
import Loding from 'PageComponent/Loding';
import { TableTd, TableTh } from 'Component/Theme/CSSstyles';
import { numberFormat } from 'tools/numberFormat';

const CashDataTable = ({ id, infoCash, setRenderEyes }) => {
  const FetchWorkData = useContext(WorkingRender);
  const componentRef = useRef();
  const token = localStorage.getItem('DataToken');

  const [dataAvans, setDataAvans] = useState(infoCash);

  // получаем данные аванса
  const FetchAvans = () => {
    axios({
      method: 'post',
      url: `${config.DB_URL}/get-salary-payments.php`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        worker_id: id,
        token: token,
      }),
    })
      .then(function (response) {
        if (response.status === 200) {
          setDataAvans(response.data);
          setRenderEyes(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchAvans();
    // eslint-disable-next-line
  }, [infoCash, id]);

  // удаляем аванс
  const Remove = async (item) => {
    swal({
      title: 'Внимание!',
      text: `Вы уверены что хотите удалить?!`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        axios({
          method: 'post',
          url: `${config.DB_URL}/delete-avans.php`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: JSON.stringify({
            id: item,
            token: token,
          }),
        })
          .then(function (response) {})
          .catch(function (error) {
            console.log(error);
          });
        FetchAvans();
        FetchWorkData();
      }
    });
  };

  if (dataAvans.length === 0) {
    return (
      <div className={css.CashDataLodain}>
        <Loding />
      </div>
    );
  }
  const HistoryCash = dataAvans.map((i) =>
    i.worker_id ? (
      <tr key={i.id}>
        <TableTd>{i.id}</TableTd>
        {/* <TableTd>{i.worker_id}</TableTd> */}
        <TableTd>{i.date}</TableTd>
        <TableTd style={{ textAlign: 'left' }}>{i.comment}</TableTd>
        <TableTd>{numberFormat(i.cash)}</TableTd>
        <TableTd>
          <span style={{ cursor: 'pointer' }} onClick={() => Remove(i.id)}>
            &times;
          </span>
        </TableTd>
      </tr>
    ) : null
  );

  return (
    <>
      <div className={css.PrintCashTable}>
        <ReactToPrint
          trigger={() => (
            <button className='exit__modal-card-btn'>Печатать</button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef} className={css.CashTableScroll}>
        <table style={{ width: '800px' }}>
          <thead>
            <tr>
              <TableTh style={{ width: '40px' }}>ID</TableTh>
              {/* <TableTh style={{ width: "40px" }}>Worker ID</TableTh> */}
              <TableTh style={{ width: '100px' }}>Дата</TableTh>
              <TableTh>Заметки</TableTh>
              <TableTh style={{ width: '120px' }}>
                Выданные <br /> суммы
              </TableTh>
              <TableTh style={{ width: '60px' }}>&times;</TableTh>
            </tr>
          </thead>
          <tbody>{HistoryCash}</tbody>
        </table>
      </div>
    </>
  );
};

export default CashDataTable;
