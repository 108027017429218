import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { config } from "config/configs";
import { PersonalContext } from "Context/context";



export default function StateDataPersonal({ children }) {

	const [workData, setWorkData] = useState([]);

	// Запрос для таблицы рабочих
	const FetchWorkData = () => {
		axios({
			method: "post",
			url: `${config.DB_URL}/get-workers.php`,
			headers: {
				"Content-Type": "text/plain",
			},
			data: JSON.stringify({ token: config.TOKEN }),
		}).then(function (res) {
			if (res.status === 200) {
				setWorkData(res.data);
				//  console.log(res.data);
			}
		}).catch(function (error) {
			console.log(error);
		});
	};

	// удаление рабочего
	const FetchDelete = (i) => {
		swal({
			title: "Внимание, точно удалить???",
			text: `Все данные рабочего будут удалены!`,
			icon: "warning",
			buttons: {
				cancel: "Нет!",
				catch: {
					text: "Да",
					value: "Ok",
				},
			},
		}).then((willDelete) => {
			if (willDelete === "Ok") {
				try {
					axios({
						method: "post",
						url: `${config.DB_URL}/delete-worker.php`,
						headers: {
							"Content-Type": "text/plain",
						},
						data: JSON.stringify({
							token: config.TOKEN,
							worker_id: i,
						}),
					}).then((res) => {
						if (0 === res.data.error) {
							swal({
								title: `${res.data.xabar}`,
								icon: "success",
							});
							FetchWorkData();
						} else if (1 === res.data.error) {
							swal({
								title: `${res.data.xabar}`,
								icon: "warning",
							});
						}
					}).catch((err) => {
						console.log(err);
					})
				} catch (error) {
					console.log(error);
					if (error.code === "ERR_NETWORK") {
						swal({
							title: "O_o!",
							text: "Проверьте подключение к интернету",
							icon: "warning",
							timer: 2000,
						});
					}
				}
				// throw new SyntaxError("Данные некорректны");
			}
		});
	};
	return (
		<PersonalContext.Provider
			value={{ FetchWorkData, workData, FetchDelete }}
		>
			{children}
		</PersonalContext.Provider>
	);
}
