import React from 'react';
import css from "./StatisticComponents.module.css"
const StatisticComponent = ({title, icon, data, text, text2}) => {
	return (
		<div className={css.statisticCard} >
			<p className={css.statisticCardTitle}>{title}</p>
			<div className={css.statisticCardData}>
				<span>{icon}</span>  {" "}
				<span>{data} <span>{text}</span><sup><small>{text2}</small></sup></span>
			</div>
		</div>
	);
};
export default StatisticComponent;
