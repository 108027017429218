import React, { useState, useEffect } from "react";
import ModalTop from "../../../Component/ModalComponent/ModalTop/ModalTop";
import FilterDateForm from "./FilterTableComponent/FilterDateForm";
import FilterTable from "./FilterTableComponent/FilterTable";
import axios from "axios";
import { Paragraph, Span } from "../../../Component/Theme/CSSstyles";
import css from "./FilterTableComponent/FilterTable.module.css";

const priceTo = (price) => {
  if (!price) return "";

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const url = process.env.REACT_APP_DB_URL;

const FilterModal = () => {
  const [fetchInfo, setFetchInfo] = useState([]);
  const [fetchbalans, setFetBalans] = useState([]);

  const BalansCard = () => {
    const token = localStorage.getItem("DataToken");
    var data = JSON.stringify({
      token: token,
    });

    var config = {
      method: "post",
      url: `${url}/get-balans-ichki.php`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setFetBalans(response.data);
        //   console.log("/get-balans-ichki.php", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    BalansCard();
  }, []);

  const BalansCardInfo = fetchbalans.map((a, index) => {
    return (
      <div key={index.toString()}>
        <Paragraph>
          Бабло сум: <Span>{priceTo(a.naqdsum)}</Span>
        </Paragraph>
        <br />
        <Paragraph>
          Бабло usd: <Span>{priceTo(a.naqdusd)}</Span>
        </Paragraph>
      </div>
    );
  });
  const DataMapFilter = fetchInfo.map((i, index) => {
    return (
      <FilterTable
        key={index.toString()}
        sumplus={i.jamioldisum}
        usdplus={i.jsmioldiusd}
        summinus={i.jamiberdisum}
        usdminus={i.jamiberdiusd}
      />
    );
  });
  return (
    <ModalTop name={"Ежедневное информацыя"}>
      <div className={css.cashFetchInfo}>{BalansCardInfo}</div>
      <FilterDateForm setFetchInfo={setFetchInfo} />
      {DataMapFilter}
    </ModalTop>
  );
};

export default FilterModal;
