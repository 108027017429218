import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import useRequiredAuth from "hook/useRequiredAuth";
import {useAuthUser} from 'react-auth-kit'
import swal from "sweetalert";

export default function RecuiredAuth({ children }) {
  const auth = useAuthUser()
  const { isAuth } = useRequiredAuth();
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to={process.env.REACT_APP_CAVER} state={{ from: location }} />;
  }else if(auth().rol !== process.env.REACT_APP_ROL){
    swal({
      title: `Занимайся своим делом!`,
      icon: "warning",
    })
    return  <Navigate to={process.env.REACT_APP_CLIENTS}/>
  }
  return children;
}