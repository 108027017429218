import React, { useRef, useEffect } from 'react';
import { Col, Row } from 'antd';
import NumberFormat from 'react-number-format';
import { Form } from 'antd';
import DrowerModal from '../../../../Component/ModalComponent/DrowerModal/DrowerModal';
import 'antd/dist/antd.min.css';
import './DrawerMadal.css';
import {
  Spans,
  MiniTitle,
  //   CardSpan,
  CardButton,
} from '../../../../Component/Theme/CSSstyles';
import swal from 'sweetalert';
import { useContext } from 'react';
import { numberFormat } from 'tools/numberFormat';
import { OrdersContext } from 'Context/context';

const FormAddClient = () => {
  const orderContext = useContext(OrdersContext);
  const [form] = Form.useForm();
  const RasRef = useRef();
  const CashRef = useRef();
  const NameRef = useRef();
  const AdresRef = useRef();
  const Sana1Ref = useRef();
  const Sana2Ref = useRef();
  const TelRef = useRef();
  const KvRef = useRef();
  const DeckRef = useRef();

  useEffect(() => {
    NameRef.current.focus();
  });

  const onFinished = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('DataToken');
    const UserName = localStorage.getItem('DataName');
    if (Number(CashRef.current.state.numAsString) === '0') {
      swal({
        title: 'Ошибка!',
        text: `Проверьте данные`,
        icon: 'error',
        buttons: 'ok',
      });
      return null;
    }

    if (
      Number(CashRef.current.state.numAsString) <
      Number(RasRef.current.state.numAsString)
    ) {
      swal({
        title: 'Ошибка!',
        text: `Сумма заказа: ${CashRef.current.state.numAsString} меньше  Суммы аванса:  ${RasRef.current.state.numAsString}`,
        icon: 'error',
        buttons: 'ok',
      });
      return null;
    }
    const DataRach = RasRef.current.state.value;
    const DataCash = CashRef.current.state.value;
    const DataName = NameRef.current.value;
    const DataAdres = AdresRef.current.value;
    const DataSana1 = Sana1Ref.current.value;
    const DataSana2 = Sana2Ref.current.value;
    const DataTel = TelRef.current.state.value;
    const DataKv = KvRef.current.value;
    if (
      DataSana2 === '' ||
      DataSana1 === '' ||
      DataAdres === '' ||
      DataName === ' ' ||
      DataCash === '' ||
      DataKv === '' ||
      DataTel === ''
    ) {
      swal({
        title: 'Внимание!',
        text: `Не все данные введены!!!`,
        icon: 'warning',
        buttons: 'ok',
      });
      return null;
    }
    if (
      Number(RasRef.current.state.numAsString) ===
      Number(CashRef.current.state.numAsString)
    ) {
      swal({
        title: 'Ошибка!',
        text: `Сумма заказа: ${CashRef.current.state.value} и  Суммы аванса:  ${RasRef.current.state.value}  Не должны быть равны!`,
        icon: 'error',
        buttons: 'ok',
      });
      return null;
    }
    var data = JSON.stringify({
      name: DataName,
      adres: DataAdres,
      date: DataSana1,
      installationDate: DataSana2,
      phone: DataTel,
      yuza: DataKv,
      cash: DataCash,
      raschot: DataRach,
      token: token,
      description: DeckRef.current.value,
      username: UserName,
    });

    swal({
      title: 'Вниманиe проверьте данные!!',
      text: `Клиент: ${DataName}, Объём: ${DataKv}, Сумма заказа: ${numberFormat(
        DataCash
      )}, Сумма аванса: ${
        numberFormat(DataRach) === 0
          ? 'Клиент аванс не внёс'
          : numberFormat(DataRach)
      }!`,
      icon: 'warning',
      buttons: {
        cancel: 'Нет!',
        catch: {
          text: 'Да',
          value: 'Ok',
        },
      },
    }).then((willDelete) => {
      if (willDelete === 'Ok') {
        if (data) {
          orderContext
            .addNote(data)
            .then(() => {
              swal({
                title: 'Новый заказ принят',
                buttons: 'Ok',
                icon: 'success',
              });
            })
            .catch((e) => {
              console.log(e);
              swal({
                title: 'Что-то пошло не так!!!',
                buttons: 'Ok',
                icon: 'warning',
              });
            });

          //  DeleteForm();
        } else {
          alert.show('Введите название заметки');
        }
      }
    });
  };

  const DeleteForm = () => {
    form.resetFields();
    //  console.log(RasRef.current.state);
    RasRef.current.state = '';
    CashRef.current.state = '';
    NameRef.current.value = '';
    AdresRef.current.value = '';
    Sana1Ref.current.value = '';
    Sana2Ref.current.value = '';
    TelRef.current.state.value = '';
    KvRef.current.value = '';
    DeckRef.current.value = '';
  };

  return (
    <DrowerModal name='Новый заказ'>
      <div className='client__add-header'>
        <div className='client__add-header-title'>
          <MiniTitle>Данные нового заказа</MiniTitle>
        </div>
        <div>
          <CardButton
            // type="primary"
            // danger
            // onClick={() => form.resetFields()}
            onClick={() => DeleteForm()}
            style={{ fontSize: 20 }}
            className='clear__new-client'
          >
            Очистить
          </CardButton>
          <CardButton
            // htmlType="Submit"
            // onClick={() => form.submit()}
            // type="primary"
            style={{ fontSize: 20 }}
            onClick={onFinished}
            type='submit'
            className='add__new-client'
          >
            Добавить
          </CardButton>
        </div>
      </div>

      <Form form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Spans>Данные заказчика</Spans>
            <input
              placeholder='И.Ф.О'
              ref={NameRef}
              className='client-add-inp'
              autoComplete='off'
              autoFocus={true}
            />
          </Col>
          <Col span={12}>
            <Spans>Адрес заказчика</Spans>
            <input
              placeholder='Короткое название улицы.'
              ref={AdresRef}
              className='client-add-inp'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Spans>Дата приёма заказа</Spans>
            <input
              type='date'
              placeholder='0000-00-00'
              ref={Sana1Ref}
              className='client-add-inp'
            />
          </Col>
          <Col span={12}>
            <Spans>Дата установки</Spans>
            <input type='date' ref={Sana2Ref} className='client-add-inp' />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Spans>Номер телефон</Spans>
            <NumberFormat
              format='(##) ###-##-##'
              mask='_'
              placeholder='(99)999-99-99'
              ref={TelRef}
              className='client-add-inp'
            />
          </Col>
          <Col span={12}>
            <Spans>
              Вветите объем заказа ( m
              <sup>
                <small>2</small>{' '}
              </sup>
              )
            </Spans>
            <input
              type='number'
              step='0.1'
              placeholder='20.4'
              ref={KvRef}
              className='client-add-inp'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Spans>Сумма заказа</Spans>
            <NumberFormat
              thousandSeparator={true}
              placeholder='10,000,000'
              ref={CashRef}
              className='client-add-inp'
            />
          </Col>
          <Col span={12}>
            <Spans>Аванc от суммы заказа</Spans>
            <NumberFormat
              thousandSeparator={true}
              placeholder='1,000,000'
              ref={RasRef}
              className='client-add-inp'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Spans>Подробные данные заказа</Spans>
          <textarea
            className='client-add-inp'
            ref={DeckRef}
            cols='30'
            rows='5'
          ></textarea>
        </Row>
      </Form>
    </DrowerModal>
  );
};

export default FormAddClient;
