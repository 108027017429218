import React, { useState } from "react";
import ModalTop from "../../../Component/ModalComponent/ModalTop/ModalTop";
import WorkData from "./WorkData";
import WorkInterval from "./WorkInterval";
import css from "./WorkDataInterval.module.css";
import { Title } from "../../../Component/Theme/CSSstyles";
const WorkIntervalModal = ({ workData }) => {
  const [dataInterval, setDataInterval] = useState();
  return (
    <ModalTop name={"Интервал работ"}>
      <div className={css.WorkHeader}>
        <Title>Интервал работ мастера</Title>
        <WorkInterval workData={workData} setDataInterval={setDataInterval} />
      </div>

      <WorkData dataInterval={dataInterval} />
    </ModalTop>
  );
};

export default WorkIntervalModal;
